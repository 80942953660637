import { useCallback, useState } from 'react';
import { SyncType, type EventSync } from ':frontend/types/Event';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SpinnerButton } from '../common';
import { getEnumValues } from ':utils/common';
import { useCached } from ':frontend/hooks';
import { type SyncOperation, type SyncState, SyncModal, type UseEventModalDispatch, type SchedulingSyncState } from './useEvent';
import { RecurrenceRange } from ':utils/entity/event';

type EventUpdateModalProps = Readonly<{
    state: SyncState | undefined;
    dispatch: UseEventModalDispatch;
}>;

export default function EventUpdateModal({ state, dispatch }: EventUpdateModalProps) {
    const dispatchSync = useCallback((operation: SyncOperation, fid?: string) => dispatch({ type: 'sync', operation, fid }), [ dispatch ]);
    // TODO we have to keep the old state somewhere so that we can keep displaying the last modal before the fetching starts.
    // So we should maybe separate the fetching property from the state.
    const cached = useCached(state);
    if (!cached)
        return null;

    const phase = cached.phase;
    if (phase === SyncModal.None)
        return null;

    const ModalInner = innerModals[phase];

    return (
        <Modal
            show={!!state}
            onHide={() => dispatchSync({ type: phase, result: 'back' })}
            className='sh-event-modal'
        >
            <ModalInner
                usecase={getUsecase(cached.eventSync)}
                dispatchSync={dispatchSync}
                fetching={state?.fetching}
            />
        </Modal>
    );
}

const innerModals = {
    [SyncModal.Recurrence]: RecurrenceRangeModalInner,
    [SyncModal.Notification]: NotificationModalInner,
    [SyncModal.ConfirmDelete]: ConfirmDeleteModalInner,
    [SyncModal.ConfirmInvoice]: ConfirmInvoiceModalInner,
};

// Recurrence range modal

type ModalInnerProps = Readonly<{
    usecase: string;
    dispatchSync: (operation: SyncOperation, fid?: string) => void;
    fetching: string | undefined;
}>;

function RecurrenceRangeModalInner({ usecase, dispatchSync, fetching }: ModalInnerProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventUpdateModal.recurrence' });
    const [ range, setRange ] = useState(rangeOptions[0]);

    return (<>
        <div className='header'>
            {t(`${usecase}.title`)}
        </div>
        <div className='body'>
            {rangeOptions.map(option => (
                <Form.Check
                    key={option}
                    label={t(`${option}-option`)}
                    checked={range === option}
                    onChange={() => setRange(option)}
                    type='radio'
                    id={`recurrence-range-${option}`}
                    className='mb-3 fw-medium'
                />
            ))}
            <SpinnerButton
                key={range}
                onClick={() => dispatchSync({ type: SyncModal.Recurrence, result: range }, FID_RECURRENCE_CONFIRM)}
                fetching={fetching}
                fid={FID_RECURRENCE_CONFIRM}
                className='mt-n1'
            >
                {t(`${usecase}.confirm-button`)}
            </SpinnerButton>
            <Button
                variant='outline-secondary'
                onClick={() => dispatchSync({ type: SyncModal.Recurrence, result: 'back' })}
                disabled={!!fetching}
            >
                {t('back-button')}
            </Button>
        </div>
    </>);
}

const FID_RECURRENCE_CONFIRM = 'recurrence-confirm';

const rangeOptions = getEnumValues(RecurrenceRange);

function NotificationModalInner({ usecase, dispatchSync, fetching }: ModalInnerProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventUpdateModal.notification' });

    return (<>
        <div className='header'>
            {t(`${usecase}.title`)}
        </div>
        <div className='body'>
            <SpinnerButton
                onClick={() => dispatchSync({ type: SyncModal.Notification, result: 'send' }, FID_NOTIFICATION_SEND)}
                fetching={fetching}
                fid={FID_NOTIFICATION_SEND}
            >
                {t('send-button')}
            </SpinnerButton>
            <SpinnerButton
                variant='outline-secondary'
                onClick={() => dispatchSync({ type: SyncModal.Notification, result: 'dont-send' }, FID_NOTIFICATION_DONTSEND)}
                fetching={fetching}
                fid={FID_NOTIFICATION_DONTSEND}
            >
                {t('dont-send-button')}
            </SpinnerButton>
            <Button variant='outline-secondary' onClick={() => dispatchSync({ type: SyncModal.Notification, result: 'back' })} disabled={!!fetching}>
                {t('back-button')}
            </Button>
        </div>
    </>);
}

const FID_NOTIFICATION_SEND = 'notification-send';
const FID_NOTIFICATION_DONTSEND = 'notification-dontsend';

function getUsecase(eventSync: EventSync): string {
    return eventSync.type !== 'transition'
        ? eventSync.type
        : eventSync.data.transition;
}

// Delete modal - if we wouldn't show any other modal when deleting, we should show at least this one.

function ConfirmDeleteModalInner({ dispatchSync, fetching }: ModalInnerProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventUpdateModal.confirmDelete' });

    return (<>
        <div className='header'>
            {t('title')}
        </div>
        <div className='body'>
            <SpinnerButton
                variant='danger'
                onClick={() => dispatchSync({ type: SyncModal.ConfirmDelete, result: 'confirm' }, FID_DELETE_CONFIRM)}
                fetching={fetching}
                fid={FID_DELETE_CONFIRM}
            >
                {t('confirm-button')}
            </SpinnerButton>
            <Button variant='outline-secondary' onClick={() => dispatchSync({ type: SyncModal.ConfirmDelete, result: 'back' })} disabled={!!fetching}>
                {t('back-button')}
            </Button>
        </div>
    </>);
}

const FID_DELETE_CONFIRM = 'delete-confirm';

function ConfirmInvoiceModalInner({ dispatchSync, fetching }: ModalInnerProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventUpdateModal.confirmInvoice' });

    return (<>
        <div className='header'>
            {t('title')}
        </div>
        <div className='body'>
            <p>
                {t('description')}
            </p>
            <SpinnerButton
                onClick={() => dispatchSync({ type: SyncModal.ConfirmInvoice, result: 'confirm' }, FID_PRICE_CONFIRM)}
                fetching={fetching}
                fid={FID_PRICE_CONFIRM}
            >
                {t('confirm-button')}
            </SpinnerButton>
            <Button variant='outline-secondary' onClick={() => dispatchSync({ type: SyncModal.ConfirmInvoice, result: 'back' })} disabled={!!fetching}>
                {t('back-button')}
            </Button>
        </div>
    </>);
}

const FID_PRICE_CONFIRM = 'price-confirm';


type EventScheduleModalProps = Readonly<{
    state: SchedulingSyncState | undefined;
    dispatch: UseEventModalDispatch;
}>;

export function EventScheduleModal({ state, dispatch }: EventScheduleModalProps) {
    const dispatchSync = useCallback((operation: SyncOperation, fid?: string) => dispatch({ type: 'sync', operation, fid }), [ dispatch ]);
    const cached = useCached(state);
    if (!cached)
        return null;

    const phase = cached.phase;
    if (phase === SyncModal.None)
        return null;

    const ModalInner = innerModals[phase];

    return (
        <Modal
            show={!!state}
            onHide={() => dispatchSync({ type: phase, result: 'back' })}
            className='sh-event-modal'
        >
            <ModalInner
                usecase={SyncType.Schedule}
                dispatchSync={dispatchSync}
                fetching={state?.fetching}
            />
        </Modal>
    );
}
