import { createDefaultFilterRowDropdown, DefaultFilterItemBadge, type Filter, type FilterDefinition, type FilterFunction, type FilterItemBadgeProps, type FilterMenuProps } from './FilterRow';
import { type EventFE, getAllEventStates } from ':frontend/types/Event';
import EventStateBadge from ':frontend/components/event/EventStateBadge';
import { Button, Form } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { compareArrays, emptyFunction } from ':frontend/utils/common';
import type { CalendarEvent } from ':frontend/types/calendar/Calendar';
import { EventState } from ':utils/entity/event';
import { enumFilterToArray } from ':utils/common';

export const filterName = 'eventState';

type FilterState = {
    [key in EventState]: boolean;
};

function FilterToggleMenu({ state, setState }: FilterMenuProps<FilterState>) {
    return (<>
        {getAllEventStates().map(eventState => (
            <Button key={eventState} className='sh-dropdown-button p-3 pe-5 align-items-center justify-content-start' onClick={() => setState({ ...state, [eventState]: !state[eventState] })}>
                {/**
                  * This is needed in order to make the checkbox clickable. Without this, when clicking on the checkbox, the state is changing, but the checkbox still looks the same. Only after changing the state on other event state, the checkbox will change.
                  * For some reason, nothing else really works. It is possible to use something like key={'' + state[eventState]} to force the component to re-render, but this seems unnecessary.
                  * It is probably caused by the whole Dropdown thing that somehow manipulates the events.
                  */}
                <Form.Check type='checkbox' checked={state[eventState]} className='me-3'
                    onClick={e => {
                        setState({ ...state, [eventState]: !state[eventState] });
                        e.stopPropagation();
                    }}
                    onChange={emptyFunction}
                />
                <EventStateBadge state={eventState} />
            </Button>
        ))}
    </>);
}

function FilterItemBadge({ item, onClose }: FilterItemBadgeProps<EventState>) {
    const { t } = useTranslation('common', { keyPrefix: `filters.${filterName}` });

    return (
        <DefaultFilterItemBadge item={item} onClose={onClose}>
            <Trans t={t} i18nKey={`${item}-badge-label`} components={{ sm: <span className='fw-medium' /> }} />
        </DefaultFilterItemBadge>
    );
}

function remove(state: FilterState, item: EventState): FilterState {
    return {
        ...state,
        [item]: false,
    };
}

function toItems(state: FilterState): EventState[] {
    return Object.entries(state)
        .filter(([ , value ]) => value)
        .map(([ key ]) => key as EventState);
}

function createFilterFunction(state: FilterState): FilterFunction<EventFE> {
    if (toItems(state).length === 0)
        return () => true;

    return (data: EventFE) => !!state[data.state];
}

function toServer(state: FilterState, previous: string[] | undefined): string[] {
    const current = enumFilterToArray(state).sort();
    return previous && compareArrays(current, previous)
        ? previous
        : current;
}

const EventStateFilter: FilterDefinition<EventState, FilterState, EventFE> = {
    name: filterName,
    defaultValues: {
        [EventState.ready]: false,
        [EventState.finished]: false,
        [EventState.canceled]: false,
    },
    FilterToggleMenu,
    FilterRowMenu: createDefaultFilterRowDropdown(filterName, FilterToggleMenu),
    FilterItemBadge,
    remove,
    toItems,
    createFilterFunction,
    toServer: toServer as (state: FilterState, previous: unknown | undefined) => string[],
};

export default EventStateFilter as Filter;

function createCalendarFilterFunction(state: FilterState): FilterFunction<CalendarEvent> {
    if (toItems(state).length === 0)
        return () => true;

    return (data: CalendarEvent) => data.resource.type !== 'event' || !!state[data.resource.event.state];
}

const CalendarEventStateFilterDefinition: FilterDefinition<EventState, FilterState, CalendarEvent> = {
    name: filterName,
    defaultValues: {
        [EventState.ready]: false,
        [EventState.finished]: false,
        [EventState.canceled]: false,
    },
    FilterToggleMenu,
    FilterRowMenu: createDefaultFilterRowDropdown(filterName, FilterToggleMenu),
    FilterItemBadge,
    remove,
    toItems,
    createFilterFunction: createCalendarFilterFunction,
    toServer: toServer as (state: FilterState, previous: unknown | undefined) => string[],
};

export const CalendarEventStateFilter = CalendarEventStateFilterDefinition as Filter;
