import { type ReactNode, useCallback, useMemo, useState } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import type { UseNewProductOrderDispatch, UseNewProductOrderState } from './useNewProductOrder';
import { type ProductFE } from ':frontend/types/Product';
import { PlusIcon } from '../icons';
import FormSelect from '../forms/FormSelect';
import { useTranslation } from 'react-i18next';
import { type ClientInfoFE } from ':frontend/types/Client';
import { between, transformToPrice } from ':frontend/utils/math';
import { CloseButton, DeleteButton } from '../forms/buttons';
import { ProductBar, ProductPriceDisplay } from '../product/productDisplay';
import { TranslatedErrorMessage } from '../forms/ErrorMessage';
import { MoneyDisplay } from '../common';
import { SingleContinuousParticipantSelect } from '../client/ContinuousParticipantSelect';
import { type ProductItemInit } from ':frontend/types/orders/Order';
import { type FormDiscount } from './CustomOrderForm';
import { TeamMemberSelect } from '../team/TeamMemberSelect';
import { useUser } from ':frontend/context/UserProvider';
import { TeamMemberRole } from ':utils/entity/team';

type ProductOrderFormProps = Readonly<{
    products: ProductFE[];
    clients: ClientInfoFE[];
    state: UseNewProductOrderState;
    dispatch: UseNewProductOrderDispatch;
}>;

export default function ProductOrderForm({ products, clients, state, dispatch }: ProductOrderFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productOrderForm' });
    const items = state.form.items;
    const { role } = useUser();

    const isTeamMaster = role === TeamMemberRole.master;

    return (
        <div className='sh-design d-flex flex-column'>
            <Form.Group>
                <Form.Label>{t('guest-label')}</Form.Label>
                <SingleContinuousParticipantSelect
                    isCreatable
                    clients={clients}
                    value={state.form.guest}
                    onChange={value => dispatch({ type: 'input', field: 'guest', value })}
                />
                <TranslatedErrorMessage translationId={state.formErrors?.['guest']} />
            </Form.Group>
            {state.form.isLinked ? (
                <div className='mt-2'>
                    <Button variant='link' onClick={() => dispatch({ type: 'input', field: 'isLinked', value: false })}>
                        {t('unlink-button')}
                    </Button>
                </div>
            ) : (
                <Form.Group className='mt-3'>
                    <Form.Label>{t('client-label')}</Form.Label>
                    <SingleContinuousParticipantSelect
                        isCreatable
                        clients={clients}
                        value={state.form.client}
                        onChange={value => dispatch({ type: 'input', field: 'client', value })}
                    />
                    <TranslatedErrorMessage translationId={state.formErrors?.['client']} />
                </Form.Group>
            )}
            {isTeamMaster && (
                <Form.Group className='mt-3'>
                    <Form.Label>{t('scheduler-label')}</Form.Label>
                    <TeamMemberSelect
                        value={state.form.scheduler}
                        onChange={value => dispatch({ type: 'input', field: 'scheduler', value })}
                    />
                    <TranslatedErrorMessage translationId={state.formErrors?.['scheduler']} />
                </Form.Group>
            )}
            <Form.Group className='d-flex flex-column gap-3 mt-4'>
                <Form.Label>{t('items-label')}</Form.Label>
                {items.map((item, index) => productItemCard(state, dispatch, item, index))}
            </Form.Group>
            <div className='mt-3'>
                <ProductSelect products={products} dispatch={dispatch} />
            </div>
            <div className='mt-3'>
                <DiscountInput state={state} dispatch={dispatch} />
            </div>
        </div>
    );
}

function productItemCard(state: UseNewProductOrderState, dispatch: UseNewProductOrderDispatch, item: ProductItemInit, index: number) {
    const isRemovable = state.form.items.length > 1;

    return (
        <ProductOrderBar
            key={index}
            product={item.product}
            onDelete={isRemovable ? () => dispatch({ type: 'form', operation: 'remove', index }) : undefined}
        />
    );
}

type ProductOrderBarProps = Readonly<{
    product: ProductFE;
    onDelete?: () => void;
    className?: string;
}>;

export function ProductOrderBar({ product, onDelete, className }: ProductOrderBarProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productOrderForm.productItem' });

    return (
        <ProductBar
            product={product}
            rightItems={
                <div className='flex-shrink-0 d-flex align-items-center'>
                    <ProductPriceDisplay price={product.price} />
                    {onDelete && (
                        <DeleteButton aria={t('remove-item-button')} onClick={onDelete} className='trim-end' />
                    )}
                </div>
            }
            className={className}
        />
    );
}

type ProductSelectProps = Readonly<{
    products: ProductFE[];
    dispatch: UseNewProductOrderDispatch;
}>;

function ProductSelect({ products, dispatch }: ProductSelectProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productOrderForm' });
    const [ isShow, setIsShow ] = useState(false);
    const productOptions = useMemo(() => products.map(productToOption), [ products ]);
    const selectProduct = useCallback((option: ProductOption | null) => {
        if (!option)
            return;

        dispatch({ type: 'form', operation: 'newItem', product: option.value });
        setIsShow(false);
    }, [ dispatch ]);

    if (!isShow) {
        return (
            <Button onClick={() => setIsShow(true)} variant='outline-primary' className='w-100'>
                <PlusIcon size={22} className='me-2' />{t('add-product-button')}
            </Button>
        );
    }

    return (
        <Form.Group>
            <FormSelect
                placeholder={t('add-product-placeholder')}
                options={productOptions}
                value={null}
                onChange={selectProduct}
                menuIsOpen
                autoFocus
                onBlur={() => setIsShow(false)}
            />
        </Form.Group>
    );
}

type ProductOption = {
    value: ProductFE;
    label: ReactNode;
}

function productToOption(product: ProductFE): ProductOption {
    return {
        value: product,
        label: (
            <div className='d-flex gap-2'>
                {product.icon && <span>{product.icon}</span>}
                <span className='fw-medium'>{product.title}</span>
                <div className='flex-grow-1' />
                <MoneyDisplay money={product.price} />
            </div>
        ),
    };
}

type DiscountInputProps = Readonly<{
    state: UseNewProductOrderState;
    dispatch: UseNewProductOrderDispatch;
}>;

function DiscountInput({ state, dispatch }: DiscountInputProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productOrderForm' });
    const discount = state.form.discount;

    function addDiscount() {
        const defaultDiscount: FormDiscount = {
            amountInPercent: 10,
            label: t('discount-label-default'),
        };
        dispatch({ type: 'input', field: 'discount', value: defaultDiscount });
    }

    function handleChange(value: string) {
        const transformed = transformToPrice(value);
        if (transformed === '') {
            dispatch({ type: 'input', field: 'discount.amountInPercent', value: '' });
            return;
        }
        const bounded = between(transformed, 0, 100);
        dispatch({ type: 'input', field: 'discount.amountInPercent', value: bounded });
    }

    if (!discount) {
        return (
            <Button onClick={() => addDiscount()} variant='outline-secondary' className='w-100'>
                <PlusIcon size={22} className='me-2' />{t('add-discount')}
            </Button>
        );
    }

    return (
        <Form.Group className='d-flex gap-3 align-items-center'>
            <InputGroup className='flex-shrink-0' style={{ width: '100px' }}>
                <Form.Control
                    type='number'
                    value={discount.amountInPercent}
                    onChange={e => handleChange(e.target.value)}
                    autoFocus
                />
                <InputGroup.Text>%</InputGroup.Text>
            </InputGroup>
            <Form.Control
                value={discount.label}
                onChange={e => dispatch({ type: 'input', field: 'discount.label', value: e.target.value })}
                autoFocus
            />
            <CloseButton onClick={() => dispatch({ type: 'input', field: 'discount', value: undefined })} aria={t('remove-discount-aria')} />
        </Form.Group>
    );
}
