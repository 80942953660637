import { zId } from ':utils/id';
import { z } from 'zod';

export enum FileType {
    ImagePNG = 'image/png',
    ImageJPEG = 'image/jpeg',
    ImageSVG = 'image/svg+xml',
}

export const zFileType = z.nativeEnum(FileType);

export type FileOutput = z.infer<typeof zFileOutput>;
export const zFileOutput = z.object({
    id: zId,
    originalName: z.string(),
    hashName: z.string(),
    type: zFileType,
    size: z.number(),
});

export type FileUpsert = z.infer<typeof zFileUpsert>;
export const zFileUpsert = z.object({
    name: z.string(),
    /** mime-type, eg. image/png */
    type: zFileType,
    /** base64-encoded, see fileToBase64 */
    data: z.string(),
});
