import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMaster } from ':frontend/context/UserProvider';
import { Button, Form } from 'react-bootstrap';
import { priceToServer, type CurrencyFE } from ':frontend/modules/money';
import { ControlledCurrencySelect } from '../forms/CurrencySelect';
import { useFieldArray, type UseFormReturn } from 'react-hook-form';
import { useTransform } from ':frontend/utils/forms';
import { type TeamSettingsFE, type PricingFE } from ':frontend/types/settings';
import { minutesToSeconds, secondsToMinutes } from ':utils/common';
import { CrossedEyeIcon, PlusIcon } from '../icons';
import { isValidNumber } from ':frontend/utils/math';
import FormErrorMessage from '../forms/FormErrorMessage';
import clsx from 'clsx';
import { DeleteButton } from '../forms/buttons';
import { type Id } from ':utils/id';
import type { PricingUpsert } from ':utils/entity/settings';

export type PricingFormItem = {
    currencyId: Id;
    /** In minutes. */
    duration: number | '';
    price: number | '';
};

type FormData = {
    pricings: PricingFormItem[];
};

type PricingsEditorProps<T extends FormData> = Readonly<{
    form: UseFormReturn<T>;
    allowEmpty?: boolean;
    isForUser?: boolean;
}>;

export function PricingsEditor<T extends FormData>(props: PricingsEditorProps<T>) {
    const form = props.form as unknown as UseFormReturn<FormData>;
    const { t } = useTranslation('components', { keyPrefix: 'pricingsEditor' });
    const { teamSettings } = useMaster();

    const { control } = form;
    const { fields, append, remove } = useFieldArray({ control, name: 'pricings' });

    function addNewPricing() {
        append(createNewFormItem(teamSettings.currency, fields[fields.length - 1]));
    }

    return (
        <div className='d-flex flex-column gap-3 w-fit'>
            {props.isForUser ? (<>
                <div>
                    <h2 className='m-0'>{t('default-pricing-title')}</h2>
                    <div className='sh-description-no-border m-0'>{t('default-pricing-description')}</div>
                </div>
                <PricingRow
                    pricingIndex={0}
                    form={form}
                    onRemove={() => remove(0)}
                    isRemovable={fields.length > 1}
                    allowEmpty={!!props.allowEmpty}
                />
                <h2>{t('other-pricings-title')}</h2>
                {fields.slice(1).map((field, index) => (
                    <PricingRow
                        key={field.id}
                        pricingIndex={index + 1}
                        form={form}
                        onRemove={() => remove(index + 1)}
                        isRemovable={fields.length > 1}
                        allowEmpty={!!props.allowEmpty}
                    />
                ))}
            </>) : (<>
                {fields.map((field, index) => (
                    <PricingRow
                        key={field.id}
                        pricingIndex={index}
                        form={form}
                        onRemove={() => remove(index)}
                        isRemovable={fields.length > 1}
                        allowEmpty={!!props.allowEmpty}
                    />
                ))}
            </>)}
            <Button className='default-empty fw-medium w-fit' variant='ghost-secondary' onClick={addNewPricing}>
                <PlusIcon size={16} className='me-2' />{t('add-pricing')}
            </Button>
        </div>
    );
}

export function pricingsFormDataToNewPricings(data: FormData): PricingUpsert[] {
    const output: PricingUpsert[] = [];

    const pricingsDurations: number[] = [];

    for (const pricing of data.pricings) {
        if (!pricing.currencyId || !pricing.duration || !isValidNumber(pricing.price) || pricingsDurations.includes(pricing.duration))
            continue;

        pricingsDurations.push(pricing.duration);

        output.push({
            duration: minutesToSeconds(pricing.duration),
            price: priceToServer(pricing.price),
            currencyId: pricing.currencyId,
        });
    }

    return output;
}

export const DEFAULT_PRICING_DURATION_IN_MINUTES = 60;

function createNewFormItem(defaultCurrency: CurrencyFE, previous?: PricingFormItem): PricingFormItem {
    return {
        currencyId: previous?.currencyId ?? defaultCurrency.id,
        duration: previous ? '' : DEFAULT_PRICING_DURATION_IN_MINUTES,
        price: '',
    };
}

function pricingToFormItem(pricing: PricingFE): PricingFormItem {
    return {
        currencyId: pricing.price.currency.id,
        duration: secondsToMinutes(pricing.duration),
        price: pricing.price.amount,
    };
}

/** @param teamSettings used for the default currency */
export function computeDefaultPricings(pricings: PricingFE[], teamSettings: TeamSettingsFE) {
    if (pricings.length === 0)
        return [ createNewFormItem(teamSettings.currency) ];

    const defaultPricings = pricings.map(pricingToFormItem);
    return defaultPricings;
}

type PricingRowProps = Readonly<{
    pricingIndex: number;
    form: UseFormReturn<FormData>;
    onRemove: () => void;
    isRemovable: boolean;
    allowEmpty: boolean;
}>;

function PricingRow({ form, pricingIndex, onRemove, isRemovable, allowEmpty }: PricingRowProps) {
    const { t } = useTranslation('components', { keyPrefix: 'pricingsEditor' });
    const formPrefix = `pricings.${pricingIndex}` as const;

    const { control, register, setValue, formState: { errors } } = form;
    const { registerPrice, registerPositiveInteger } = useTransform(register, setValue);

    const isFirstPricing = pricingIndex === 0;

    const validateDuration = useCallback((value: number | '') => {
        if (value === '')
            return (isFirstPricing && !allowEmpty) ? t('no-rate-error') : true;

        const pricings = form.getValues('pricings');
        if (pricings.some((pricing, index) => index < pricingIndex && pricing.duration === value))
            return t('duplicated-duration-error');
    }, [ pricingIndex, form, isFirstPricing, allowEmpty, t ]);

    return (<>
        <div className='d-flex align-items-center fw-medium gap-3 w-fit'>
            <div style={{ width: 66 }}>
                <Form.Control
                    type='number'
                    style={{ backgroundColor: '#F5F5F5' }}
                    className='fw-medium px-2 py-1 border-0 lha-3 text-end'
                    {...registerPositiveInteger(`${formPrefix}.duration`, { validate: validateDuration })}
                />
            </div>
            <div className='text-center w-fit'>
                {t('minute-block')}
            </div>
            <div className='text-center'>
                =
            </div>
            <div>
                <Form.Control
                    type='number'
                    style={{ backgroundColor: '#F5F5F5' }}
                    className='fw-medium px-2 py-1 border-0 lha-3 text-end'
                    placeholder={t('price-placeholder')}
                    autoFocus={isFirstPricing}
                    {...registerPrice(`${formPrefix}.price`, { required: (isFirstPricing && !allowEmpty) && t('no-rate-error') })}
                />
            </div>
            <div className='ps-0'>
                <ControlledCurrencySelect
                    control={control}
                    name={`${formPrefix}.currencyId`}
                    className='rs-condensed'
                />
            </div>
            {isRemovable ? (
                <DeleteButton aria={t('remove-button-aria')} onClick={onRemove} />
            ) : (
                <div style={{ width: 40 }} />
            )}
        </div>
        <FormErrorMessage errors={errors} names={[ `${formPrefix}.duration`, `${formPrefix}.price` ]} />
    </>);
}

type OnlyToYouLabelProps = Readonly<{
    className?: string;
}>;

export function OnlyToYouLabel({ className }: OnlyToYouLabelProps) {
    const { t } = useTranslation('components', { keyPrefix: 'pricingsEditor' });

    return (
        <div className={clsx('fs-base d-flex align-items-center gap-2 text-muted fw-medium', className)}>
            <CrossedEyeIcon size={18} />
            <span>{t('only-to-you')}</span>
        </div>
    );
}
