import { Modal } from 'react-bootstrap';
import { type BaseLocationFE, locationFromServer } from ':frontend/types/location';
import LocationForm, { type LocationFormData } from './LocationForm';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import { useTranslation } from 'react-i18next';
import { LocationType, PlatformType, type LocationUpsert } from ':utils/entity/location';
import { trpc } from ':frontend/context/TrpcProvider';

type NewLocationModalProps = Readonly<{
    defaultTitle?: string;
    show: boolean;
    onClose(): void;
    onLocationCreated?: (location: BaseLocationFE) => void;
}>;

export default function NewLocationModal({ defaultTitle, show, onClose, onLocationCreated }: NewLocationModalProps) {
    const { t } = useTranslation('pages');
    const { addAlert } = useNotifications();

    const createLocationMutation = trpc.location.createLocation.useMutation();

    function onSubmit(data: LocationFormData) {
        const init = locationFormToUpsert(data);
        createLocationMutation.mutate(init, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
                onClose();
            },
            onSuccess: response => {
                const newLocation = locationFromServer(response);
                if (onLocationCreated)
                    onLocationCreated(newLocation);
                addAlert(createTranslatedSuccessAlert('common:locationCreatedAlert'));

                onClose();
            },
        });
    }

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('newLocation.modal-title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LocationForm
                    defaultValues={{ title: defaultTitle }}
                    isFetching={createLocationMutation.isPending}
                    onSubmit={onSubmit}
                />
            </Modal.Body>
        </Modal>
    );
}

export function locationFormToUpsert(data: LocationFormData): LocationUpsert {
    if (data.platformType === PlatformType.physicalLocation) {
        return {
            type: LocationType.physical,
            title: data.title,
            address: {
                city: data.city,
                line1: data.line1,
                postalCode: data.postalCode,
                country: data.country,
            },
        };
    }

    return {
        type: LocationType.video,
        title: data.title,
        platform: data.platformType === PlatformType.custom ? data.platform : data.platformType,
        url: data.url,
    };
}
