import { type DateTime } from 'luxon';
import { type FilterDefinition, type FilterFunction, type FilterItemBadgeProps, type FilterMenuProps, DefaultFilterItemBadge, type Filter } from './FilterRow';
import { type OrderInfoFE } from ':frontend/types/orders/Order';
import { Trans, useTranslation } from 'react-i18next';
import DateTimeDisplay from '../DateTimeDisplay';
import { DatePicker } from ':frontend/components/forms';
import { Dropdown } from 'react-bootstrap';

const orderCreatedAfterFilterName = 'orderCreatedAfter';
const orderCreatedBeforeFilterName = 'orderCreatedBefore';

type FilterState = DateTime | undefined;

function FilterToggleMenu({ state, setState }: FilterMenuProps<FilterState>) {
    return (
        <DatePicker
            selected={state}
            onChange={setState}
            type='date'
            inline
        />
    );
}

function createFilterRowMenu(name: typeof orderCreatedAfterFilterName | typeof orderCreatedBeforeFilterName) {
    return ({ state, setState }: FilterMenuProps<FilterState>) => {
        const { t } = useTranslation('common', { keyPrefix: 'filters' });

        return (
            <Dropdown drop='down' autoClose='outside'>
                <Dropdown.Toggle variant='outline-secondary' className='sh-dropdown-custom-icon compact'>
                    {t(`${name}.menu-button`)}
                </Dropdown.Toggle>
                <Dropdown.Menu className='p-0'>
                    <DatePicker
                        selected={state}
                        onChange={setState}
                        type='date'
                        inline
                        calendarClassName='embedded'
                    />
                </Dropdown.Menu>
            </Dropdown>
        );
    };
}

function createFilterItemBadge(name: typeof orderCreatedAfterFilterName | typeof orderCreatedBeforeFilterName) {
    return ({ item, onClose }: FilterItemBadgeProps<FilterState>) => {
        const { t } = useTranslation('common', { keyPrefix: `filters.${name}` });

        return (
            <DefaultFilterItemBadge item={item} onClose={onClose}>
                <Trans
                    t={t}
                    i18nKey={`badge-label`}
                    components={{
                        sm: <span className='fw-medium' />,
                        date: item ? <DateTimeDisplay date dateTime={item} /> : <></>,
                    }}
                />
            </DefaultFilterItemBadge>
        );
    };
}

function remove(): FilterState {
    return undefined;
}

function toItems(state: FilterState): FilterState[] {
    if (!state)
        return [];
    return [ state ];
}

function createCreatedAfterFilterFunction(state: FilterState): FilterFunction<OrderInfoFE> {
    if (!state)
        return () => true;

    return (data: OrderInfoFE) => state.startOf('day') <= data.createdAt;
}

function createCreatedBeforeFilterFunction(state: FilterState): FilterFunction<OrderInfoFE> {
    if (!state)
        return () => true;

    return (data: OrderInfoFE) => data.createdAt < state.plus({ days: 1 }).startOf('day');
}

function createdAfterToServer(state: FilterState): DateTime | undefined {
    if (!state)
        return undefined;
    return state.startOf('day').toUTC();
}

function createdBeforeToServer(state: FilterState): DateTime | undefined {
    if (!state)
        return undefined;
    return state.plus({ days: 1 }).startOf('day').toUTC();
}

const OrderCreatedAfterFilterTyped: FilterDefinition<FilterState, FilterState, OrderInfoFE> = {
    name: orderCreatedAfterFilterName,
    defaultValues: undefined,
    FilterToggleMenu,
    FilterRowMenu: createFilterRowMenu(orderCreatedAfterFilterName),
    FilterItemBadge: createFilterItemBadge(orderCreatedAfterFilterName),
    remove,
    toItems,
    createFilterFunction: createCreatedAfterFilterFunction,
    toServer: createdAfterToServer,
};

const OrderCreatedBeforeFilterTyped: FilterDefinition<FilterState, FilterState, OrderInfoFE> = {
    name: orderCreatedBeforeFilterName,
    defaultValues: undefined,
    FilterToggleMenu,
    FilterRowMenu: createFilterRowMenu(orderCreatedBeforeFilterName),
    FilterItemBadge: createFilterItemBadge(orderCreatedBeforeFilterName),
    remove,
    toItems,
    createFilterFunction: createCreatedBeforeFilterFunction,
    toServer: createdBeforeToServer,
};

export const OrderCreatedAfterFilter = OrderCreatedAfterFilterTyped as Filter;
export const OrderCreatedBeforeFilter = OrderCreatedBeforeFilterTyped as Filter;
