import { env } from ':utils/../env';

const FRAGMENT_KEY = '#fragment';
export type Fragment = typeof FRAGMENT_KEY;

export class Route<TParam extends string = never, TQuery extends string = never> {
    constructor(
        /**
         * Used for matching. E.g., `/users/:id`.
         * Contains only the last part, i.e., without any prefixing routers.
         */
        readonly path: string,
        /**
         * On backend, some part of the path is usually taken by express routers, and then the rest is used for the final routing.
         */
        private readonly prefix?: string,
    ) {}

    resolve(params: Record<TParam, string>, query?: Record<TQuery, string>): string {
        let value = (this.prefix ?? '') + this.path;

        for (const key of Object.keys(params))
            value = value.replace(':' + key, params[key as keyof typeof params]);

        if (query) {
            if (FRAGMENT_KEY in query)
                value += '#' + query[FRAGMENT_KEY];

            const queryString = Object.entries(query)
                .filter(([ key ]) => key !== FRAGMENT_KEY)
                .map(([ key, value ]) => `${key}=${value}`)
                .join('&');
            if (queryString.length > 0)
                value += '?' + queryString;
        }

        return value;
    }

    get absolutePath(): string {
        return env.VITE_APP_URL + (this.prefix ?? '') + this.path;
    }

    absoluteResolve(params: Record<TParam, string>, query?: Record<TQuery, string>): string {
        return env.VITE_APP_URL + this.resolve(params, query);
    }
}

export class Router {
    private constructor(
        /**
         * Relative path (join of all parent routes).
         */
        readonly prefix: string,
    ) {}

    static root(prefix: string): Router {
        return new Router(prefix);
    }

    child(childPrefix: string): Router {
        return new Router(this.prefix + childPrefix);
    }

    route<TParam extends string = never, TQuery extends string = never>(path: string): Route<TParam, TQuery> {
        return new Route(path, this.prefix);
    }
}


// Not all routes are included here - only those that are needed by frontend. Exclusively backend routes are kept in the backend.

const backendRouter = Router.root('/api');
const authRouter = backendRouter.child('/auth');
const publicRouter = backendRouter.child('/public');
const privateRouter = backendRouter.child('/private');
const trpcRouter = backendRouter.child('/trpc');
const googleRouter = backendRouter.child('/google');

export const routesBE = {
    $router: backendRouter,
    trpc: {
        $router: trpcRouter,
    },
    auth: {
        $router: authRouter,
        login: authRouter.route('/login'),
        registerValidate: authRouter.route('/register-validate'),
        register: authRouter.route('/register'),
        logout: authRouter.route('/logout'),
        refresh: authRouter.route('/refresh'),
        updatePassword: authRouter.route('/update-password'),
        resetPassword: authRouter.route('/reset-password'),
    },
    public: {
        $router: publicRouter,
        version: publicRouter.route('/version'),
        invoice: publicRouter.route<'id'>('/orders/:id/invoice'),
        payment: publicRouter.route<'id'>('/orders/:id/pay'),
    },
    private: {
        $router: privateRouter,
        invoicePreview: privateRouter.route('/orders/preview'),
        ordersExport: privateRouter.route('/orders/export'),
        invoicingProfileExample: privateRouter.route<'id'>('/invoicing-profiles/:id/example'),
    },
    google: {
        $router: googleRouter,
        oauth: googleRouter.route('/oauth'),
        integration: googleRouter.route('/integration'),
    },
} as const;

const frontendRouter = Router.root('');

export const routesFE = {
    $router: frontendRouter,
    root: '/',
    dashboard: '/',
    calendar: '/calendar',
    clients: {
        list: '/clients',
        detail: frontendRouter.route<'id' | 'key', Fragment>('/clients/:id/:key'),
        new: '/clients/new',
        import: '/clients/import',
    },
    orders: {
        list: '/orders',
        detail: frontendRouter.route<'id'>('/orders/:id'),
        export: '/orders/export',
        newProduct: '/orders/new',
        newEventGroup: '/orders/new-event',
        newCustom: '/orders/new-custom',
        newBackpay: '/clients/invoice',
    },
    team: '/team',
    events: {
        detail: frontendRouter.route<'id'>('/events/:id'),
    },
    settings: frontendRouter.route<'key'>('/settings/:key'),
    invoicingProfiles: {
        detail: frontendRouter.route<'id' | 'key'>('/invoicing-profiles/:id/:key'),
    },
    payments: frontendRouter.route('/payments'),
    subscription: frontendRouter.route('/subscription'),
    integrations: frontendRouter.route<never, 'error'>('/integrations'),
    login: {
        index: frontendRouter.route('/login'),
        google: '/login/google',
    },
    // The url pages are displayed to the users so we use the 'sign-' version.
    register: {
        index: '/signup',
        google: frontendRouter.route<never, 'error'>('/signup/google'),
    },
    resetPassword: '/reset-password',
    /** Only for development purposes. Not active in production. */
    dev: '/dev',
};
