import { useTranslation } from 'react-i18next';
import GoogleIntegration from ':frontend/components/integrations/GoogleIntegration';

export default function Integrations() {
    const { t } = useTranslation('pages', { keyPrefix: 'integrations' });

    return (
        <div className='container-small'>
            <h1>{t('page-title')}</h1>
            <div className='sh-description'>{t('page-description')}</div>
            <GoogleIntegration />
        </div>
    );
}
