import { Alert } from 'react-bootstrap';
import useNotifications from ':frontend/context/NotificationProvider';
import { Visibility } from ':frontend/types/notifications';
import clsx from 'clsx';

type AlertDisplayProps = Readonly<{
    visibility: Visibility;
    className?: string;
}>;

export default function AlertDisplay({ visibility, className }: AlertDisplayProps) {
    const { alerts, removeAlert } = useNotifications();

    return (
        // The pe-none class turns off pointer events so that the user can click through the container.
        // We then have to turn them back on for the alerts themselves.
        <div className={clsx('sh-alert-display pe-none', className)}>
            {alerts
                .filter(alert => alert.visibility === visibility || (visibility !== Visibility.Dev && alert.visibility === Visibility.Global))
                .map(alert => (
                    <Alert
                        key={alert.id}
                        variant={alert.content.variant}
                        dismissible
                        show={alert.show}
                        onClose={() => removeAlert(alert.id)}
                        className='pe-auto'
                    >
                        {typeof alert.content.message === 'string' ? (
                            alert.content.message
                        ) : (
                            <alert.content.message alertId={alert.id} />
                        )}
                    </Alert>
                ))}
        </div>
    );
}
