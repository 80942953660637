import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import FormErrorMessage from ':frontend/components/forms/FormErrorMessage';
import { SpinnerButton } from ':frontend/components/common';
import useNotifications from ':frontend/context/NotificationProvider';
import { Variant, Visibility } from ':frontend/types/notifications';
import { routesFE } from ':utils/routes';
import { GoogleOauthButton } from ':frontend/components/auth/googleButton';
import { FlowlanceBanner } from ':frontend/components/icons';
import useAuth from ':frontend/context/AuthProvider';
import { createTranslatedErrorAlert } from ':frontend/components/notifications';
import { canonizeEmail } from ':utils/forms';

type ResetPasswordFormData = {
    email: string;
};

export default function ResetPassword() {
    const { t } = useTranslation('pages', { keyPrefix: 'resetPassword' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });
    const [ isFetching, setIsFetching ] = useState(false);
    const [ isGoogleAccount, setIsGoogleAccount ] = useState(false);
    const { auth } = useAuth();

    const { register, handleSubmit, formState: { errors } } = useForm<ResetPasswordFormData>();
    const { addAlert } = useNotifications();

    async function onSubmit(data: ResetPasswordFormData) {
        const canonizedEmail = canonizeEmail(data.email);
        setIsFetching(true);
        const response = await auth.resetPassword(canonizedEmail);
        setIsFetching(false);

        if (!response.status) {
            if (response.error === 'auth.resetPassword.google') {
                setIsGoogleAccount(true);
                return;
            }

            setIsGoogleAccount(false);
            addAlert(createTranslatedErrorAlert());
            return;
        }

        addAlert({ message: t('success-alert'), variant: Variant.Success }, Visibility.Global);
    }

    return (
        <div className='sh-unauthorized-page'>
            <div className='centering-wrapper'>
                <div className='sh-unauthorized-card'>
                    <div className='sh-unauthorized-content d-flex flex-column align-items-center'>
                        <FlowlanceBanner />
                        <h1 className='fs-1'>{t('page-title')}</h1>
                        <Form noValidate onSubmit={handleSubmit(onSubmit)} className='w-100'>
                            <Form.Group className='sh-form-label-inside'>
                                <Form.Label>{t('email-label')}</Form.Label>
                                <Form.Control
                                    type='email'
                                    {...register('email', { required: tf('email-required') })}
                                />
                            </Form.Group>
                            <FormErrorMessage errors={errors} name='email' />
                            <SpinnerButton
                                type='submit'
                                className='w-100 mt-3'
                                isFetching={isFetching}
                            >
                                {t('reset-password-button')}
                            </SpinnerButton>
                        </Form>
                        <Link to={routesFE.login.index.path} className='align-self-end mt-2'>
                            {t('login-link')}
                        </Link>
                        {isGoogleAccount && (
                            <div className='rounded border border-danger bg-danger bg-opacity-10 p-2 mt-4 w-100'>
                                <p className='text-center text-danger'>{t('via-google')}</p>
                                <GoogleOauthButton type='login' />
                            </div>
                        )}
                        <div className='text-center mt-4'>
                            <Trans
                                i18nKey='register-link'
                                t={t}
                                components={{
                                    a: <Link to={routesFE.register.index} />,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
