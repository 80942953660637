import { useMemo } from 'react';
import type { AnyEventResource, CalendarEvent } from ':frontend/types/calendar/Calendar';
import { Views, type View } from ':frontend/lib/calendar/Views';
import { type DateTime } from 'luxon';
import calendarLocalizer, { type DateRange } from ':frontend/lib/calendar/localizer';
import { type CurrencyFE } from ':frontend/modules/money';
import { useTranslation } from 'react-i18next';
import { MoneyDisplay } from '../common';
import { floatToPercent } from ':frontend/utils/math';

type EventsStats = {
    total: number;
    paid: number;
    quantity: number;
};

type VisibleEventsSummaryProps = Readonly<{
    events: CalendarEvent<AnyEventResource>[];
    date: DateTime;
    view: View;
    className?: string;
}>;

export function VisibleEventsSummary({ events, date, view, className }: VisibleEventsSummaryProps) {
    const visibleEvents = useMemo(() => {
        if (!date || !view)
            return;
        return eventsInView(events, date, view);
    }, [ events, date, view ]);

    const eventsStats = useMemo(() => {
        if (!visibleEvents)
            return;

        const stats = new Map<CurrencyFE, EventsStats>();

        visibleEvents.forEach(event => {
            if (event.resource.type !== 'event')
                return;

            const totalPrice = event.resource.totalPrice;
            if (!totalPrice)
                return;

            // TODO multiple event currencies
            const paid = event.resource.event.clients.filter(participant => participant.isPaid).reduce((ans, p) => ans + p.payment.price.amount, 0);

            const currencyStats: EventsStats = stats.get(totalPrice.currency) ?? { total: 0, paid: 0, quantity: 0 };
            stats.set(totalPrice.currency, {
                total: currencyStats.total + totalPrice.amount,
                paid: currencyStats.paid + paid,
                quantity: currencyStats.quantity + 1,
            });
        });

        return [ ...stats.entries() ].map(([ currency, stats ]) => ({ currency, ...stats })).filter(x => x.total > 0);
    }, [ visibleEvents ]);

    return (
        <div className={className}>
            {eventsStats?.map(stat => (
                <OneCurrencySummary key={stat.currency.id} {...stat} />
            ))}
        </div>
    );
}

function eventsInView(events: CalendarEvent<AnyEventResource>[], date: DateTime, view: View) {
    const range = getViewRange(date, view);
    const eventsInRange = events.filter(ev => calendarLocalizer.inEventRange(ev, range, true));
    return eventsInRange;
}

function getViewRange(date: DateTime, view: View): DateRange {
    if (view === Views.WEEK) {
        return {
            start: date.startOf('week'),
            end: date.endOf('week'),
        };
    }
    else if (view === Views.MONTH || view === Views.AGENDA) {
        return {
            start: date.startOf('month'),
            end: date.endOf('month'),
        };
    }
    else {
        // fallback
        return {
            start: date,
            end: date,
        };
    }
}

type OneCurrencySummaryProps = Readonly<{
    paid: number;
    total: number;
    currency: CurrencyFE;
    quantity: number;
}>;

function OneCurrencySummary({ paid, total, quantity, currency }: OneCurrencySummaryProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'calendar' });
    const paidPercent = floatToPercent(paid / total);

    return (
        <div>
            <div className='d-flex fw-medium'>
                <div className='text-success'>
                    {t('paid-label')}{' '}
                    <MoneyDisplay money={{ amount: paid, currency }} />
                </div>
                <div className='flex-grow-1' />
                <div>
                    {t('total-label')}{' '}
                    <MoneyDisplay money={{ amount: total, currency }} />
                </div>
                <div className='sh-text-light'>
                    &nbsp;{`(${quantity})`}
                </div>
            </div>
            <div className='sh-summary-bar-outer'>
                <div className='sh-summary-bar-inner' style={{ width: paidPercent + '%' }} />
            </div>
        </div>
    );
}
