import { DateTime } from 'luxon';
import { type Id } from ':utils/id';
import { type Person } from './Person';
import type { AppUserOutput } from ':utils/entity/user';
import { TeamMemberRole } from ':utils/entity/team';

export class AppUserFE implements Person {
    private constructor(
        readonly id: Id,
        readonly firstName: string,
        readonly lastName: string | undefined,
        readonly email: string,
        readonly phoneNumber: string | undefined,
        readonly google: GoogleIntegrationFE,
    ) {}

    static fromServer(input: AppUserOutput): AppUserFE {
        const google: GoogleIntegrationFE = {
            isAuth: !!input.google,
            calendar: input.google?.calendar && {
                calendarId: input.google.calendar.calendarId,
                datePastEvents: DateTime.fromISO(input.google.calendar.datePastEvents),
            },
            isContacts: !!input.google?.contacts,
        };

        return new AppUserFE(
            input.id,
            input.firstName,
            input.lastName,
            input.email,
            input.phoneNumber,
            google,
        );
    }
}

export function isGoogleCalendarEnabled(appUser: AppUserFE, role: TeamMemberRole): boolean {
    // We don't show google events for masters, because they have too much events already.
    return !!appUser.google.calendar && role !== TeamMemberRole.master;
}

type GoogleIntegrationFE = {
    readonly isAuth: boolean;
    readonly calendar?: {
        readonly calendarId: string;
        readonly datePastEvents: DateTime;
    };
    readonly isContacts: boolean;
};
