import { useCallback, useMemo } from 'react';
import { type InvoicingProfileFE } from ':frontend/types/Invoicing';
import { ClientsTable } from ':frontend/pages/client/Clients';
import { useClients, useSortOrder } from ':frontend/hooks';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import ClientSelect from '../client/ClientSelect';
import { type ClientInfoFE, ClientFE } from ':frontend/types/Client';
import { createErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import { trpc } from ':frontend/context/TrpcProvider';

type InvoicingProfileClientsProps = Readonly<{
    profile: InvoicingProfileFE;
}>;

export default function InvoicingProfileClients({ profile }: InvoicingProfileClientsProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'invoicing-profile' });
    const [ nameOrder, setNameOrder ] = useSortOrder();
    const { clients, addClients, setClients } = useClients({ invoicingProfileId: profile.id, nameOrder });
    const { clients: allClients, setClients: setAllClients } = useClients({ default: [] });
    const { addAlert } = useNotifications();

    const clientUpdateMutation = trpc.$client.updateClient.useMutation();

    const addClient = useCallback((client?: ClientInfoFE) => {
        if (!client)
            return;

        clientUpdateMutation.mutate({ id: client.id, invoicingProfileId: profile.id }, {
            onError: error => {
                addAlert(createErrorAlert(error.data));
            },
            onSuccess: response => {
                const updatedClient = ClientFE.fromServer(response);
                addClients(updatedClient);
                setAllClients(oldAllClients => {
                    const index = oldAllClients.findIndex(c => c.id === client.id);
                    if (index === -1)
                        return oldAllClients;

                    oldAllClients[index] = updatedClient;
                    return [ ...oldAllClients ];
                });
                addAlert(createTranslatedSuccessAlert('pages:invoicing-profile.add-client-success-alert'));
            },
        });
    }, [ profile.id, addClients, setAllClients, addAlert, clientUpdateMutation ]);

    const availableClients = useMemo(() => allClients.filter(c => c.invoicingProfileId !== profile.id), [ profile.id, allClients ]);

    return (<>
        <Form.Group className='sh-design mb-4 w-50'>
            <Form.Label className='fw-medium'>{t('add-client-label')}</Form.Label>
            <ClientSelect
                clients={availableClients}
                value={undefined}
                onChange={addClient}
                showEmails
                disabled={clientUpdateMutation.isPending}
            />
        </Form.Group>
        <ClientsTable
            clients={clients}
            setClients={setClients}
            noClientsMessage={t('no-clients-for-profile-text')}
            nameOrder={nameOrder}
            switchNameOrder={setNameOrder.toggle}
        />
    </>);
}
