import { createContext } from 'react';
import { Calendar as ReactCalendar, type NavigateAction, type SlotInfo } from ':frontend/lib/calendar';
import { DateTime } from 'luxon';
import withDragAndDrop from ':frontend/lib/calendar/addons/dragAndDrop';
import { type CalendarEvent } from ':frontend/types/calendar/Calendar';
import { type ClientInfoFE } from ':frontend/types/Client';
import { Views, type View } from ':frontend/lib/calendar/Views';
import type { Selected } from ':frontend/pages/CalendarDetail';

const ReactDragDropCalendar = withDragAndDrop(ReactCalendar);

const DEFAULT_SCROLL_HOUR = 7;
export const DEFAULT_CALENDAR_VIEW = Views.WEEK;

// creating a higher-order component or creating a local component inside Calendar
// as recommended in https://github.com/jquense/react-big-calendar/issues/709#issuecomment-362099377
// did not work
const LoadingContext = createContext({ loading: true });

// same as EventInteractionArgs, but using Date instead of stupid stringOrDate
// TODO unify
export type EventChangeArgs = {
    event: CalendarEvent;
    start: DateTime;
    end: DateTime;
    isAllDay: boolean;
};

type CalendarProps = {
    events: CalendarEvent[];
    selected?: Selected;
    onSelectEvent?: (event: CalendarEvent) => void;
    onNavigate?: (newDate: DateTime, view: View, action: NavigateAction) => void;
    view: View;
    onView?: (view: View) => void;
    onSelectSlot?: (slot: SlotInfo) => void;
    onMoveEvent?: (event: EventChangeArgs) => void;
    onResizeEvent?: (event: EventChangeArgs) => void;
    loading: boolean;
    date: DateTime;
    clients: ClientInfoFE[];
};

export default function Calendar({ events, selected, onSelectEvent, onNavigate, view, onView, onSelectSlot, onMoveEvent, onResizeEvent, loading, date, clients }: CalendarProps) {
    const scrollToTime = DateTime.now().set({ hour: DEFAULT_SCROLL_HOUR });

    // const isChangeable = useCallback((event: CalendarEvent) => {
    //     if (isGoogleEvent(event))
    //         return false;

    //     if (!draftEvent)
    //         return true;

    //     return event === draftEvent;
    // }, [ draftEvent ]);

    const selectedOrDraft = selected
        ? ('event' in selected ? selected.event : selected.draft)
        : null;

    return (
        <div className='sh-calendar-wrapper'>
            <LoadingContext.Provider value={{ loading }}>
                <ReactDragDropCalendar
                    events={events}
                    style={{ height: '100%' }}
                    scrollToTime={scrollToTime}
                    defaultView={DEFAULT_CALENDAR_VIEW}
                    selected={selectedOrDraft}
                    onSelectEvent={onSelectEvent}
                    onNavigate={onNavigate}
                    view={view}
                    onView={onView}
                    onSelectSlot={onSelectSlot}
                    selectable={true}
                    // draggableAccessor={isChangeable}
                    onEventDrop={onMoveEvent}
                    onEventResize={onResizeEvent}
                    date={date}
                    clients={clients}
                />
            </LoadingContext.Provider>
        </div>
    );
}
