import { z } from 'zod';

// TODO this should be synchronized with all other env files.
const VITE_APP_URL = z.string().safeParse(import.meta.env?.VITE_APP_URL);

// This is because react email preview can't handle env variables. Maybe we can fix it by using @t3-oss/env-core and setting all env variables in one place.
if (!VITE_APP_URL.success)
    console.error(`VITE_APP_URL is not set, got: ${VITE_APP_URL.data}`);

export const env = {
    VITE_APP_URL: VITE_APP_URL.data ?? 'http://localhost:3030',
};
