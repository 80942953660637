import { z } from 'zod';
import { DateTime } from 'luxon';

const zEventDateTime = z.object({
    date: z.string().optional(),
    dateTime: z.string().optional(),
    timezone: z.string().optional(),
});

function parseEventDateTime(input: z.infer<typeof zEventDateTime>): DateTime {
    if (input.date)
        return DateTime.fromFormat(input.date, 'yyyy-MM-dd');

    if (!input.dateTime)
        throw new Error('Calendar event has invalid date: ' + JSON.stringify(input));

    return DateTime.fromISO(input.dateTime, { zone: input.timezone });
}

export type GoogleEventFromServer = z.infer<typeof zGoogleEventFromServer>;
export const zGoogleEventFromServer = z.object({
    id: z.string(),
    recurringEventId: z.string().optional(),
    summary: z.string().optional(),
    description: z.string().optional(),
    /** If the event is canceled, it doesn't have neither start nor end. */
    start: zEventDateTime.optional(),
    end: zEventDateTime.optional(),
    attendees: z.array(z.object({
        email: z.string(),
        responseStatus: z.enum([ 'needsAction', 'declined', 'tentative', 'accepted' ]),
    })).optional(),
});

export class GoogleEvent {
    private constructor(
        /**
         * The primary identifier of the event. It's unique even between instances of the same recurrence event.
         */
        readonly id: string,
        /**
         * For an instance of a recurring event, this is the id of the recurring event to which this instance belongs.
         * If undefined, this is not a recurring event
         */
        readonly recurringEventId: string | undefined,

        // BTW, there is also iCalUID, which is shared accross all recurrence instances, but is unique accross all calendars.
        // Maybe it could be used somehow?

        readonly calendarId: string,
        readonly title: string | undefined,
        readonly description: string,
        readonly isAllDay: boolean,
        readonly start: DateTime,
        readonly end: DateTime,
        /** Either empty (if only the appUser is going) or 2 or more emails (including the user's email). */
        readonly guests: string[],
        readonly isCancelled: boolean = false,
    ) {}

    static tryFromServer(input: GoogleEventFromServer, calendarId: string): GoogleEvent | undefined {
        if (!input.start || !input.end)
            return undefined;

        const isAllDay = !!(input.start.date && input.end.date);

        return new GoogleEvent(
            input.id,
            input.recurringEventId,
            calendarId,
            input.summary,
            input.description || '',
            isAllDay,
            parseEventDateTime(input.start),
            parseEventDateTime(input.end),
            input.attendees?.map(a => a.email) ?? [],
            !input.attendees?.some(a => a.responseStatus !== 'declined'),
        );
    }

    static fromServer(input: GoogleEventFromServer, calendarId: string): GoogleEvent {
        const event = GoogleEvent.tryFromServer(input, calendarId);
        if (!event)
            throw new Error('Invalid Google event: ' + JSON.stringify(input));

        return event;
    }
}
