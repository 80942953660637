import { useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { type AppUserSettingsFE } from ':frontend/types/settings';
import { SpinnerButton } from '../common';
import { useTranslation } from 'react-i18next';
import { ControlledCountrySelect, ControlledLocaleSelect, ControlledTimezoneSelect } from ':frontend/components/forms';
import type { CountryCode, LocaleCode, TimezoneCode } from ':utils/i18n';
import type { AppUserSettingsEdit } from ':utils/entity/settings';

type PreferencesFormData = {
    timezone: TimezoneCode;
    locale: LocaleCode;
    country: CountryCode;
    hideEventPrices: boolean;
};

type PreferencesFormProps = Readonly<{
    input: AppUserSettingsFE;
    onSubmit: (output: AppUserSettingsEdit) => void;
    isFetching: boolean;
}>;

export default function PreferencesForm({ input, onSubmit, isFetching }: PreferencesFormProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.general' });
    const { register, handleSubmit, reset, control } = useForm<PreferencesFormData>();

    useEffect(() => {
        reset({
            timezone: input.timezone,
            locale: input.locale,
            country: input.country,
            hideEventPrices: input.hideEventPrices,
        });
    }, [ input ]);

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className='sh-design'>
            <Row className='gap-row-3'>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('timezone-label')}</Form.Label>
                    <ControlledTimezoneSelect
                        control={control}
                        name='timezone'
                    />
                </Form.Group>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('locale-label')}</Form.Label>
                    <ControlledLocaleSelect
                        control={control}
                        name='locale'
                        type='base'
                    />
                </Form.Group>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('country-label')}</Form.Label>
                    <ControlledCountrySelect
                        control={control}
                        name='country'
                    />
                </Form.Group>
            </Row>
            <Row className='mt-3'>
                <Col>
                    <Form.Check
                        {...register('hideEventPrices')}
                        type='switch'
                        label={t('hide-event-prices-toggle')}
                        id='hide-event-prices-toggle'
                    />
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col>
                    <SpinnerButton
                        type='submit'
                        isFetching={isFetching}
                    >
                        {t('save-button')}
                    </SpinnerButton>
                </Col>
            </Row>
        </Form>
    );
}
