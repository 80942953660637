import { type CurrencyFE, type Money } from ':frontend/modules/money/types';
import { type Id } from ':utils/id';
import { getCurrency, moneyFromServer } from ':frontend/modules/money';
import type { AppUserSettingsOutput, PricingOutput, TeamSettingsOutput } from ':utils/entity/settings';
import type { CountryCode, LocaleCode, TimezoneCode } from ':utils/i18n';

export class TeamSettingsFE {
    private constructor(
        readonly id: Id,
        readonly pricings: PricingFE[],
        readonly currency: CurrencyFE,
    ) {}

    static fromServer(input: TeamSettingsOutput) {
        return new TeamSettingsFE(
            input.id,
            input.pricings.map(PricingFE.fromServer),
            getCurrency(input.currencyId),
        );
    }
}

export class PricingFE {
    private constructor(
        readonly id: Id,
        /** In seconds. */
        readonly duration: number,
        readonly price: Money,
    ) {}

    static fromServer(input: PricingOutput) {
        return new PricingFE(
            input.id,
            input.duration,
            moneyFromServer(input.price, input.currencyId),
        );
    }
}

/**
 * This type represents settings that are not yet implemented on the backend, so they are hard-coded here.
 */
const NOT_INCLUDED_SETTINGS = {
    dateFormat: 'dd. MM. yyyy',
    dateTimeFormat: 'dd. MM. yyyy HH:mm',
};

export class AppUserSettingsFE {
    private constructor(
        readonly id: Id,
        readonly timezone: TimezoneCode,
        readonly locale: LocaleCode,
        readonly country: CountryCode,
        readonly dateFormat: string,
        readonly dateTimeFormat: string,
        readonly hideEventPrices: boolean,
    ) {}

    static fromServer(input: AppUserSettingsOutput) {
        return new AppUserSettingsFE(
            input.id,
            input.timezone,
            input.locale,
            input.country,
            NOT_INCLUDED_SETTINGS.dateFormat,
            NOT_INCLUDED_SETTINGS.dateTimeFormat,
            input.hideEventPrices,
        );
    }
}
