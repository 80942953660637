import { Fragment, useCallback, useEffect, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'react-bootstrap';
import { DatePicker } from '.';
import { type DateTime } from 'luxon';
import { computeByDay, type Day, Frequency, type Recurrence, WEEK_DAYS, type ByDay, FREQUENCIES, getWeekDayNumber } from ':utils/recurrence/';
import FormSelect, { StringSelect } from './FormSelect';
import { getEnumValues } from ':utils/common';
import { toNumber, transformToIntegerOrEmpty, transformToPositiveIntegerOrEmpty } from ':frontend/utils/math';
import { type TFunction } from 'i18next';
import type { SingleValue } from 'react-select';
import { getDayTranslator } from ':frontend/types/i18n';
import clsx from 'clsx';
import { getI18nLocale } from ':utils/i18n';

type RecurrenceFormProps = Readonly<{
    after: DateTime;
    input: Recurrence;
    onSubmit: (output: Recurrence) => void;
    onCancel: () => void;
    countLimit?: number;
}>;

export default function RecurrenceForm({ after, input, onSubmit, onCancel, countLimit }: RecurrenceFormProps) {
    const { t } = useTranslation('common', { keyPrefix: 'recurrence.form' });
    const { t: tEnd } = useTranslation('common', { keyPrefix: 'recurrence.end' });
    const [ state, dispatch ] = useReducer(recurrenceReducer, computeInitialState(after, input, countLimit));

    useEffect(() => {
        dispatch({ type: 'set', field: 'after', value: after });
    }, [ after ]);

    function innerOnSubmit() {
        const recurrence = formToRecurrence(state.form);
        if (recurrence)
            onSubmit(recurrence);
    }

    return (
        <div className='sh-recurrence-form'>
            <div className='d-flex align-items-center flex-wrap gap-2'>
                <div className='d-flex align-items-center gap-2'>
                    <span className='fw-medium'>{t('repeat-every', { count: state.form.validInterval })}</span>
                    <div className='sh-interval-frequency'>
                        <Form.Control
                            type='number'
                            value={state.form.interval}
                            onChange={e => dispatch({ type: 'input', field: 'interval', value: transformToIntegerOrEmpty(e.target.value) })}
                            style={{ width: '50px' }}
                        />
                        <div className='sh-divider' />
                        <FrequencySelect state={state} dispatch={dispatch} />
                    </div>
                </div>
                {state.form.frequency === Frequency.Monthly && (
                    <div className='d-flex align-items-center gap-2'>
                        <span className='fw-medium'>{t('on-day')}</span>
                        <MonthInput state={state} dispatch={dispatch} />
                    </div>
                )}
                {state.form.frequency === Frequency.Weekly && (
                    <div className='d-flex align-items-center gap-2'>
                        <span className='fw-medium'>{t('on-day')}</span>
                        <DayInput state={state} dispatch={dispatch} />
                    </div>
                )}
                <div className='d-flex align-items-center gap-2'>
                    <div className='sh-count-until'>
                        <div className='flex-shrink-0'>
                            <StringSelect
                                value={state.form.endType}
                                onChange={endType => dispatch({ type: 'input', field: 'endType', value: endType as EndType })}
                                options={endTypeValues}
                                t={tEnd}
                                className='rs-remove-indicator rs-menu-overflow'
                                isSearchable={false}
                            />
                        </div>
                        {/* {state.form.endType !== EndType.Never && ( */}
                        <div className='sh-divider' />
                        {/* )} */}
                        {state.form.endType === EndType.Count && (
                            <Form.Control
                                type='number'
                                value={state.form.count}
                                onChange={e => dispatch({ type: 'input', field: 'count', value: transformToIntegerOrEmpty(e.target.value) })}
                                className='text-center'
                                style={{ width: '50px' }}
                            />
                        )}
                        {state.form.endType === EndType.Until && (
                            <div style={{ width: '100px' }}>
                                <DatePicker
                                    selected={state.form.until}
                                    onChange={until => until && dispatch({ type: 'input', field: 'until', value: until })}
                                    type='date'
                                />
                            </div>
                        )}
                    </div>
                    {state.form.endType === EndType.Count && (
                        <span className='fw-medium'>{t('count-unit', { count: toNumber(state.form.count) })}</span>
                    )}
                </div>
            </div>
            <div className='mt-3 text-end'>
                <Button onClick={onCancel} className='compact' variant='outline-secondary me-2'>
                    {t('cancel-button')}
                </Button>
                <Button onClick={innerOnSubmit} className='compact'>
                    {t('submit-button')}
                </Button>
            </div>
        </div>
    );
}

enum EndType {
    // Never = 'never',
    Count = 'count',
    Until = 'until',
}

const endTypeValues = getEnumValues(EndType);

type StateDispatchProps = Readonly<{
    state: RecurrenceState;
    dispatch: (action: RecurrenceAction) => void;
}>;

function FrequencySelect({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('common', { keyPrefix: 'recurrence.frequency' });
    const { frequency, validInterval } = state.form;
    const options = useMemo(() => FREQUENCIES.map(value => frequencyToOption(value, validInterval, t)), [ t, validInterval ]);

    const value = useMemo(() => frequencyToOption(frequency, validInterval, t), [ frequency, validInterval, t ]);
    const onChange = useCallback((option: SingleValue<FrequencyOption>) => {
        if (option)
            dispatch({ type: 'input', field: 'frequency', value: option.value });
    }, [ dispatch ]);

    return (
        <FormSelect
            value={value}
            onChange={onChange}
            options={options}
            className='rs-remove-indicator rs-menu-overflow'
        />
    );
}

type FrequencyOption = { value: Frequency, label: string };

function frequencyToOption(value: Frequency, count: number, t: TFunction): FrequencyOption {
    return {
        value,
        label: t(value, { count }),
    };
}

function MonthInput({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('common', { keyPrefix: 'recurrence' });
    const options = useMemo(() => createMonthOptions(state.form.monthData, t), [ state.form.monthData, t ]);

    const value = options.find(option => option.value === state.form.monthType);
    const onChange = useCallback((option: SingleValue<MonthOption>) => {
        if (option)
            dispatch({ type: 'input', field: 'monthType', value: option.value });
    }, [ dispatch ]);

    return (
        <FormSelect
            value={value}
            onChange={onChange}
            options={options}
            className='rs-remove-indicator rs-menu-overflow'
        />
    );
}

type MonthOption = { value: MonthType, label: string };

function createMonthOptions(data: MonthData, t: TFunction): MonthOption[] {
    // We have to use custom translation for the day here because we need a different gramatical case.
    const fromStartDay = t(`day.${data.byDayFromStart.weekDay}`);
    const fromStartLabel = data.byDayFromStart.occurence === 1
        ? t('month.fromStart-first', { day: fromStartDay })
        : t('month.fromStart', { count: data.byDayFromStart.occurence, day: fromStartDay, ordinal: true });

    const output = [
        { value: MonthType.MonthDay, label: t('month.monthDay', { count: data.byMonthDay, ordinal: true } ) },
        { value: MonthType.FromStart, label: fromStartLabel },
    ];

    // We show only the last day because the other usecases are very rare (and it's a translation nightmare).
    if (data.byDayFromEnd.occurence === 1) {
        const fromEndDay = t(`day.${data.byDayFromEnd.weekDay}`);
        const fromEnd = { value: MonthType.FromEnd, label: t('fromEnd', { day: fromEndDay }) };
        output.push(fromEnd);
    }

    return output;
}

function DayInput({ state, dispatch }: StateDispatchProps) {
    const { t, i18n } = useTranslation('common', { keyPrefix: 'recurrence.day' });
    const translator = getDayTranslator(getI18nLocale(i18n));

    return (
        <div className='sh-day-input'>
            {WEEK_DAYS.map((day, index) => {
                const isSelected = state.form.byWeekDay[index];
                const dayName = translator(index + 1);
                const toggle = () => dispatch({ type: 'input', field: 'byWeekDay', index, value: !isSelected });

                return (
                    <Fragment key={day}>
                        <div
                            role='checkbox'
                            tabIndex={0}
                            aria-label={t(`day-input-${isSelected ? 'checked' : 'unchecked'}-aria`, { day: dayName })}
                            aria-checked={isSelected}
                            onClick={toggle}
                            onKeyDown={toggle}
                            className={clsx(isSelected && 'selected')}
                        >
                            {dayName.substring(0, 1).toUpperCase()}
                        </div>

                        {index < WEEK_DAYS.length - 1 && <div className='sh-divider' />}
                    </Fragment>
                );
            })}
        </div>
    );
}

enum MonthType {
    MonthDay = 'monthDay', // E.g., 15th of each month.
    FromStart = 'fromStart', // E.g., the first Monday of each month.
    FromEnd = 'fromEnd', // E.g., the last Monday of each month.
}

type MonthData = {
    byMonthDay: number;
    byDayFromStart: ByDay;
    byDayFromEnd: ByDay;
}

type RecurrenceState = {
    after: DateTime;
    input: Recurrence;
    form: RecurrenceFormState;
    countLimit: number | undefined;
};

type RecurrenceFormState = {
    frequency: Frequency;
    interval: number | '';
    /** Last valid value. It's needed for plurals in translations. */
    validInterval: number;
    count: number | '';
    until: DateTime;
    byWeekDay: boolean[];
    monthType: MonthType;
    monthData: MonthData;
    endType: EndType;
};

function inputToFormWeek(date: DateTime, input: Recurrence): Pick<RecurrenceFormState, 'byWeekDay'> {
    const byWeekDay = WEEK_DAYS.map(() => false);
    input.byWeekDay?.forEach(day => byWeekDay[getWeekDayNumber(day) - 1] = true);

    return { byWeekDay };
}

function weekFormToRecurrence(form: RecurrenceFormState): Pick<Recurrence, 'byWeekDay'> {
    if (form.frequency !== Frequency.Weekly)
        return {};

    const byWeekDay: Day[] = [];
    form.byWeekDay.forEach((value, index) => value && byWeekDay.push(WEEK_DAYS[index]));

    return { byWeekDay };

}

function inputToFormMonth(date: DateTime, input: Recurrence): Pick<RecurrenceFormState, 'monthType' | 'monthData'> {
    return {
        monthType: MonthType.MonthDay,
        monthData: {
            byMonthDay: input.byMonthDay ? input.byMonthDay : date.day,
            byDayFromStart: (input.byDay && input.byDay.occurence > 0) ? input.byDay : computeByDay(date, true),
            byDayFromEnd: (input.byDay && input.byDay.occurence < 0) ? input.byDay : computeByDay(date, false),
        },
    };
}

function monthFormToRecurrence(form: RecurrenceFormState): Pick<Recurrence, 'byMonthDay' | 'byDay'> {
    if (form.frequency !== Frequency.Monthly)
        return {};

    switch (form.monthType) {
    case MonthType.MonthDay:
        return { byMonthDay: form.monthData.byMonthDay };
    case MonthType.FromStart:
        return { byDay: form.monthData.byDayFromStart };
    case MonthType.FromEnd:
        return { byDay: form.monthData.byDayFromEnd };
    }
}

function computeInitialState(after: DateTime, input: Recurrence, countLimit: number | undefined): RecurrenceState {
    return {
        after,
        input,
        form: {
            frequency: input.frequency,
            interval: input.interval,
            validInterval: input.interval,
            count: input.count ?? countLimit ?? 12,
            until: input.until ?? after.plus({ weeks: 4 }),
            ...inputToFormWeek(after, input),
            ...inputToFormMonth(after, input),
            // endType: input.count ? EndType.Count : input.until ? EndType.Until : EndType.Never,
            endType: input.count ? EndType.Count : EndType.Until,
        },
        countLimit,
    };
}

function formToRecurrence(form: RecurrenceFormState): Recurrence | undefined {
    // Empty string and zero are valid values in the form, but not valid values for the recurrence.
    const interval = transformToPositiveIntegerOrEmpty(form.interval);
    if (interval === '')
        return undefined;

    // The same holds true for the count.
    const count = form.endType === EndType.Count ? transformToPositiveIntegerOrEmpty(form.count) : undefined;
    if (count === '')
        return undefined;

    return {
        frequency: form.frequency,
        interval,
        count,
        until: form.endType === EndType.Until ? form.until : undefined,
        ...weekFormToRecurrence(form),
        ...monthFormToRecurrence(form),
    };
}

type RecurrenceAction = InputAction | SetAction;

type InputAction = {
    type: 'input';
} & ({
    field: 'frequency';
    value: Frequency;
} | {
    field: 'interval';
    value: number | '';
} | {
    field: 'count';
    value: number | '';
} | {
    field: 'until';
    value: DateTime;
} | {
    field: 'monthType';
    value: MonthType;
} | {
    field: 'byWeekDay';
    index: number;
    value: boolean;
} | {
    field: 'endType';
    value: EndType;
});

function recurrenceReducer(state: RecurrenceState, action: RecurrenceAction) {
    console.log('Reduce:', state, action);
    switch (action.type) {
    case 'input': return { ...state, form: input(state, action) };
    case 'set': return set(state, action);
    }
}

function input(state: RecurrenceState, action: InputAction): RecurrenceFormState {
    if (action.field === 'frequency')
        return inputFrequency(state, action.value);

    if (action.field === 'byWeekDay') {
        const byWeekDay = state.form.byWeekDay.slice();
        byWeekDay[action.index] = action.value;

        // At leas one day must be checked. So if the user unchecks the last one, we check the current one.
        if (byWeekDay.every(value => !value))
            byWeekDay[state.after.weekday - 1] = true;

        return { ...state.form, byWeekDay };
    }

    if (action.field === 'interval') {
        const validInterval = validateInterval(action.value, state.form.validInterval);
        return { ...state.form, [action.field]: action.value, validInterval };
    }

    return { ...state.form, [action.field]: action.value };
}

function validateInterval(interval: number | '', lastValidInterval: number): number {
    const positive = transformToPositiveIntegerOrEmpty(interval);
    return positive === '' ? lastValidInterval : positive;
}

function inputFrequency(state: RecurrenceState, value: Frequency): RecurrenceFormState {
    if (value === Frequency.Weekly)
        return { ...state.form, frequency: value, ...inputToFormWeek(state.after, state.input) };
    if (value === Frequency.Monthly)
        return { ...state.form, frequency: value, ...inputToFormMonth(state.after, state.input) };

    return { ...state.form, frequency: value };
}

type SetAction = {
    type: 'set';
    field: 'after';
    value: DateTime;
};

function set(state: RecurrenceState, action: SetAction): RecurrenceState {
    return computeInitialState(action.value, state.input, state.countLimit);
}
