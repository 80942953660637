import { memo } from 'react';
import { DateTime } from 'luxon';
import { capitalize } from ':frontend/utils/common';
import { localizer } from ':frontend/lib/calendar';
import { useTranslation } from 'react-i18next';
import { FORMATS } from ':utils/i18n';
import clsx from 'clsx';

type DateTimeFormatType = { date?: true } | { time?: true };

type DateTimeDisplayProps = Readonly<{
    dateTime: DateTime;
    className?: string;
} & DateTimeFormatType>;

export default function DateTimeDisplay({ dateTime, className, ...type }: DateTimeDisplayProps) {
    return (
        <span className={clsx('sh-date-time-display', className)}>{dateTime.toFormat(getFormat(type))}</span>
    );
}

function getFormat(type: DateTimeFormatType): string {
    if ('date' in type)
        return FORMATS.date;

    if ('time' in type)
        return FORMATS.time;

    return FORMATS.dateTime;
}

export function DayDisplay({ dateTime }: Readonly<{ dateTime: DateTime }>) {
    return (
        <span className='fw-semibold'>{capitalize(dateTime.toFormat('cccc'))}</span>
    );
}

type DayLongFormatProps = Readonly<{
    day: DateTime;
}>;

export const DayLongFormat = memo(DayLongFormatRaw);

function DayLongFormatRaw({ day }: DayLongFormatProps) {
    return (
        <span>
            <DayDisplay dateTime={day}/>{' '}<DateTimeDisplay dateTime={day} date />
        </span>
    );
}

type EventRangeFormatProps = Readonly<{
    start: DateTime;
    end: DateTime;
    currentDayStart: DateTime;
}>;

export const EventRangeFormat = memo(EventRangeFormatRaw);

function EventRangeFormatRaw({ start, end, currentDayStart }: EventRangeFormatProps) {
    const { t } = useTranslation('components', { keyPrefix: 'calendar' });

    if (localizer.isSameDate(start, end)) {
        // The event starts and ends on the same day => we display only times.
        return (
            <span className={eventRangeClassName}>{timeFormat(start)}{' - '}{timeFormat(end)}</span>
        );
    }

    // Now we know that the event spans multiple days.

    if (localizer.isSameDate(start, currentDayStart)) {
        // The event starts on the same day as the current day => we display only the start time.
        return (
            <span className={eventRangeClassName}>{timeFormat(start)}{' - '}{floatingDots(end)}</span>
        );
    }

    if (localizer.isSameDate(end, currentDayStart)) {
    // The event ends on the same day as the current day => we display only the end time.
    // The first part is here (but hidded) just for the spacing.
        return (
            <span className={eventRangeClassName}>{floatingDots(start)}{' - '}{timeFormat(end)}</span>
        );
    }

    // The event spans the whole current day.
    return (
        <span className={eventRangeClassName}>{t('allDay')}</span>
    );
}

// Just a small optimalization, because Luxon is extremely slow.
// The difference is pretty significant (more than 1000x) so we should probably use it for other formats as well.
function timeFormat(date: DateTime): string {
    return ('' + date.hour).padStart(2, '0') + ':' + ('' + date.minute).padStart(2, '0');
}

const eventRangeClassName = 'fw-medium monospace-numbers text-nowrap';

function floatingDots(date: DateTime) {
    return (
        <span className='position-relative'>
            <span className='invisible'>{localizer.format(date, FORMATS.time)}</span>
            <span className='position-absolute' style={{ left: '50%', transform: 'translateX(-50%)' }}>...</span>
        </span>
    );
}

type DateRangeDisplayProps = Readonly<{
    from: DateTime;
    to: DateTime;
    className?: string;
    dateClassName?: string;
    hideMonthIfSame?: boolean;
    hideYearIfCurrent?: boolean;
}>;

export function DateRangeDisplay({ from, to, className, dateClassName, hideMonthIfSame, hideYearIfCurrent }: DateRangeDisplayProps) {
    const { fromFormat, toFormat } = getRangeFormats(from, to, hideMonthIfSame, hideYearIfCurrent);

    return (
        <div className={clsx('d-flex flex-nowrap gap-2', className)}>
            <span className={clsx('sh-date-time-display', dateClassName)}>{from.toFormat(fromFormat)}</span>
            {'-'}
            <span className={clsx('sh-date-time-display', dateClassName)}>{to.toFormat(toFormat)}</span>
        </div>
    );
}

function getRangeFormats(from: DateTime, to: DateTime, hideMonthIfSame?: boolean, hideYearIfCurrent?: boolean) {
    if (from.year !== to.year)
        return { fromFormat: 'dd. MM. yyyy', toFormat: 'dd. MM. yyyy' };

    const yearFormat = hideYearIfCurrent && from.year === DateTime.now().year ? '' : ' yyyy';

    if (!hideMonthIfSame || from.month !== to.month)
        return { fromFormat: 'dd. MM.', toFormat: 'dd. MM.' + yearFormat };

    return { fromFormat: 'dd.', toFormat: 'dd. MM.' + yearFormat };
}

export function dateRangeToString(from: DateTime, to: DateTime, hideMonthIfSame?: boolean, hideYearIfCurrent?: boolean): string {
    const { fromFormat, toFormat } = getRangeFormats(from, to, hideMonthIfSame, hideYearIfCurrent);
    return `${from.toFormat(fromFormat)}  -  ${to.toFormat(toFormat)}`;
}
