import { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CheckIcon, EyeIcon } from ':frontend/components/icons';
import Portal, { portals } from ':frontend/components/common/Portal';
import { SpinnerButton } from ':frontend/components/common';
import { useMaster } from ':frontend/context/UserProvider';
import { useBlockerModal, useToggle } from ':frontend/hooks';
import BlockNavigationModal from ':frontend/components/BlockNavigationModal';
import GeneralSupplierForm, { type GeneralSupplierFormOutput } from ':frontend/components/settings/GeneralSupplierForm';
import PersonalizationForm, { type PersonalizationFormOutput } from ':frontend/components/settings/PersonalizationForm';
import { InvoicingProfileFE } from ':frontend/types/Invoicing';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import { InfoTooltip } from ':frontend/components/forms/buttons';
import { AddProfileButton } from './InvoicingProfilesEditor';
import { InfoCard } from ':frontend/components/settings/InfoCard';
import { trpc } from ':frontend/context/TrpcProvider';
import { InvoicingProfileExampleModal } from ':frontend/components/orders/InvoicingProfileExampleModal';

type InvoicingProfileEditorProps = Readonly<{
    type: 'general' | 'custom';
}>;

export default function InvoicingProfileEditor({ type }: InvoicingProfileEditorProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.invoicing-profile' });
    const { profiles, setProfiles } = useMaster();
    const profile = profiles[0];

    const setProfile = useCallback((profile: InvoicingProfileFE) => {
        setProfiles(oldProfiles => {
            const index = oldProfiles.findIndex(p => p.id === profile.id);
            const newProfiles = [ ...oldProfiles ];
            newProfiles[index] = profile;

            return newProfiles;
        });
    }, [ setProfiles ]);

    const [ isDirty, setIsDirty ] = useState(false);
    const { control } = useBlockerModal(isDirty);

    return (
        <div>
            <BlockNavigationModal control={control} />
            <div className='d-flex align-items-top mb-4'>
                <h1 className='m-0'>{t(`${type}-page-title`)}</h1>
                {type === 'general' && (<>
                    <div className='flex-grow-1' />
                    <div style={{ height: '28.8px' }}>
                        <AddProfileButton className='compact' />
                    </div>
                    <div style={{ position: 'relative', left: '7px' }}>
                        <InfoTooltip text={t('new-profile-tooltip')} />
                    </div>
                </>)}
            </div>
            <InfoCard infoKey='invoicingProfiles' className='mb-4' />
            <InvoicingProfileFormPart profile={profile} setProfile={setProfile} isDirty={isDirty} setIsDirty={setIsDirty} type={type} />
        </div>
    );
}

const formParts = {
    general: GeneralSupplierForm,
    custom: PersonalizationForm,
};

type InvoicingProfileFormPartProps = {
    profile: InvoicingProfileFE;
    setProfile: (newProfile: InvoicingProfileFE) => void;
    type: keyof typeof formParts;
    isDirty: boolean;
    setIsDirty: (isDirty: boolean) => void;
};

function InvoicingProfileFormPart({ profile, setProfile, type, isDirty, setIsDirty }: InvoicingProfileFormPartProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.invoicing-profile' });
    const [ showExampleModal, setShowExampleModal ] = useToggle(false);
    const { addAlert } = useNotifications();

    const updateInvoicingProfileMutation = trpc.invoicing.updateInvoicingProfile.useMutation();

    function onSubmit(edit: GeneralSupplierFormOutput | PersonalizationFormOutput) {
        updateInvoicingProfileMutation.mutate({ id: profile.id, ...edit }, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: response => {
                setProfile(InvoicingProfileFE.fromServer(response));
                addAlert(createTranslatedSuccessAlert('pages:invoicing-profile.save-success-alert'));
            },
        });
    }

    const saveButton = useCallback(({ onClick }: { onClick: () => void }) => (
        <SaveButton onClick={onClick} isFetching={updateInvoicingProfileMutation.isPending} />
    ), [ updateInvoicingProfileMutation.isPending ]);

    const InnerForm = formParts[type];

    return (<>
        <InnerForm
            onSubmit={onSubmit}
            input={profile}
            onChange={setIsDirty}
            saveButton={isDirty ? saveButton : undefined}
        />
        <div className='d-flex gap-3 mt-4'>
            <Button variant='outline-primary' onClick={setShowExampleModal.true}>
                <EyeIcon size={22} className='me-2' />
                {t('example-invoice-button')}
            </Button>
            <div id={portals.invoicingProfile.save} />
        </div>
        <InvoicingProfileExampleModal profile={showExampleModal ? profile : undefined} onHide={setShowExampleModal.false} />
    </>);
}

type SaveButtonProps = Readonly<{
    onClick: () => void;
    isFetching: boolean;
}>;

function SaveButton({ onClick, isFetching }: SaveButtonProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'invoicing-profile' });

    return (
        <Portal to={portals.invoicingProfile.save}>
            <SpinnerButton
                type='submit'
                isFetching={isFetching}
                variant='primary'
                onClick={onClick}
            >
                <CheckIcon size={22} />
                {t('save-button')}
            </SpinnerButton>
        </Portal>
    );
}
