import { useCallback, type Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'react-bootstrap';
import { type FECustomItemInit, determineIsSendNotification } from ':frontend/types/orders/Order';
import { PaymentMethodSelect } from ':frontend/components/forms/PaymentMethodSelect';
import { MoneyDisplay, SpinnerButton } from ':frontend/components/common';
import { ClientIconRow } from ':frontend/components/client/ClientIconLink';
import { FileIcon, SendIcon } from ':frontend/components/icons';
import { CloseButton } from ':frontend/components/forms/buttons';
import { getClientOrContact } from ':frontend/types/EventParticipant';
import { PercentDisplay } from ':frontend/components/common/MoneyDisplay';
import { type CheckoutOutput } from './Checkout';
import { CheckoutPhase, type CheckoutState, type CheckoutAction, type Cache, type CacheItem, CheckoutType } from './useCheckout';
import clsx from 'clsx';
import { ProductOrderBar } from '../ProductOrderForm';
import { floatToPercent } from ':frontend/utils/math';
import { PaymentMethod } from ':utils/entity/order';

type OverviewProps = Readonly<{
    output: Dispatch<CheckoutOutput>;
    state: CheckoutState;
    dispatch: Dispatch<CheckoutAction>;
    isFetching: boolean;
    createOrder: () => void;
    showCloseButton?: boolean;
}>;

export function Overview({ output, state, dispatch, isFetching, createOrder, showCloseButton }: OverviewProps) {
    const { t } = useTranslation('components', { keyPrefix: 'checkout.overview' });
    const { cache, input, overview: { paymentMethod, isSendNotification } } = state;
    const discount = 'discount' in input ? input.discount : undefined;
    const determinedNotification = determineIsSendNotification(paymentMethod);

    const setPaymentMethod = useCallback((method: PaymentMethod) => {
        dispatch({ type: 'overview', paymentMethod: method });
    }, [ dispatch ]);

    return (
        <div className='sh-checkout-overview position-relative d-flex h-100'>
            {showCloseButton && (
                <div className='position-absolute top-3 end-3'>
                    <CloseButton onClick={() => output({ type: 'close' })} aria={t('close-modal-aria')} />
                </div>
            )}
            <div className='w-50 rounded-start-4' style={{ padding: '40px 60px', backgroundColor: '#F8F8F8' }}>
                {state.input.type !== CheckoutType.Event && (<>
                    <h2 className='mb-3 mt-0'>{t('items-title')}</h2>
                    <div className='d-flex flex-column gap-3 mb-4'>
                        {cache.items.map(cacheItemDisplay)}
                        {discount && (
                            <div className='d-flex flex-column gap-2 p-3 bg-white rounded shadow-sm'>
                                <div className='d-flex justify-content-between lha-3'>
                                    <h3 className='mb-0'>{discount.label}</h3>
                                    <span className='fs-3'><PercentDisplay amount={floatToPercent(discount.amount)}/></span>
                                </div>
                                <div className='d-flex justify-content-end gap-2'>
                                    {cache.overviewForCurrencies.map((forCurrency, index) => (
                                        <span key={forCurrency.totalDiscount.currency.id}>
                                            <MoneyDisplay money={forCurrency.totalDiscount} />
                                            {index < cache.overviewForCurrencies.length - 1 && ','}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </>)}
                <h2 className='mb-3 mt-0'>{t('payment-type-title')}</h2>
                <PaymentMethodSelect
                    value={paymentMethod}
                    onChange={setPaymentMethod}
                    currencyIds={cache.currencyIds}
                    options={cache.paymentOptions}
                />
                <div className='d-flex justify-content-end mt-5'>
                    <Button onClick={() => output({ type: 'back' })} variant='outline-secondary w-25'>
                        {t('back-button')}
                    </Button>
                </div>
            </div>
            <div className='w-50' style={{ padding: '40px 60px' }}>
                <h2 className='mb-3 mt-0'>{t('overview-title')}</h2>
                <InvoicesTable cache={cache} paymentMethod={paymentMethod} dispatch={dispatch} />
                <Form.Group>
                    <Form.Check
                        checked={isSendNotification}
                        disabled={determinedNotification !== undefined}
                        onChange={event => dispatch({ type: 'overview', isSendNotification: event.target.checked })}
                        label={<span className='fw-medium'>{t('notify-label', { count: cache.clients.length })}</span>}
                        id='send-notification-checkbox'
                        type='switch'
                    />
                </Form.Group>
                <div className='p-3 rounded-3 mt-3' style={{ backgroundColor: '#F5F5F5' }}>
                    {isSendNotification ? (<>
                        {/* TODO invoices length */}
                        <div className='text-muted'>{t('notify-description-true', { count: 1 })}</div>
                        <Button
                            variant='outline-secondary'
                            className='compact mt-2'
                            onClick={() => dispatch({ type: 'phase', phase: CheckoutPhase.EmailPreview })}
                        >
                            <SendIcon size={18} className='me-2' />
                            {t('preview-email-button')}
                        </Button>
                    </>) : (
                        <div className='text-muted'>{t('notify-description-false', { count: 1 })}</div>
                    )}
                </div>
                <SpinnerButton
                    variant='primary'
                    className='w-100 mt-3'
                    onClick={createOrder}
                    isFetching={isFetching}
                    style={{ height: '49px' }}
                >
                    {t(paymentMethod !== PaymentMethod.noInvoice ? 'create-orders-button' : 'create-orders-without-payment-button')}
                </SpinnerButton>
            </div>
        </div>
    );
}

function cacheItemDisplay(item: CacheItem, index: number) {
    if ('product' in item) {
        return (
            <ProductOrderBar key={index} product={item.product} />
        );
    }

    // TODO this shouldn't happen
    if ('event' in item)
        return null;

    return (
        <CustomItemDisplay key={index} item={item} />
    );
}

type CustomItemDisplay = Readonly<{
    item: FECustomItemInit;
    className?: string;
}>;

export function CustomItemDisplay({ item, className }: CustomItemDisplay) {
    return (
        <div className={clsx('p-3 bg-white rounded shadow-sm', className)}>
            <h3 className='fs-base mb-0 lha-3 text-truncate'>{item.label}</h3>
            <div className='d-flex gap-3 mt-2'>
                <span className='fw-medium me-1'>{item.quantity}×</span>
                <span className='fw-medium me-1'><MoneyDisplay money={item.price} /></span>
            </div>
        </div>
    );
}

type InvoicesTableProps = Readonly<{
    cache: Cache;
    paymentMethod: PaymentMethod;
    dispatch: Dispatch<CheckoutAction>;
}>;

function InvoicesTable({ cache, paymentMethod, dispatch }: InvoicesTableProps) {
    const { t } = useTranslation('components', { keyPrefix: 'checkout.overview' });
    const { invoicesForClients, overviewForCurrencies } = cache;

    return (
        <div>
            <div className='d-flex flex-column border-bottom'>
                {invoicesForClients.map(forClient => (
                    <div key={forClient.client.identifier.toString()} className='border-top'>
                        {forClient.invoices.map((invoice, index) => (
                            <div key={invoice.price.currency.id} className='d-flex align-items-center gap-2 py-2'>
                                <div className='flex-grow-1 text-truncate'>
                                    {index === 0 && (
                                        <ClientIconRow client={getClientOrContact(forClient.client)} />
                                    )}
                                </div>
                                <span className='py-2 px-3 rounded' style={{ height: '32px', backgroundColor: '#F5F5F5' }}>
                                    <MoneyDisplay money={invoice.price} />
                                </span>
                                {paymentMethod !== PaymentMethod.noInvoice && (
                                    <Button variant='outline-secondary' className='compact' onClick={() => dispatch({ type: 'invoicePreview', invoice })}>
                                        <FileIcon size={18} className='me-2' />
                                        {t('preview-invoice-button')}
                                    </Button>
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className='d-flex justify-content-end py-2'>
                <span className='lha-3 py-2 px-3 rounded d-flex gap-2 fs-6' style={{ backgroundColor: '#F5F5F5' }}>
                    <span className='fw-medium'>{t('total-price-label')}</span>
                    {overviewForCurrencies.map((forCurrency, index) => (
                        <span key={forCurrency.totalPrice.currency.id}>
                            <MoneyDisplay money={forCurrency.totalPrice} />
                            {index < overviewForCurrencies.length - 1 && ','}
                        </span>
                    ))}
                </span>
            </div>
        </div>
    );
}
