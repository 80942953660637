import { type InvoiceInfo } from ':frontend/types/orders/Order';
import clsx from 'clsx';

export const INDEX_DISPLAY_DIGITS = 4;

function getPaddedIndex(index: number): string {
    return index.toString().padStart(INDEX_DISPLAY_DIGITS, '0');
}

export function getInvoiceNumber(invoice: InvoiceInfo): string {
    return invoice.prefix + getPaddedIndex(invoice.index);
}

type InvoiceNumberDisplayProps = {
    className?: string;
} & ({
    invoice: InvoiceInfo;
} | {
    index: number;
    prefix: string;
});

export function InvoiceNumberDisplay({ className, ...rest }: InvoiceNumberDisplayProps) {
    const index = 'index' in rest ? rest.index : rest.invoice.index;
    const prefix = 'prefix' in rest ? rest.prefix : rest.invoice.prefix;

    return (
        <div className={clsx('sh-invoice-number', className)}>
            <span className='text-nowrap'>{prefix}{getPaddedIndex(index)}</span>
        </div>
    );
}
