import { useToggle } from ':frontend/hooks';
import { Button, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DotsHorizontalIcon } from '../icons';

type MoreButtonDropdownProps = Readonly<{
    children: React.ReactNode;
}>;

export default function MoreButtonDropdown({ children }: MoreButtonDropdownProps) {
    const { t } = useTranslation('common', { keyPrefix: 'buttons' });
    const [ isShow, setIsShow ] = useToggle(false);

    return (
        <Dropdown drop='down-centered' autoClose='outside' show={isShow} onToggle={setIsShow.toggle}>
            <Button onClick={setIsShow.toggle} variant='ghost-secondary' className='square'>
                <DotsHorizontalIcon size={22} />
                {t('more-button-label')}
            </Button>
            <Dropdown.Menu className='sh-dropdown-menu min-w-0 mt-2' style={{ left: '-7px' }}>
                <div className='sh-dropdown-menu-inner'>
                    {children}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
}
