import { type ReactNode, useMemo, useState } from 'react';
import { type ClientFE } from ':frontend/types/Client';
import { DateTime } from 'luxon';
import { type MonthOption, generateMonthOptionsTranslator } from ':frontend/types/i18n';
import { Trans, useTranslation } from 'react-i18next';
import FormSelect from ':frontend/components/forms/FormSelect';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { type CurrencyFE } from ':frontend/modules/money';
import { masterComponent, useMaster } from ':frontend/context/UserProvider';
import CurrencySelect from '../forms/CurrencySelect';
import { MoneyDisplay, Skeleton } from '../common';
import { FaChartPie } from 'react-icons/fa';
import { CircleSemiFullIcon, InfoIcon } from '../icons';
import { trpc } from ':frontend/context/TrpcProvider';
import { getI18nLocale } from ':utils/i18n';

type ClientStatsProps = Readonly<{
    client: ClientFE;
}>;

export const ClientStatsView = masterComponent(({ client }: ClientStatsProps) => {
    const { teamSettings } = useMaster();
    const { t } = useTranslation('components', { keyPrefix: 'clientForm.stats' });

    const [ currency, setCurrency ] = useState<CurrencyFE>(teamSettings.currency);
    const [ issuedAfter, setIssuedAfter ] = useState(() => monthYearToRange('all', DateTime.now().year)[0]);
    const [ issuedBefore, setIssuedBefore ] = useState(() => monthYearToRange('all', DateTime.now().year)[1]);

    const clientStatsQuery = trpc.$client.getClientStats.useQuery({
        clientId: client.id,
        currency: currency.id,
        issuedAfter,
        issuedBefore,
    });

    function handleDateChange(startDate: DateTime, endDate: DateTime) {
        setIssuedAfter(startDate);
        setIssuedBefore(endDate);
    }

    return (
        <div>
            <h5 className='d-flex align-items-center mb-3'><FaChartPie size={24} className='me-3' style={{ marginLeft: '2px' }} />{t('stats-title')}</h5>
            <Row className='gx-3 mb-3'>
                <Col xs={3}>
                    <MonthYearSelect onChange={handleDateChange} />
                </Col>
                <Col />
                <Col xs={3}>
                    <CurrencySelect value={currency} onChange={newCurrency => newCurrency && setCurrency(newCurrency)} />
                </Col>
            </Row>
            {clientStatsQuery.data ? (
                <Row className='gx-3 mb-4'>
                    <StatsTile translationId='orders-total'>
                        <MoneyDisplay money={{ amount: clientStatsQuery.data.ordersTotal, currency }} />
                    </StatsTile>
                    <StatsTile translationId='orders-unpaid'>
                        <MoneyDisplay money={{ amount: clientStatsQuery.data.ordersUnpaid, currency }} />
                    </StatsTile>
                    <StatsTile translationId='order-count'>
                        {clientStatsQuery.data.orderCount}
                    </StatsTile>
                    <StatsTile translationId='events-invoiced'>
                        {clientStatsQuery.data.eventsInvoiced}
                    </StatsTile>
                </Row>
            ) : (
                <Skeleton height={109} className='mb-4' />
            )}
        </div>
    );
});

function createYearOptions() {
    const now = DateTime.now();
    const maxYear = now.year + (now.month < 7 ? 0 : 1);
    // Fallback for users who like to mess with time settings.
    const minYear = now.year >= 2023 ? 2023 : now.year; // TODO probably something like user registration date.

    const years = [ ...new Array(maxYear - minYear + 1) ].map((_, index) => minYear + index);
    years.reverse();

    return years.map(yearToOption);
}

function yearToOption(year: number): MonthOption {
    return {
        value: year,
        label: year.toString(),
    };
}

const YEAR_OPTIONS = createYearOptions();

type MonthYearSelectProps = Readonly<{
    onChange: (startDate: DateTime, endDate: DateTime) => void;
}>;

function MonthYearSelect({ onChange }: MonthYearSelectProps) {
    const { t, i18n } = useTranslation('components', { keyPrefix: 'clientForm.stats' });
    const [ monthSelection, setMonthSelection ] = useState<number | 'all'>('all');
    const [ yearSelection, setYearSelection ] = useState<number>(() => DateTime.now().year);

    const locale = getI18nLocale(i18n);
    const monthOptions = useMemo(() => {
        const monthOptions = generateMonthOptionsTranslator(locale);
        const allMonthsOption = {
            value: 'all' as const,
            label: t('whole-year'),
        };

        function monthToOption(month: number | 'all') {
            return month === 'all'
                ? allMonthsOption
                : monthOptions.monthToOption(month);
        }

        return {
            options: [
                allMonthsOption,
                ...monthOptions.options,
            ],
            monthToOption,
        };
    }, [ t, locale ]);

    function handleMonthChange(month: number | 'all') {
        setMonthSelection(month);
        const [ startDate, endDate ] = monthYearToRange(month, yearSelection);
        onChange(startDate, endDate);
    }

    function handleYearChange(year: number) {
        setYearSelection(year);
        const [ startDate, endDate ] = monthYearToRange(monthSelection, year);
        onChange(startDate, endDate);
    }

    return (
        <div className='d-flex gap-2'>
            <FormSelect
                options={monthOptions.options}
                value={monthOptions.monthToOption(monthSelection)}
                onChange={option => option && handleMonthChange(option.value)}
                className='text-black flex-grow-1'
            />
            <FormSelect
                options={YEAR_OPTIONS}
                value={yearToOption(yearSelection)}
                onChange={option => option && handleYearChange(option.value)}
            />
        </div>
    );
}

function monthYearToRange(month: number | 'all', year: number): [DateTime, DateTime] {
    if (month === 'all') {
        const yearObject = DateTime.fromObject({ year });
        const startDate = yearObject.startOf('year');
        const endDate = yearObject.endOf('year');
        return [ startDate, endDate ];
    }
    else {
        // JS Date has months as 0-11
        // Luxon DateTime has months as 1-12
        const luxonMonth = month + 1;
        const monthObject = DateTime.fromObject({ year, month: luxonMonth });
        const startDate = monthObject.startOf('month');
        const endDate = monthObject.endOf('month');
        return [ startDate, endDate ];
    }
}

const statsTileIcons = {
    'orders-total': undefined,
    'orders-unpaid': { color: 'warning', icon: CircleSemiFullIcon },
    'order-count': undefined,
    'events-invoiced': undefined,
} as const;

type StatsTileProps = Readonly<{
    translationId: 'orders-total' | 'orders-unpaid' | 'order-count' | 'events-invoiced';
    children?: ReactNode;
}>;

function StatsTile({ translationId, children }: StatsTileProps) {
    const { t } = useTranslation('components', { keyPrefix: 'statsTile' });

    const iconData = statsTileIcons[translationId];

    return (
        <Col xs={3}>
            <div className='position-relative rounded-2 p-4' style={{ backgroundColor: '#EEE' }}>
                <div className='d-flex gap-1 flex-column align-items-start justify-content-center'>
                    <span className='fs-2 fw-medium lha-4'>{children}</span>
                    <div className='d-flex align-items-center gap-2'>
                        {!!iconData && (
                            <span className={`text-${iconData.color}`} style={{ height: '16px' }}>
                                {iconData.icon({ size: 16 })}
                            </span>
                        )}
                        <span className='fw-medium'>{t(`${translationId}-label`)}</span>
                    </div>
                </div>
                <OverlayTrigger
                    placement='left'
                    overlay={<Tooltip><TileTooltip translationId={translationId} /></Tooltip>}
                >
                    <div className='position-absolute' style={{ top: 10, right: 10, opacity: 0.4 }}>
                        <InfoIcon size={18} />
                    </div>
                </OverlayTrigger>
            </div>
        </Col>
    );
}

type TileTooltipProps = Readonly<{
    translationId: string;
}>;

function TileTooltip({ translationId }: TileTooltipProps) {
    return <Trans
        i18nKey={`components:statsTile.${translationId}-tooltip`}
        components={{
            ul: <ul className='ps-3 mb-0' />,
            li: <li />,
            list: <div className='ps-1 text-start' />,
        }}
    />;
}
