import { Component, createRef } from 'react';
import clsx from 'clsx';
import chunk from 'lodash/chunk';
import { Navigate } from './utils/common';
import getPosition from 'dom-helpers/position';
import * as animationFrame from 'dom-helpers/animationFrame';
/* import Popup from './Popup'
import Overlay from 'react-overlays/Overlay' */
import PopOverlay from './PopOverlay';
import DateContentRow from './DateContentRow';
import Header from './Header';
import { localizer } from '.';
import { Views } from './Views';

const eventsForWeek = (evts, start, end) => {
    const startDay = localizer.startOf(start, 'day');
    const endDay = localizer.startOf(end, 'day');
    return evts.filter(e => localizer.inRangeDay(e, startDay, endDay));
};

class Month extends Component {
    constructor(...args) {
        super(...args);

        this.state = {
            rowLimit: 5,
            needLimitMeasure: true,
            date: null,
        };
        this.containerRef = createRef();
        this.slotRowRef = createRef();

        this._bgRows = [];
        this._pendingSelection = [];
    }

    static getDerivedStateFromProps({ date }, state) {
        return {
            date,
            needLimitMeasure: localizer.neq(date, state.date, 'month'),
        };
    }

    componentDidMount() {
        let running;

        if (this.state.needLimitMeasure)
            this.measureRowLimit(this.props);

        window.addEventListener(
            'resize',
            (this._resizeListener = () => {
                if (!running) {
                    animationFrame.request(() => {
                        running = false;
            this.setState({ needLimitMeasure: true }) //eslint-disable-line
                    });
                }
            }),
            false,
        );
    }

    componentDidUpdate() {
        if (this.state.needLimitMeasure)
            this.measureRowLimit(this.props);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._resizeListener, false);
    }

    getContainer = () => {
        return this.containerRef.current;
    };

    render() {
        let { date, className } = this.props,
            month = localizer.visibleDays(date),
            weeks = chunk(month, 7);

        this._weekCount = weeks.length;

        return (
            <div
                className={clsx('rbc-month-view', className)}
                role='table'
                aria-label='Month View'
                ref={this.containerRef}
            >
                <div className='rbc-row rbc-month-header' role='row'>
                    {this.renderHeaders(weeks[0])}
                </div>
                {weeks.map(this.renderWeek)}
                {this.props.popup && this.renderOverlay()}
            </div>
        );
    }

    renderWeek = (week, weekIdx) => {
        let {
            events,
            selectable,
            getNow,
            selected,
            date,
            showAllEvents,
        } = this.props;

        const { needLimitMeasure, rowLimit } = this.state;

        // let's not mutate props
        const weeksEvents = eventsForWeek(
            [ ...events ],
            week[0],
            week[week.length - 1],
        );

        weeksEvents.sort(localizer.sortEvents);

        return (
            <DateContentRow
                enablePlaceholders
                key={weekIdx}
                ref={weekIdx === 0 ? this.slotRowRef : undefined}
                container={this.getContainer}
                className='rbc-month-row'
                getNow={getNow}
                date={date}
                range={week}
                events={weeksEvents}
                maxRows={showAllEvents ? Infinity : rowLimit}
                selected={selected}
                selectable={selectable}
                renderHeader={this.readerDateHeading}
                renderForMeasure={needLimitMeasure}
                onShowMore={this.handleShowMore}
                onSelect={this.handleSelectEvent}
                onKeyPress={this.handleKeyPressEvent}
                onSelectSlot={this.handleSelectSlot}
                showAllEvents={showAllEvents}
            />
        );
    };

    readerDateHeading = ({ date, className, ...props }) => {
        let { date: currentDate } = this.props;
        let isOffRange = localizer.neq(date, currentDate, 'month');
        let isCurrent = localizer.isSameDate(date, currentDate);
        let label = localizer.fullFormat(date, 'dateFormat');

        return (
            <div
                {...props}
                className={clsx(
                    className,
                    isOffRange && 'rbc-off-range',
                    isCurrent && 'rbc-current',
                )}
                role='cell'
            >
                <span className='rbc-date-header'>{label}</span>
            </div>
        );
    };

    renderHeaders(row) {
        let first = row[0];
        let last = row[row.length - 1];

        return localizer.range(first, last, 'day').map((day, index) => (
            <div key={'header_' + index} className='rbc-header'>
                <Header date={day} type='month' />
            </div>
        ));
    }

    renderOverlay() {
        let overlay = this.state?.overlay ?? {};
        let {
            selected,
            popupOffset,
            handleDragStart,
        } = this.props;

        const onHide = () => this.setState({ overlay: null });

        return (
            <PopOverlay
                overlay={overlay}
                selected={selected}
                popupOffset={popupOffset}
                ref={this.containerRef}
                handleKeyPressEvent={this.handleKeyPressEvent}
                handleSelectEvent={this.handleSelectEvent}
                handleDragStart={handleDragStart}
                show={!!overlay.position}
                overlayDisplay={this.overlayDisplay}
                onHide={onHide}
            />
        );
    }

    measureRowLimit() {
        this.setState({
            needLimitMeasure: false,
            rowLimit: this.slotRowRef.current.getRowLimit(),
        });
    }

    handleSelectSlot = (range, slotInfo) => {
        this.clearSelection();
        this.props.onDrillDown(range[0], Views.WEEK);

        // this._pendingSelection = this._pendingSelection.concat(range);

        // clearTimeout(this._selectTimer);
        // this._selectTimer = setTimeout(() => this.selectDates(slotInfo));
    };

    // handleHeadingClick = (date, view, e) => {
    //     e.preventDefault();
    //     this.clearSelection();
    // };

    handleSelectEvent = (...args) => {
        this.clearSelection();
        this.props.onSelectEvent(...args);
    };

    handleKeyPressEvent = (...args) => {
        this.clearSelection();
        this.props.onKeyPressEvent(...args);
    };

    handleShowMore = (events, date, cell, slot, target) => {
        const {
            popup,
            onShowMore,
        } = this.props;
        //cancel any pending selections so only the event click goes through.
        this.clearSelection();

        if (popup) {
            let position = getPosition(cell, this.containerRef.current);

            this.setState({
                overlay: { date, events, position, target },
            });
        }

        onShowMore?.(events, date, slot);
    };

    overlayDisplay = () => {
        this.setState({
            overlay: null,
        });
    };

    selectDates(slotInfo) {
        let slots = this._pendingSelection.slice();

        this._pendingSelection = [];

        slots.sort((a, b) => +a - +b);
        const start = slots[0];
        const end = slots[slots.length - 1].plus({ day: 1 });

        this.props.onSelectSlot({
            slots,
            start,
            end,
            action: slotInfo.action,
            bounds: slotInfo.bounds,
            box: slotInfo.box,
        });
    }

    clearSelection() {
        clearTimeout(this._selectTimer);
        this._pendingSelection = [];
    }
}

// Month.propTypes = {
//     events: PropTypes.array.isRequired,
//     date: PropTypes.instanceOf(DateTime),

//     min: PropTypes.instanceOf(DateTime),
//     max: PropTypes.instanceOf(DateTime),

//     getNow: PropTypes.func.isRequired,

//     scrollToTime: PropTypes.instanceOf(DateTime),
//     width: PropTypes.number,
//     selected: PropTypes.object,
//     selectable: PropTypes.oneOf([ true, false, 'ignoreEvents' ]),

//     onNavigate: PropTypes.func,
//     onSelectEvent: PropTypes.func,
//     onKeyPressEvent: PropTypes.func,
//     showAllEvents: PropTypes.bool,

//     popup: PropTypes.bool,
//     handleDragStart: PropTypes.func,

//     popupOffset: PropTypes.oneOfType([
//         PropTypes.number,
//         PropTypes.shape({
//             x: PropTypes.number,
//             y: PropTypes.number,
//         }),
//     ]),
// };

function getRange(date) {
    const start = localizer.firstVisibleDay(date);
    const end = localizer.lastVisibleDay(date);
    return localizer.range(start, end);
}

function navigateTo(date, action) {
    switch (action) {
    case Navigate.PREVIOUS:
        return localizer.add(date, -1, 'month');

    case Navigate.NEXT:
        return localizer.add(date, 1, 'month');

    default:
        return date;
    }
}

const viewObject = {
    component: Month,
    navigateTo,
    getRange,
};

export default viewObject;
