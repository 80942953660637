import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Form } from 'react-bootstrap';
import PasswordInput from ':frontend/components/forms/PasswordInput';
import FormErrorMessage from ':frontend/components/forms/FormErrorMessage';
import { useForm } from 'react-hook-form';
import { PASSWORD_MINIMAL_LENGTH } from ':utils/forms';
import { SpinnerButton } from ':frontend/components/common';
import useNotifications from ':frontend/context/NotificationProvider';
import useAuth from ':frontend/context/AuthProvider';
import { Variant, Visibility } from ':frontend/types/notifications';
import { createTranslatedErrorAlert } from ':frontend/components/notifications';

type ChangePasswordFormData = {
    password: string;
    newPassword: string;
};

export default function ChangePassword() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.change-password' });
    const { addAlert } = useNotifications();
    const { auth } = useAuth();
    const [ isFetching, setIsFetching ] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm<ChangePasswordFormData>();

    async function onSubmit(data: ChangePasswordFormData) {
        setIsFetching(true);
        const response = await auth.updatePassword(data.password, data.newPassword);
        setIsFetching(false);
        if (!response.status) {
            // TODO handle error

            addAlert(createTranslatedErrorAlert('pages:settings.change-password.error-alert'));
            return;
        }

        addAlert({ message: t('success-alert'), variant: Variant.Success }, Visibility.Global);
    }

    return (
        <Container className='content-tiny'>
            <h1 className='mt-0'>{t('page-title')}</h1>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className='mt-3'>
                    <Form.Label className='fw-semibold'>{t('current-password-label')}</Form.Label>
                    <PasswordInput
                        register={register('password', { required: t('current-password-required-error') })}
                    />
                    <FormErrorMessage errors={errors} name='password' />
                </Form.Group>
                <Form.Group className='mt-3'>
                    <Form.Label className='fw-semibold'>{t('new-password-label')}</Form.Label>
                    <PasswordInput
                        register={register('newPassword', {
                            required: t('new-password-required-error'),
                            minLength: { value: PASSWORD_MINIMAL_LENGTH, message: t('new-password-too-short', { minimalLength: PASSWORD_MINIMAL_LENGTH }) },
                        })}
                    />
                    <FormErrorMessage errors={errors} name='newPassword' />
                </Form.Group>
                <Row className='mt-5'>
                    <Col>
                        <SpinnerButton
                            type='submit'
                            isFetching={isFetching}
                        >
                            {t('save-button')}
                        </SpinnerButton>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
}
