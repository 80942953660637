import { useCallback, type ReactNode } from 'react';
import clsx from 'clsx';
import { BigCircleCheckIcon, BigCircleEmptyIcon } from '../icons';
import { InfoTooltip, type BaseButtonProps } from './buttons';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PaymentMethod } from ':utils/entity/order';

type RadioOption<TValue> = {
    value: TValue;
    label: ReactNode;
    aria: string;
    isDisabled?: boolean;
};

type RadioProps<TValue> = Readonly<{
    value: TValue;
    options: RadioOption<TValue>[];
    onChange: (value: TValue) => void;
}>;

export type RadioComponent<TValue> = (props: RadioProps<TValue>) => JSX.Element;

type TabRadioProps<TValue> = RadioProps<TValue> & Readonly<{
    disabled?: boolean;
    compact?: boolean;
}>;

export function TabRadio<TValue extends string>({ value, options, onChange, disabled, compact }: TabRadioProps<TValue>) {
    const innerOnClick = useCallback((option: RadioOption<TValue>) => {
        if (option.value === value)
            return;

        onChange(option.value);
    }, [ value, onChange ]);

    return (
        <div className={clsx('sh-tab-buttons fit-content', { compact, disabled })}>
            {options.map(option => (
                <button
                    type='button'
                    key={option.value}
                    className={clsx('sh-tab-button', {
                        selected: option.value === value,
                        disabled: option.isDisabled,
                    })}
                    onClick={() => innerOnClick(option)}
                    aria-label={option.aria}
                >
                    {option.label}
                </button>
            ))}
        </div>
    );
}

export function TileRadio<TValue extends string>({ value, options, onChange }: RadioProps<TValue>) {
    const innerOnClick = useCallback((option: RadioOption<TValue>) => {
        if (option.value === value)
            return;

        onChange(option.value);
    }, [ value, onChange ]);

    return (
        <div className='d-grid gap-2' style={{ gridTemplateColumns: `repeat(${options.length}, 1fr)` }}>
            {options.map(option => (
                <RadioButton
                    type='tile'
                    key={option.value}
                    aria={option.aria}
                    selected={value === option.value}
                    disabled={option.isDisabled}
                    onClick={() => innerOnClick(option)}
                >
                    {option.label}
                </RadioButton>
            ))}
        </div>
    );
}

export function BarRadio<TValue extends string>({ value, options, onChange }: RadioProps<TValue>) {
    const innerOnClick = useCallback((option: RadioOption<TValue>) => {
        if (option.value === value)
            return;

        onChange(option.value);
    }, [ value, onChange ]);

    return (
        <div className='d-flex flex-column'>
            {options.map(option => (
                <RadioButton
                    type='bar'
                    key={option.value}
                    aria={option.aria}
                    selected={value === option.value}
                    disabled={option.isDisabled}
                    onClick={() => innerOnClick(option)}
                >
                    {option.label}
                </RadioButton>
            ))}
        </div>
    );
}

type RadioButtonProps = Readonly<{
    type: 'tile' | 'bar';
    selected: boolean;
    children: React.ReactNode;
}> & BaseButtonProps;

function RadioButton({ type, selected, children, aria, className, disabled, ...rest }: RadioButtonProps) {
    return (
        <button
            type='button'
            aria-label={aria}
            className={clsx(`sh-radio-button-${type}`, selected && 'selected', disabled && 'disabled', className)}
            disabled={disabled}
            {...rest}
        >
            {selected ? (
                <BigCircleCheckIcon size={24} className='text-primary' />
            ) : (
                <BigCircleEmptyIcon size={24} />
            )}
            <span className='fw-medium'>
                {children}
            </span>
        </button>
    );
}

export type PaymentMethodRadioOption = {
    method: PaymentMethod;
    isDisabled?: boolean;
    onEnableClick?: () => void;
};

type PaymentMethodRadioProps = Readonly<{
    value?: PaymentMethod;
    options: PaymentMethodRadioOption[];
    onChange: (value: PaymentMethod) => void;
}>;

export function PaymentMethodRadio({ value, options, onChange }: PaymentMethodRadioProps) {
    const { t } = useTranslation('components', { keyPrefix: 'paymentMethodSelect' });
    const innerOnClick = useCallback((option: PaymentMethodRadioOption) => {
        if (option.method === value)
            return;

        onChange(option.method);
    }, [ value, onChange ]);

    return (
        <div className='d-flex flex-column'>
            {options.map(option => {
                const { method, isDisabled } = option;
                const label = (
                    <span className='fw-medium'>
                        {t(`${method}-title`)}
                        {method === PaymentMethod.noInvoice && <InfoTooltip text={t(`${method}-tooltip`)} className='ms-2' />}
                    </span>
                );

                if (isDisabled) {
                    return (
                        <div key={option.method} className='sh-radio-button-bar disabled'>
                            <BigCircleEmptyIcon size={24} />
                            {label}
                            {option.onEnableClick && (<>
                                <div className='flex-grow-1' />
                                <Button onClick={option.onEnableClick} variant='outline-primary' className='compact'>
                                    {t(`${method}-enable-button`)}
                                </Button>
                            </>)}
                        </div>
                    );
                }

                const selected = value === option.method;
                return (
                    <button
                        key={option.method}
                        type='button'
                        aria-label={t(`${method}-title`)}
                        className={clsx('sh-radio-button-bar', selected && 'selected', isDisabled && 'disabled')}
                        disabled={isDisabled}
                        onClick={() => innerOnClick(option)}
                    >
                        {selected ? (
                            <BigCircleCheckIcon size={24} className='text-primary' />
                        ) : (
                            <BigCircleEmptyIcon size={24} />
                        )}
                        {label}
                    </button>
                );
            })}
        </div>
    );
}
