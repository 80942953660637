import { Button } from 'react-bootstrap';
import { ChevronDownIcon } from '../icons';

type OpenChevronButtonProps = {
    disabled?: boolean;
    className?: string;
    onClick: () => void;
};

export default function OpenChevronButton({ disabled, className, onClick }: OpenChevronButtonProps) {
    return (
        <Button
            disabled={disabled}
            onClick={onClick}
            className={className + ' chevron-button'}
            variant='light'
        >
            <ChevronDownIcon size={18} />
        </Button>
    );
}
