import { useCallback, useMemo } from 'react';
import EmojiPickerReact, { EmojiStyle, type EmojiClickData, Categories } from 'emoji-picker-react';
import { EmojiPlusIcon } from '../icons';
import { type Control, Controller, type FieldPath, type FieldValues } from 'react-hook-form';
import { CloseButton } from './buttons';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Dropdown } from 'react-bootstrap';
import { useToggle } from ':frontend/hooks';

type EmojiPickerProps = Readonly<{
    value?: string;
    onChange: (emoji: string) => void;
    className?: string;
}>;

export default function EmojiPicker({ value, onChange, className }: EmojiPickerProps) {
    const { t } = useTranslation('components', { keyPrefix: 'emojiPicker' });
    const [ isShow, setIsShow ] = useToggle(false);
    const innerOnChange = useCallback((emoji: EmojiClickData) => {
        onChange(emoji.emoji);
        setIsShow.false();
    }, [ onChange ]);

    function unselect() {
        onChange('');
        setIsShow.false();
    }

    const categories: CategoryConfig[] = useMemo(() => allCategories.map(category => ({
        category,
        name: t('categories.' + category),
    })), [ t ]);

    const isEmoji = value && value !== '';

    return (
        <div className={clsx('d-flex align-items-center', className)}>
            <Dropdown autoClose='outside' show={isShow} onToggle={setIsShow.toggle} className='sh-dropdown-remove-indicator'>
                <Dropdown.Toggle
                    className='border rounded-1 bg-transparent min-h-0 d-block p-0 text-black'
                    style={{ width: '36px', height: '31px' }}
                    aria-label={t('emoji-button-aria')}
                    onClick={setIsShow.toggle}
                >
                    {isEmoji
                        ? <span className='fs-3'>{value}</span>
                        : <EmojiPlusIcon size={22} />
                    }
                </Dropdown.Toggle>
                <Dropdown.Menu className='sh-dropdown-menu-plain'>
                    <EmojiPickerReact
                        onEmojiClick={innerOnChange}
                        skinTonesDisabled={true}
                        previewConfig={{ showPreview: false }}
                        emojiStyle={EmojiStyle.NATIVE}
                        searchPlaceholder={t('search-placeholder')}
                        categories={categories}
                    />
                </Dropdown.Menu>
            </Dropdown>
            {isEmoji && (
                <CloseButton onClick={unselect} aria={t('close-button-aria')} />
            )}
        </div>
    );
}

type CategoryConfig = {
    category: Categories;
    name: string;
};

const allCategories: Categories[] = [
    Categories.SUGGESTED,
    Categories.SMILEYS_PEOPLE,
    Categories.ANIMALS_NATURE,
    Categories.FOOD_DRINK,
    Categories.TRAVEL_PLACES,
    Categories.ACTIVITIES,
    Categories.OBJECTS,
    Categories.SYMBOLS,
    Categories.FLAGS,
];

type ControlledEmojiPickerProps<TFieldValues extends FieldValues> = {
    control: Control<TFieldValues>;
    name: FieldPath<TFieldValues>;
    className?: string;
};

export function ControlledEmojiPicker<TFieldValues extends FieldValues>({ control, name, className }: ControlledEmojiPickerProps<TFieldValues>) {
    const InnerSelect = useCallback(({ field }: { field: { value: string | undefined, onChange: (value: string | undefined) => void } }) => {
        return (
            <EmojiPicker
                value={field.value}
                onChange={field.onChange}
                className={className}
            />
        );
    }, [ className ]);

    return (
        <Controller
            control={control as Control<FieldValues>}
            name={name}
            render={InnerSelect}
        />
    );
}
