import { useEffect } from 'react';
import { PricingsEditor, computeDefaultPricings, pricingsFormDataToNewPricings } from ':frontend/components/calendar/PricingsEditor';
import { Form } from 'react-bootstrap';
import { useMaster } from ':frontend/context/UserProvider';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import { useForm } from 'react-hook-form';
import { SpinnerButton } from ':frontend/components/common';
import { useTranslation } from 'react-i18next';
import { InfoCard } from ':frontend/components/settings/InfoCard';
import { TeamSettingsFE } from ':frontend/types/settings';
import { type Id } from ':utils/id';
import { trpc } from ':frontend/context/TrpcProvider';

export type PricingFormItem = {
    currencyId: Id;
    duration: number | '';
    price: number | '';
};

type FormData = {
    pricings: PricingFormItem[];
};

export default function UserPricingsEditor() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.pricings' });
    const updatePricingsMutation = trpc.team.updatePricings.useMutation();
    const { teamSettings, setTeamSettings } = useMaster();
    const { addAlert } = useNotifications();

    const form = useForm<FormData>({
        defaultValues: {
            pricings: computeDefaultPricings(teamSettings.pricings, teamSettings),
        },
    });
    const { handleSubmit } = form;

    function onSubmit(data: FormData) {
        const pricings = pricingsFormDataToNewPricings(data);
        updatePricingsMutation.mutate(pricings, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: response => {
                setTeamSettings(TeamSettingsFE.fromServer(response));
                addAlert(createTranslatedSuccessAlert('components:pricingsEditor.save-success-alert'));
            },
        });
    }

    useEffect(() => {
        form.reset({
            pricings: computeDefaultPricings(teamSettings.pricings, teamSettings),
        });
    }, [ teamSettings, form ]);

    return (
        <div>
            <InfoCard infoKey='pricingsGlobal' className='mb-4' />
            <div className='d-flex justify-content-center'>
                <Form className='w-fit sh-design' noValidate onSubmit={handleSubmit(onSubmit)}>
                    <PricingsEditor form={form} isForUser />
                    <div className='d-flex justify-content-end'>
                        <SpinnerButton type='submit' variant='primary' isFetching={updatePricingsMutation.isPending}>
                            {t('save-button')}
                        </SpinnerButton>
                    </div>
                </Form>
            </div>
        </div>
    );
}
