import { type Dispatch } from 'react';
import { type CheckoutAction, CheckoutPhase, type CheckoutState, type EmailPreviewState, type EmailPreviewAction } from './useCheckout';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Form } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import { TranslatedErrorMessage } from ':frontend/components/forms/ErrorMessage';

type EmailPreviewProps = Readonly<{
    state: CheckoutState;
    dispatch: Dispatch<CheckoutAction>;
}>;

export function EmailPreview({ state, dispatch }: EmailPreviewProps) {
    const { t } = useTranslation('components', { keyPrefix: 'checkout.emailPreview' });
    const isChanged = state.emailPreview.isChanged;

    return (
        <div className='sh-checkout-email-preview sh-design compact-imputs' style={{ padding: '20px' }}>
            <h2 className='m-0'>{t('title')}</h2>
            <EmailPreviewForm state={state.emailPreview} dispatch={dispatch} />
            <div className='d-flex justify-content-end gap-2 mt-3'>
                {isChanged && (
                    <Button variant='outline-secondary' onClick={() => dispatch({ type: 'emailPreview', operation: 'reset' })}>
                        {t('reset-button')}
                    </Button>
                )}
                <Button onClick={() => dispatch({ type: 'phase', phase: CheckoutPhase.Overview })}>
                    {t('back-button')}
                </Button>
            </div>
        </div>
    );
}

type EmailPreviewFormProps = Readonly<{
    state: EmailPreviewState;
    dispatch: Dispatch<EmailPreviewAction>;
}>;

export function EmailPreviewForm({ state, dispatch }: EmailPreviewFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'checkout.emailPreview' });
    const { isMultipleClients, form, formErrors, unusedVariables } = state;

    return (<>
        <Form.Group className='w-50 mt-3'>
            <Form.Label>{t('email-label')}</Form.Label>
            {isMultipleClients ? (
                <Alert variant='info' className='m-0 py-2'>
                    {t('multiple-clients-email')}
                </Alert>
            ) : (<>
                <Form.Control
                    value={form.email}
                    onChange={event => dispatch({ type: 'emailPreview', field: 'email', value: event.target.value })}
                />
                <TranslatedErrorMessage translationId={formErrors?.email} />
            </>)}
        </Form.Group>
        <Form.Group className='w-50 mt-3'>
            <Form.Label>{t('cc-label')}</Form.Label>
            <Form.Control
                value={form.cc}
                onChange={event => dispatch({ type: 'emailPreview', field: 'cc', value: event.target.value })}
                as={TextareaAutosize}
                minRows={1}
            />
            <div className='fs-small mt-1'>{t('cc-description')}</div>
            <TranslatedErrorMessage translationId={formErrors?.cc} />
        </Form.Group>
        <Form.Group className='w-50 mt-3'>
            <Form.Label>{t('subject-label')}</Form.Label>
            <Form.Control
                value={form.subject}
                onChange={event => dispatch({ type: 'emailPreview', field: 'subject', value: event.target.value })}
            />
            <TranslatedErrorMessage translationId={formErrors?.subject} />
        </Form.Group>
        <Form.Group className='mt-3'>
            <Form.Label>{t('body-label')}</Form.Label>
            <Form.Control
                value={form.body}
                onChange={event => dispatch({ type: 'emailPreview', field: 'body', value: event.target.value })}
                as={TextareaAutosize}
                minRows={9}
            />
            <TranslatedErrorMessage translationId={formErrors?.body} />
        </Form.Group>
        {unusedVariables.length > 0 && (
            <Alert variant='warning' className='mt-3 fw-medium'>
                {t('unused-variables-description-1')}
                <ul className='m-0'>
                    {unusedVariables.map(variable => (
                        <li key={variable} className='py-2'>
                            <span className='bg-white rounded-2 me-2' style={{ padding: '2px 6px 4px 6px' }}>
                                {variable}
                            </span>
                            {t(`${variable}-description`)}
                        </li>
                    ))}
                </ul>
                {t('unused-variables-description-2')}
            </Alert>
        )}
    </>);
}
