import { Component, createRef } from 'react';
import clsx from 'clsx';

import { dateCellSelection, getSlotAtX, pointInBox } from './utils/selection';
import Selection, { getBoundsForNode, isEvent } from './Selection';
import { localizer } from '.';

class BackgroundCells extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            selecting: false,
        };
        this.containerRef = createRef();
    }

    componentDidMount() {
        this.props.selectable && this._selectable();
    }

    componentWillUnmount() {
        this._teardownSelectable();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.selectable && this.props.selectable)
            this._selectable();

        if (prevProps.selectable && !this.props.selectable)
            this._teardownSelectable();
    }

    render() {
        let {
            range,
            getNow,
            date: currentDate,
        } = this.props;
        let { selecting, startIdx, endIdx } = this.state;
        let current = getNow();

        return (
            <div className='rbc-row-bg' ref={this.containerRef}>
                {range.map((date, index) => {
                    let selected = selecting && index >= startIdx && index <= endIdx;
                    const isWeekend = date && localizer.isWeekend(date);

                    return (
                        <div
                            key={index}
                            className={clsx(
                                'rbc-day-bg',
                                selected && 'rbc-selected-cell',
                                localizer.isSameDate(date, current) && 'rbc-today',
                                isWeekend && 'weekend',
                                currentDate && localizer.neq(currentDate, date, 'month') && 'rbc-off-range-bg',
                            )}
                        />
                    );
                })}
            </div>
        );
    }

    _selectable() {
        let node = this.containerRef.current;
        let selector = (this._selector = new Selection(this.props.container));

        let selectorClicksHandler = (point, actionType) => {
            if (!isEvent(node, point)) {
                let rowBox = getBoundsForNode(node);
                let { range } = this.props;

                if (pointInBox(rowBox, point)) {
                    let currentCell = getSlotAtX(rowBox, point.x, range.length);

                    this._selectSlot({
                        startIdx: currentCell,
                        endIdx: currentCell,
                        action: actionType,
                        box: point,
                    });
                }
            }

            this._initial = {};
            this.setState({ selecting: false });
        };

        selector.on('selecting', (box) => {
            let { range } = this.props;

            let startIdx = -1;
            let endIdx = -1;

            if (!this.state.selecting) {
                this.props.onSelectStart?.(box);
                this._initial = { x: box.x, y: box.y };
            }
            if (selector.isSelected(node)) {
                let nodeBox = getBoundsForNode(node);
                ({ startIdx, endIdx } = dateCellSelection(
                    this._initial,
                    nodeBox,
                    box,
                    range.length,
                ));
            }

            this.setState({
                selecting: true,
                startIdx,
                endIdx,
            });
        });

        selector.on('beforeSelect', (box) => {
            if (this.props.selectable !== 'ignoreEvents')
                return;

            return !isEvent(this.containerRef.current, box);
        });

        selector.on('click', (point) => selectorClicksHandler(point, 'click'));

        selector.on('doubleClick', (point) =>
            selectorClicksHandler(point, 'doubleClick'),
        );

        selector.on('select', (bounds) => {
            this._selectSlot({ ...this.state, action: 'select', bounds });
            this._initial = {};
            this.setState({ selecting: false });
            this.props.onSelectEnd?.(this.state);
        });
    }

    _teardownSelectable() {
        if (!this._selector)
            return;
        this._selector.teardown();
        this._selector = null;
    }

    _selectSlot({ endIdx, startIdx, action, bounds, box }) {
        if (endIdx === -1 || startIdx === -1)
            return;

        this.props.onSelectSlot?.({
            start: startIdx,
            end: endIdx,
            action,
            bounds,
            box,
        });
    }
}

// BackgroundCells.propTypes = {
//   date: PropTypes.instanceOf(DateTime),
//   getNow: PropTypes.func.isRequired,
//   container: PropTypes.func,
//   selectable: PropTypes.oneOf([true, false, 'ignoreEvents']),
//   range: PropTypes.arrayOf(PropTypes.instanceOf(DateTime)),
//   type: PropTypes.string,
// }

export default BackgroundCells;
