import { useCallback, useMemo, useState } from 'react';
import { ClientInfoFE } from ':frontend/types/Client';
import { ClientTagFE, sortClientTags } from ':frontend/types/ClientTag';
import { AddButton } from '../forms/buttons';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { type Id } from ':utils/id';
import { useMaster } from ':frontend/context/UserProvider';
import { useTranslation } from 'react-i18next';
import { useToggle } from ':frontend/hooks';
import { CheckIcon, CloseIcon, DotsHorizontalIcon, TrashIcon } from '../icons';
import { SpinnerButton } from '../common';
import clsx from 'clsx';
import { trpc } from ':frontend/context/TrpcProvider';

type ClientTagButtonProps = Readonly<{
    tag: { name: string, color: string };
    onClick?: () => void;
}>;

export function ClientTagButton({ tag, onClick }: ClientTagButtonProps) {
    return (
        <button className='sh-client-tag-button px-2 py-1 rounded-2 fw-medium text-nowrap' style={{ '--sh-base-color': `#${tag.color}`, height: '25px' }} onClick={onClick}>
            {tag.name}
        </button>
    );
}

type ClientTagDisplayProps = Readonly<{
    tag: { name: string, color: string };
}>;

export function ClientTagDisplay({ tag }: ClientTagDisplayProps) {
    return (
        <div className='px-2 py-1 rounded-2 fw-medium text-nowrap w-fit' style={{ backgroundColor: `#${tag.color}`, height: '25px' }}>
            {tag.name}
        </div>
    );
}

type ClientTagsProps = Readonly<{
    client: ClientInfoFE;
    setClient: (client: ClientInfoFE) => void;
}>;

export function ClientTags({ client, setClient }: ClientTagsProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientTags' });
    const { clientTags } = useMaster();
    // All tags are ordered the same way, i.e., by their ids.
    const tags = useMemo(() => clientTags.filter(tag => client.tagIds.some(tagId => tag.id === tagId)), [ clientTags, client.tagIds ]);

    const [ show, setShow ] = useToggle(false);
    const [ editedTagId, setEditedTagId ] = useState<Id>();

    const onEditClose = useCallback(() => setEditedTagId(undefined), []);

    return (
        <div className='d-flex align-items-center gap-1 flex-wrap'>
            <div>
                <Dropdown autoClose='outside' show={show} onToggle={setShow.toggle}>
                    <AddButton aria={t('add-button-aria')} onClick={setShow.true} />
                    <Dropdown.Menu className='p-0' style={{ minWidth: '240px' }}>
                        {show && (
                            <AddTagMenu client={client} setClient={setClient} tags={tags} onClose={setShow.false} />
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            {tags.map(tag => (
                <Dropdown key={tag.id} show={editedTagId === tag.id} onToggle={onEditClose}>
                    <ClientTagButton key={tag.id} tag={tag} onClick={() => setEditedTagId(tag.id)} />
                    <Dropdown.Menu className='p-0'>
                        {editedTagId === tag.id && (
                            <EditTagMenu client={client} setClient={setClient} tag={tag} onClose={onEditClose} />
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            ))}
        </div>
    );
}

type AddTagMenuProps = Readonly<{
    client: ClientInfoFE;
    setClient: (client: ClientInfoFE) => void;
    tags: ClientTagFE[];
    onClose: () => void;
}>;

function AddTagMenu({ client, setClient, tags, onClose }: AddTagMenuProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientTags' });
    const { clientTags, setClientTags } = useMaster();
    const [ query, setQuery ] = useState('');
    const [ fetching, setFetching ] = useState<string>();
    const createTagMutation = trpc.$client.createClientTag.useMutation();
    const updateTagsMutation = trpc.$client.updateClientsTags.useMutation();

    const availableTags = useMemo(() => clientTags.filter(tag => !tags.some(clientTag => clientTag.id === tag.id)), [ clientTags, tags ]);
    const filteredTags = useMemo(() =>
        query.trim().length === 0
            ? availableTags
            : availableTags.filter(tag => tag.name.toLowerCase().includes(query.toLowerCase())),
    [ availableTags, query ]);
    const trimmedTags = useMemo(() => filteredTags.slice(0, MAX_DISPLAYED_TAGS), [ filteredTags ]);

    function createTag(input: string) {
        setFetching(FID_CREATE);
        createTagMutation.mutate({ name: input.trim(), color: newTagColor, clientId: client.id }, {
            onSuccess: response => {
                const newTag = ClientTagFE.fromServer(response);
                setClientTags(oldTags => sortClientTags([ ...oldTags, newTag ]));

                client.tagIds.push(newTag.id);
                setClient(client);

                onClose?.();
            },
            onSettled: () => {
                setFetching(undefined);
            },
        });
    }

    const newTagColor = useMemo(() => findNextColor(clientTags), [ clientTags ]);
    const isNewEnabled = query.trim().length > 0 && !filteredTags.some(tag => tag.name === query);

    function addTag(tag: ClientTagFE) {
        setFetching(tag.id);
        updateTagsMutation.mutate({ clientId: client.id, tagId: tag.id, type: 'add' }, {
            onSuccess: response => {
                const updatedClient = ClientInfoFE.fromServer(response);
                setClient(updatedClient);
                onClose?.();
            },
            onSettled: () => {
                setFetching(undefined);
            },
        });
    }

    return (
        <div>
            <div className='px-3 py-2'>
                <Form.Label>{t('query-label')}</Form.Label>
                <Form.Control
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                    placeholder={t('query-placeholder')}
                    autoFocus
                />
            </div>
            <div className={clsx(!isNewEnabled && 'rounded-bottom-2 overflow-hidden')}>
                {trimmedTags.map(tag => (
                    <SpinnerButton
                        key={tag.id}
                        className='sh-dropdown-button'
                        onClick={() => addTag(tag)}
                        fetching={fetching}
                        fid={tag.id}
                        icon={<div className='position-absolute ps-3' style={{ left: '0px' }}>
                            <ClientTagDisplay tag={tag} />
                        </div>}
                    >
                        <div style={{ height: '25px' }} />
                    </SpinnerButton>
                ))}
                {filteredTags.length > MAX_DISPLAYED_TAGS && (
                    <div className='py-2 px-3 text-secondary'>
                        <DotsHorizontalIcon size={22} />
                    </div>
                )}
            </div>
            {isNewEnabled && (<>
                <div className='sh-divider-light' />
                <div className='px-3 py-2 d-flex align-items-center gap-3'>
                    <SpinnerButton
                        className='compact'
                        onClick={() => createTag(query)}
                        fetching={fetching}
                        fid={FID_CREATE}
                    >
                        {t('create-button')}
                    </SpinnerButton>
                    <ClientTagDisplay tag={{ name: query, color: newTagColor }} />
                </div>
            </>)}
        </div>
    );
}

const MAX_DISPLAYED_TAGS = 5;
const FID_CREATE = 'create';

type EditTagMenuProps = Readonly<{
    client: ClientInfoFE;
    setClient: (client: ClientInfoFE) => void;
    tag: ClientTagFE;
    onClose: () => void;
}>;

function EditTagMenu({ client, setClient, tag, onClose }: EditTagMenuProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientTags' });
    const { t: tc } = useTranslation('common', { keyPrefix: 'colors' });
    const { setClientTags } = useMaster();

    const [ formName, setFormName ] = useState(tag.name);
    const [ formHex, setFormHex ] = useState(tag.color);

    const [ fetching, setFetching ] = useState<string>();

    const updateTagMutation = trpc.$client.updateClientTag.useMutation();
    const deleteTagMutation = trpc.$client.deleteClientTag.useMutation();
    const updateTagsMutation = trpc.$client.updateClientsTags.useMutation();

    function removeTag() {
        setFetching(FID_REMOVE);
        updateTagsMutation.mutate({ clientId: client.id, tagId: tag.id, type: 'remove' }, {
            // TODO handle error
            onSuccess: response => {
                const newClient = ClientInfoFE.fromServer(response);
                setClient(newClient);
                onClose?.();
            },
            onSettled: () => {
                setFetching(undefined);
            },
        });
    }

    function updateTag() {
        setFetching(FID_UPDATE);
        updateTagMutation.mutate({ id: tag.id, name: formName.trim(), color: formHex }, {
            // TODO handle error
            onSuccess: response => {
                const updatedTag = ClientTagFE.fromServer(response);
                setClientTags(oldTags => sortClientTags(oldTags.map(oldTag => oldTag.id === tag.id ? updatedTag : oldTag)));
                onClose?.();
            },
            onSettled: () => {
                setFetching(undefined);
            },
        });
    }

    function deleteTag() {
        setFetching(FID_DELETE);
        deleteTagMutation.mutate({ id: tag.id }, {
            // TODO handle error
            onSuccess: () => {
                setClientTags(oldTags => oldTags.filter(oldTag => oldTag.id !== tag.id));
                onClose?.();
            },
            onSettled: () => {
                setFetching(undefined);
            },
        });
    }

    const isChange = getChange(tag, formName, formHex);

    return (
        <div>
            <div className='px-3 py-2'>
                <SpinnerButton
                    variant='outline-secondary'
                    className='compact'
                    onClick={removeTag}
                    fetching={fetching}
                    fid={FID_REMOVE}
                >
                    <CloseIcon size={18} className='me-2' />{t('remove-button')}
                </SpinnerButton>
            </div>
            <div className='sh-divider-light' />
            <div className='px-3 py-2'>
                <Form.Label>{t('name-label')}</Form.Label>
                <Form.Control
                    value={formName}
                    onChange={e => setFormName(e.target.value)}
                    placeholder={t('name-placeholder')}
                    autoFocus
                />
            </div>
            <div className='sh-divider-light' />
            <div className='d-flex flex-column'>
                <Form.Label className='px-3 pt-2 pb-1 m-0'>{t('color-label')}</Form.Label>
                {Object.entries(PREDEFINED_COLORS).map(([ color, hex ]) => (
                    <Button key={color} className='sh-dropdown-button gap-2' onClick={() => setFormHex(hex)}>
                        <div style={{ width: '16px', height: '16px', backgroundColor: `#${hex}` }} className='rounded-1' />
                        <span className='text-nowrap'>{tc(color)}</span>
                        <div className='flex-grow-1' />
                        {formHex === hex && (
                            <CheckIcon />
                        )}
                    </Button>
                ))}
            </div>
            <div className='sh-divider-light' />
            <div className='px-3 py-2 d-flex flex-column gap-2'>
                {isChange && (
                    <SpinnerButton
                        variant='primary'
                        className='compact'
                        onClick={updateTag}
                        fetching={fetching}
                        fid={FID_UPDATE}
                    >
                        {t('update-button')}
                    </SpinnerButton>
                )}
                <SpinnerButton
                    variant='outline-danger'
                    className='compact'
                    onClick={deleteTag}
                    fetching={fetching}
                    fid={FID_DELETE}
                >
                    <TrashIcon size={18} className='me-2' />{t('delete-button')}
                </SpinnerButton>
            </div>
        </div>
    );
}

const FID_REMOVE = 'remove';
const FID_DELETE = 'delete';
const FID_UPDATE = 'update';

function getChange(tag: ClientTagFE, name?: string, hex?: string): boolean {
    if (!name || !hex)
        return false;

    return tag.name !== name || tag.color !== hex;
}

const PREDEFINED_COLORS = {
    'purple': 'd5bce8',
    'blue': 'b6f1fe',
    'cyan': '88f8f8',
    'green': 'a9f9cd',
    'yellow': 'f9face',
    'orange': 'fac19d',
    'red': 'f88888',
    'pink': 'f7bfee',
} as const;

function findNextColor(tags: ClientTagFE[]): string {
    const usedColors = tags.map(tag => tag.color);
    const allColors = Object.values(PREDEFINED_COLORS);
    const availableColors = allColors.filter(color => !usedColors.includes(color));
    return availableColors[0] ?? allColors[0];
}
