export abstract class TypedError extends Error {
    protected constructor(
        readonly type: string,
        readonly httpStatus: number = 400,
    ) {
        super(type);
    }
}

export enum TypedErrorType {
    TransitionError = 'transitionError',
    AccessDeniedError = 'accessDeniedError',
    EntityNotFoundError = 'entityNotFoundError',
}
