import { zId } from ':utils/id';
import { z } from 'zod';
import { zCurrencyId } from './money';
import { zCountryCode, zLocaleCode, zTimezoneCode } from ':utils/i18n';

export type PricingUpsert = z.infer<typeof zPricingUpsert>;
export const zPricingUpsert = z.object({
    currencyId: zCurrencyId,
    duration: z.number(),
    price: z.number(),
});

export type PricingOutput = z.infer<typeof zPricingOutput>;
export const zPricingOutput = zPricingUpsert.extend({
    id: zId,
});

export type TeamSettingsOutput = z.infer<typeof zTeamSettingsOutput>;
export const zTeamSettingsOutput = z.object({
    id: zId,
    currencyId: zCurrencyId,
    pricings: z.array(zPricingOutput),
});

export type AppUserSettingsOutput = z.infer<typeof zAppUserSettingsOutput>;
export const zAppUserSettingsOutput = z.object({
    id: zId,
    timezone: zTimezoneCode,
    locale: zLocaleCode,
    country: zCountryCode,
    hideEventPrices: z.boolean(),
});

/** Like PUT. */
export type AppUserSettingsEdit = z.infer<typeof zAppUserSettingsEdit>;
export const zAppUserSettingsEdit = z.object({
    timezone: zTimezoneCode,
    locale: zLocaleCode,
    country: zCountryCode,
    hideEventPrices: z.boolean(),
});
