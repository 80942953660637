import { useCallback, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { createTranslatedSuccessAlert } from ':frontend/components/notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import { createErrorAlert } from '../notifications/TranslatedAlertMessage';
import { ProductFE } from ':frontend/types/Product';
import ProductForm, { FID_SAVE } from './ProductForm';
import { useTranslation } from 'react-i18next';
import { SpinnerButton } from '../common';
import { TrashIcon } from '../icons';
import { useUser } from ':frontend/context/UserProvider';
import { sleep } from ':frontend/utils/common';
import { trpc } from ':frontend/context/TrpcProvider';
import type { ProductEdit, ProductInit } from ':utils/entity/product';

type ProductModalProps = Readonly<{
    state: ProductModalState;
    onClose: () => void;
    onProductUpdate: (product: ProductFE, isDeleted?: boolean) => void;
}>;

export default function ProductModal({ state, onClose, onProductUpdate }: ProductModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productModal' });
    const { addAlert } = useNotifications();
    const [ fetching, setFetching ] = useState<string>();
    const { onboarding } = useUser();

    const createMutation = trpc.product.createProduct.useMutation();
    const updateMutation = trpc.product.updateProduct.useMutation();
    const deleteMutation = trpc.product.deleteProduct.useMutation();

    const utils = trpc.useUtils();

    const trySyncOnboarding = useCallback(async () => {
        if (onboarding.flags.productCreatedOrUpdated)
            return;

        // TODO This is just so wrong on so many levels. But we have to fix this on backend first.
        await sleep(2000);
        utils.user.getOnboarding.invalidate();
    }, [ onboarding.flags.productCreatedOrUpdated, utils ]);

    const createProduct = useCallback((init: ProductInit) => {
        setFetching(FID_SAVE);
        createMutation.mutate(init, {
            onError: (error) => {
                addAlert(createErrorAlert(error.data));
            },
            onSuccess: response => {
                const newProduct = ProductFE.fromServer(response);
                addAlert(createTranslatedSuccessAlert('components:productModal.product-created-alert'));
                onProductUpdate(newProduct);
                trySyncOnboarding();
            },
            onSettled: () => {
                setFetching(undefined);
                onClose();
            },
        });
    }, [ onClose, onProductUpdate, addAlert, trySyncOnboarding ]);

    const updateProduct = useCallback((edit: ProductEdit) => {
        setFetching(FID_SAVE);
        updateMutation.mutate(edit, {
            onError: (error) => {
                addAlert(createErrorAlert(error.data));
            },
            onSuccess: response => {
                const newProduct = ProductFE.fromServer(response);
                addAlert(createTranslatedSuccessAlert('components:productModal.product-updated-alert'));
                onProductUpdate(newProduct);
                trySyncOnboarding();
            },
            onSettled: () => {
                setFetching(undefined);
                onClose();
            },
        });
    }, [ onClose, onProductUpdate, addAlert, trySyncOnboarding ]);

    function deleteProduct() {
        const product = state.product;
        if (!product)
            return;

        setFetching(FID_DELETE);
        deleteMutation.mutate({ id: product.id }, {
            onError: (error) => {
                addAlert(createErrorAlert(error.data));
            },
            onSuccess: () => {
                addAlert(createTranslatedSuccessAlert('components:productModal.product-deleted-alert'));
                onProductUpdate(product, true);
            },
            onSettled: () => {
                setFetching(undefined);
                onClose();
            },
        });
    }

    return (
        <Modal show={state.isShow} onHide={onClose} contentClassName='rounded-4'>
            <Modal.Body style={{ padding: '24px' }}>
                <div className='d-flex align-items-top mb-4'>
                    <h2 className='my-0'>{t(state.product ? 'edit-product-title' : 'new-product-title')}</h2>
                    {state.product && (<>
                        <div className='flex-grow-1' />
                        <SpinnerButton
                            fetching={fetching}
                            fid={FID_DELETE}
                            variant='outline-danger'
                            className='compact'
                            onClick={deleteProduct}
                        >
                            <TrashIcon size={18} className='me-2' />{t('delete-button')}
                        </SpinnerButton>
                    </>)}
                </div>
                <ProductForm
                    onInitSubmit={createProduct}
                    onEditSubmit={updateProduct}
                    onClose={onClose}
                    fetching={fetching}
                    defaultValue={state.product}
                />
            </Modal.Body>
        </Modal>
    );
}

const FID_DELETE = 'delete';

type ProductModalState = {
    isShow: boolean;
    product?: ProductFE;
};

export type ProductModalControl = {
    startCreating: () => void;
    startEditing: (product: ProductFE) => void;
    close: () => void;
};

export function useProductModal(): { state: ProductModalState, control: ProductModalControl } {
    const [ state, setState ] = useState<ProductModalState>({ isShow: false });
    const control = useMemo(() => ({
        startCreating: () => setState({ isShow: true }),
        startEditing: (product: ProductFE) => setState({ isShow: true, product }),
        close: () => setState(state => ({ ...state, isShow: false })),
    }), []);

    return {
        state,
        control,
    };
}
