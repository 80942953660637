import { Nav } from 'react-bootstrap';
import { MdOutlineSpaceDashboard, MdOutlineViewHeadline } from 'react-icons/md';
import { matchPath, Link } from 'react-router-dom';
import { IoMdCalendar } from 'react-icons/io';
import { type IconType } from 'react-icons/lib';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { routesFE } from ':utils/routes';
import { PaymentsIcon, SettingsIcon, UsersIcon } from '../icons';
import { toMaster, useUser } from ':frontend/context/UserProvider';

type MenuItemBase = Readonly<{
    nameTranslationId: string;
    to: string;
    match?: string | string[];
    icon?: IconType;
    masterOnly?: boolean;
}>;

export const userMenuItems: MenuItemBase[] = [
    {
        nameTranslationId: 'user-settings',
        icon: SettingsIcon,
        to: routesFE.settings.resolve({ key: 'general' }),
    },
    {
        nameTranslationId: 'payments',
        icon: PaymentsIcon,
        to: routesFE.payments.path,
    },
    {
        nameTranslationId: 'subscription',
        to: routesFE.subscription.path,
        masterOnly: true,
    },
    {
        nameTranslationId: 'integrations',
        to: routesFE.integrations.path,
    },
];

export const overviewMenuItems: MenuItemBase[] = [
    {
        nameTranslationId: 'dashboard',
        icon: MdOutlineSpaceDashboard,
        to: routesFE.dashboard,
    },
    {
        nameTranslationId: 'calendar',
        icon: IoMdCalendar,
        to: routesFE.calendar,
    },
    {
        nameTranslationId: 'clients',
        icon: UsersIcon,
        to: routesFE.clients.list,
        match: [ routesFE.clients.detail.path, routesFE.clients.new, routesFE.clients.import, routesFE.orders.newBackpay ],
    },
    {
        nameTranslationId: 'orders',
        icon: MdOutlineViewHeadline,
        to: routesFE.orders.list,
        match: routesFE.orders.detail.path,
    },
    {
        nameTranslationId: 'team',
        to: routesFE.team,
        masterOnly: true,
    },
];

function getMatchPaths(item: MenuItemBase): string[] {
    if (!item.match)
        return [ item.to ];
    if (typeof item.match === 'string')
        return [ item.to, item.match ];

    return [ item.to, ...item.match ];
}

export function isActive(item: MenuItemBase, pathName: string): boolean {
    for (const path of getMatchPaths(item)) {
        if (matchPath(path, pathName))
            return true;
    }

    return false;
}

export function SubMenuItem(props: MenuItemBase) {
    const { t } = useTranslation('pages');
    const isMasterOrFreelancer = !!toMaster(useUser());

    if (props.masterOnly && !isMasterOrFreelancer)
        return null;

    const Icon = props.icon;

    return (
        <Nav.Item>
            <Link className={clsx('sh-navigation-button d-flex align-items-center gap-1 py-2 px-3', isActive(props, location.pathname) ? 'link-primary' : 'text-reset')} to={props.to}>
                {Icon && (
                    <Icon size={18} />
                )}
                <span className='lha-3 fw-medium'>{t(`mainMenu.${props.nameTranslationId}`)}</span>
            </Link>
        </Nav.Item>
    );
}
