import { createDefaultFilterRowDropdown, DefaultFilterItemBadge, type Filter, type FilterDefinition, type FilterFunction, type FilterItemBadgeProps, type FilterMenuProps } from './FilterRow';
import { Button, Form } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { compareArrays, emptyFunction } from ':frontend/utils/common';
import { type OrderInfoFE, ORDER_STATE_VALUES } from ':frontend/types/orders/Order';
import OrderStateBadge from ':frontend/components/orders/OrderStateBadge';
import { OrderState } from ':utils/entity/order';
import { enumFilterToArray } from ':utils/common';

const filterName = 'orderState';

type FilterState = {
    [key in OrderState]: boolean;
};

function FilterToggleMenu({ state, setState }: FilterMenuProps<FilterState>) {
    return (<>
        {ORDER_STATE_VALUES.map(orderState => (
            <Button key={orderState} className='sh-dropdown-button p-3 pe-5 align-items-center justify-content-start' onClick={() => setState({ ...state, [orderState]: !state[orderState] })}>
                {/**
                  * This is needed in order to make the checkbox clickable. Without this, when clicking on the checkbox, the state is changing, but the checkbox still looks the same. Only after changing the state on other order state, the checkbox will change.
                  * For some reason, nothing else really works. It is possible to use something like key={'' + state[orderState]} to force the component to re-render, but this seems unnecessary.
                  * It is probably caused by the whole Dropdown thing that somehow manipulates the orders.
                  */}
                <Form.Check type='checkbox' checked={state[orderState]} className='me-3'
                    onClick={e => {
                        setState({ ...state, [orderState]: !state[orderState] });
                        e.stopPropagation();
                    }}
                    onChange={emptyFunction}
                />
                <OrderStateBadge state={orderState} />
            </Button>
        ))}
    </>);
}

function FilterItemBadge({ item, onClose }: FilterItemBadgeProps<OrderState>) {
    const { t } = useTranslation('common', { keyPrefix: `filters.${filterName}` });

    return (
        <DefaultFilterItemBadge item={item} onClose={onClose}>
            <Trans t={t} i18nKey={`${item}-badge-label`} components={{ sm: <span className='fw-medium' /> }} />
        </DefaultFilterItemBadge>
    );
}

function remove(state: FilterState, item: OrderState): FilterState {
    return {
        ...state,
        [item]: false,
    };
}

function toItems(state: FilterState): OrderState[] {
    return Object.entries(state)
        .filter(([ , value ]) => value)
        .map(([ key ]) => key as OrderState);
}

function createFilterFunction(state: FilterState): FilterFunction<OrderInfoFE> {
    if (toItems(state).length === 0)
        return () => true;

    return (data: OrderInfoFE) => !!state[data.state];
}

function toServer(state: FilterState, previous: string[] | undefined): string[] | undefined {
    const current = enumFilterToArray(state).sort();
    if (current.length === 0)
        return;

    return previous && compareArrays(current, previous)
        ? previous
        : current;
}

const OrderStateFilter: FilterDefinition<OrderState, FilterState, OrderInfoFE> = {
    name: filterName,
    defaultValues: {
        [OrderState.fulfilled]: false,
        [OrderState.new]: false,
        [OrderState.overdue]: false,
        [OrderState.canceled]: false,
    },
    FilterToggleMenu,
    FilterRowMenu: createDefaultFilterRowDropdown(filterName, FilterToggleMenu),
    FilterItemBadge,
    remove,
    toItems,
    createFilterFunction,
    toServer: toServer as (state: FilterState, previous: unknown | undefined) => string[],
};

export default OrderStateFilter as Filter;
