import { SortOrder } from ':utils/common';
import { GenIcon, type IconBaseProps, type IconTree } from 'react-icons';

function element(tag: string, attr: Record<string, string>, ...child: IconTree[]): IconTree {
    return {
        tag,
        attr,
        child,
    };
}

type IconOptions = {
    w: number;
    h: number;
    strokeWidth?: number;
    fill?: 'currentColor';
    stroke?: 'none';
}

function generateIcon(options: number | IconOptions, ...paths: string[]) {
    const data = typeof options === 'number' ? { w: options, h: options } : options;
    const svg: Record<string, string> = {
        viewBox: `0 0 ${data.w} ${data.h}`,
        strokeWidth: `${data.strokeWidth ?? 1.5}`,
        fill: data.fill ?? 'none',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
    };
    if (data.stroke)
        svg.stroke = data.stroke;

    return GenIcon(element('svg', svg, ...paths.map(d => element('path', { d }))));
}

// Resize all icons to 22x22 and minify them by
// https://www.svgviewer.dev/

const TODO_BASE_SIZE_FIXME = 22;

export const CalendarClockIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M19.25 6.875V5.5a1.8334 1.8334 0 0 0-1.8333-1.8333H4.5833A1.8333 1.8333 0 0 0 2.75 5.5v12.8333a1.8333 1.8333 0 0 0 1.8333 1.8334h3.2084M14.6665 1.8333V5.5M7.3335 1.8333V5.5M2.75 9.1667h4.5833M16.0415 16.0417l-1.375-1.1459v-2.0625',
    'M20.1665 14.6667a5.5 5.5 0 1 1-11.0001-.0002 5.5 5.5 0 0 1 11.0001.0002Z',
);

export const CalendarCheckIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M17.4167 3.6667H4.5833C3.5708 3.6667 2.75 4.4875 2.75 5.5v12.8334c0 1.0125.8208 1.8333 1.8333 1.8333h12.8334c1.0125 0 1.8333-.8208 1.8333-1.8333V5.5c0-1.0125-.8208-1.8333-1.8333-1.8333ZM14.6665 1.8333V5.5M7.3335 1.8333V5.5M2.75 9.1667h16.5',
    'M8.25 14.6666 10.0833 16.5l3.6667-3.6667',
);

export const CalendarPlusIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M19.25 11.9167V5.5a1.8334 1.8334 0 0 0-1.8333-1.8333H4.5833A1.8333 1.8333 0 0 0 2.75 5.5v12.8334a1.8332 1.8332 0 0 0 1.8333 1.8333h7.3334M14.6665 1.8333V5.5M7.3335 1.8333V5.5M2.75 9.1667h16.5M17.4165 14.6667v5.5M14.6665 17.4167h5.5',
);

export const ClockIcon = generateIcon(16,
    'M8 14.667A6.667 6.667 0 1 0 8 1.333a6.667 6.667 0 0 0 0 13.334',
    'M8 4v4l2.667 1.333',
);

export const CircleSlashIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'm8.25 13.75 5.5-5.5M11.0002 20.1667c5.0626 0 9.1666-4.1041 9.1666-9.1667 0-5.0626-4.104-9.1667-9.1666-9.1667-5.0626 0-9.1667 4.1041-9.1667 9.1667 0 5.0626 4.104 9.1667 9.1667 9.1667Z',
);

export const CircleUserIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M 16.5 18.417 a 5.5 5.5 0 0 0 -1.611 -3.889 a 5.5 5.5 0 0 0 -7.779 0 A 5.5 5.5 0 0 0 5.5 18.417',
    'M 11 12.917 a 3.667 3.667 0 1 0 0 -7.333 a 3.667 3.667 0 0 0 0 7.333',
    'M 11.0002 20.1667 c 5.0626 0 9.1666 -4.1041 9.1666 -9.1667 c 0 -5.0626 -4.104 -9.1667 -9.1666 -9.1667 c -5.0626 0 -9.1667 4.1041 -9.1667 9.1667 c 0 5.0626 4.104 9.1667 9.1667 9.1667 Z',
);

export const DownloadIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M19.25 13.75v3.6667a1.8333 1.8333 0 0 1-1.8333 1.8333H4.5833A1.8334 1.8334 0 0 1 2.75 17.4167V13.75M6.4165 9.1667l4.5833 4.5833 4.5834-4.5833M11 13.75v-11',
);

export const ExpandIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M13.75 2.75h5.5v5.5M8.25 19.25h-5.5v-5.5M19.2502 2.75l-6.4167 6.4167M2.75 19.25l6.4167-6.4167',
);

export const EyeIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M1.8335 11s2.75-6.4167 9.1667-6.4167c6.4166 0 9.1666 6.4167 9.1666 6.4167s-2.75 6.4167-9.1666 6.4167C4.5835 17.4167 1.8335 11 1.8335 11Z',
    'M11 13.75c1.5188 0 2.75-1.2312 2.75-2.75S12.5188 8.25 11 8.25 8.25 9.4812 8.25 11s1.2312 2.75 2.75 2.75Z',
);

export const CrossedEyeIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M9.057 9.057a2.75 2.75 0 1 0 3.887 3.887M9.836 4.657A9.449 9.449 0 0 1 11 4.584c6.417 0 9.167 6.417 9.167 6.417a12.066 12.066 0 0 1-1.531 2.457',
    'M6.059 6.059A12.399 12.399 0 0 0 1.833 11s2.75 6.417 9.167 6.417a8.928 8.928 0 0 0 4.941-1.476M1.833 1.833l18.333 18.333',
);

export const CheckIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M18.3332 5.5 8.2498 15.5833 3.6665 11',
);

export const SmallCheckIcon = generateIcon(9,
    'M7.291 2 2.94 6.352.96 4.374',
);

export const ChevronLeftIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M13.75 16.5 8.25 11l5.5-5.5',
);

export const ChevronRightIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'm8.25 16.5 5.5-5.5-5.5-5.5',
);

export const ChevronDownIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'm16.5 8.25-5.5 5.5-5.5-5.5',
);

export const ChevronUpIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'm5.5 13.75 5.5-5.5 5.5 5.5',
);

export const InvoiceIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M13.2915 1.8333H5.4998a1.8334 1.8334 0 0 0-1.8333 1.8334v14.6666a1.8333 1.8333 0 0 0 1.8333 1.8334h11a1.8334 1.8334 0 0 0 1.8334-1.8334V6.875l-5.0417-5.0417Z',
    'M12.8335 1.8333v5.5h5.5',
);

export const PlusIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M4.5835 11h12.8333M11 4.5833v12.8333',
);

export const ReceiptIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M3.6665 1.8333v18.3333l1.8333-.9166 1.8334.9166 1.8333-.9166 1.8333.9166 1.8334-.9166 1.8333.9166 1.8333-.9166 1.8334.9166V1.8333l-1.8334.9167-1.8333-.9167-1.8333.9167-1.8334-.9167-1.8333.9167-1.8333-.9167-1.8334.9167-1.8333-.9167Z',
    'M14.6668 7.3333h-5.5a1.8333 1.8333 0 0 0 0 3.6667h3.6667a1.8333 1.8333 0 1 1 0 3.6666h-5.5M11 15.5834V6.4167',
);

export const SendIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'm20.1668 1.8333-6.4166 18.3334-3.6667-8.25-8.25-3.6667 18.3333-6.4167ZM20.1668 1.8333 10.0835 11.9167',
);

export const TrashIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M2.75 5.5h16.5M17.4168 5.5v12.8333c0 .9167-.9166 1.8334-1.8333 1.8334H6.4168c-.9166 0-1.8333-.9167-1.8333-1.8334V5.5M7.3335 5.5V3.6667c0-.9167.9167-1.8334 1.8333-1.8334h3.6667c.9167 0 1.8333.9167 1.8333 1.8334V5.5',
);

export const UserPlusIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M12.8335 17.4167a5.5001 5.5001 0 0 0-11 0',
    'M7.3332 11.9167c2.025 0 3.6666-1.6416 3.6666-3.6667 0-2.025-1.6416-3.6667-3.6666-3.6667S3.6665 6.225 3.6665 8.25c0 2.0251 1.6416 3.6667 3.6667 3.6667ZM17.4165 7.3333v5.5M20.1665 10.0833h-5.5',
);

export const UsersIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M12.833 17.417a5.5 5.5 0 0 0 -1.611 -3.889 5.5 5.5 0 0 0 -7.779 0A5.5 5.5 0 0 0 1.833 17.417',
    'M7.333 11.917a3.667 3.667 0 1 0 0 -7.333 3.667 3.667 0 0 0 0 7.333Zm12.833 5.5a5.5 5.5 0 0 0 -1.611 -3.889A5.5 5.5 0 0 0 14.667 11.917a3.667 3.667 0 0 0 2.592 -1.074 3.667 3.667 0 0 0 0 -5.185A3.667 3.667 0 0 0 14.667 4.583',
);

export const MapPinIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M18.333 9.167c0 5.5 -7.333 11 -7.333 11s-7.333 -5.5 -7.333 -11a7.333 7.333 0 1 1 14.667 0Z',
    'M11 11.917a2.75 2.75 0 1 0 0 -5.5 2.75 2.75 0 0 0 0 5.5Z',
);

export const CalendarIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M17.417 3.667H4.583a1.833 1.833 0 0 0 -1.833 1.833v12.833a1.833 1.833 0 0 0 1.833 1.833h12.833a1.833 1.833 0 0 0 1.833 -1.833V5.5a1.833 1.833 0 0 0 -1.833 -1.833Zm-2.75 -1.833v3.667M7.333 1.833v3.667m-4.583 3.667h16.5',
);

export const WalletIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M19.25 11V6.417H4.583a1.833 1.833 0 1 1 0 -3.667h12.833v3.667',
    'M2.75 4.583v12.833a1.833 1.833 0 0 0 1.833 1.833h14.667v-4.583',
    'M16.5 11a1.833 1.833 0 0 0 0 3.667h3.667v-3.667h-3.667Z',
);

export const CloseIcon = generateIcon(18,
    'm13.5 4.5-9 9m0-9 9 9',
);

export const DotsVerticalIcon = generateIcon(18,
    'M9 9.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM9 4.5A.75.75 0 1 0 9 3a.75.75 0 0 0 0 1.5ZM9 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z',
);

export const DotsHorizontalIcon = generateIcon(18,
    'M9.75 9a.75.75 0 1 0-1.5 0 .75.75 0 0 0 1.5 0M4.5 9A.75.75 0 1 0 3 9a.75.75 0 0 0 1.5 0M15 9a.75.75 0 1 0-1.5 0A.75.75 0 0 0 15 9',
);

export const EditNotesIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M9.524 4.074H3.463a1.732 1.732 0 0 0-1.731 1.732v12.121a1.732 1.732 0 0 0 1.731 1.731h12.121a1.732 1.732 0 0 0 1.732-1.731v-6.062',
    'M16.016 2.774a1.837 1.837 0 0 1 2.597 2.598l-8.224 8.225-3.463.865L7.792 11l8.226-8.226Z',
);

export const ShoppingBagIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M5.5 1.8333 2.75 5.5v12.8333a1.8333 1.8333 0 0 0 1.8333 1.8334h12.8334a1.8333 1.8333 0 0 0 1.8333-1.8334V5.5L16.5 1.8333h-11ZM2.75 5.5h16.5',
    'M14.6663 9.1667a3.6668 3.6668 0 0 1-6.2593 2.5927 3.6667 3.6667 0 0 1-1.074-2.5927',
);

export const BankIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M2.75 20.1667h16.5M5.5 16.5v-6.4167M9.1665 16.5v-6.4167M12.8335 16.5v-6.4167M16.5 16.5v-6.4167M11.0003 1.8333l7.3334 4.5834H3.667l7.3333-4.5834Z',
);

export const PhoneCallIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M20.167 15.51v2.75a1.831 1.831 0 0 1-1.998 1.833 18.142 18.142 0 0 1-7.911-2.814 17.875 17.875 0 0 1-5.5-5.5 18.141 18.141 0 0 1-2.814-7.947 1.833 1.833 0 0 1 1.824-1.999h2.75A1.833 1.833 0 0 1 8.351 3.41c.116.88.332 1.744.642 2.576a1.833 1.833 0 0 1-.412 1.934L7.416 9.084a14.668 14.668 0 0 0 5.5 5.5l1.164-1.164a1.833 1.833 0 0 1 1.934-.412c.832.31 1.696.525 2.576.642a1.833 1.833 0 0 1 1.577 1.86ZM12.879 1.833a8.25 8.25 0 0 1 7.333 7.278m-7.333-3.612A4.583 4.583 0 0 1 16.5 9.167',
);

export const DiamondPercentIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M2.475 10.13a2.21 2.21 0 0 0 0 3.125l6.957 6.957a2.21 2.21 0 0 0 3.127 0l6.957-6.957a2.208 2.208 0 0 0 0-3.125l-6.957-6.957a2.21 2.21 0 0 0-3.127 0L2.475 10.13Zm5.959-1.009h.008m4.848.275L8.707 13.98m4.767.275h.008',
);

export const BuildingIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M5.5 20.855v-16.5a1.833 1.833 0 0 1 1.833-1.834h7.334A1.833 1.833 0 0 1 16.5 4.355v16.5h-11Z',
    'M5.5 11.688H3.666a1.833 1.833 0 0 0-1.833 1.833v5.5a1.833 1.833 0 0 0 1.833 1.834H5.5m11-11.917h1.833a1.833 1.833 0 0 1 1.834 1.833v8.25a1.833 1.833 0 0 1-1.834 1.834H16.5M9.167 6.188h3.667M9.167 9.855h3.667M9.167 13.52h3.667m-3.667 3.668h3.667',
);

const circleViewbox = '0 0 16 16';
const outerCircle = {
    cx: '8',
    cy: '8',
    r: '7',
    strokeWidth: '2',
    fill: 'none',
};
const innerCircle = {
    cx: '8',
    cy: '8',
    r: '3',
};

export const CircleEmptyIcon = GenIcon(
    element('svg', { viewBox: circleViewbox },
        element('circle', outerCircle),
    ),
);

export const CircleSemiFullIcon = GenIcon(
    element('svg', { viewBox: circleViewbox },
        element('circle', outerCircle),
        element('path', {
            d: 'M8 5a1 1 0 0 1 0 6z',
            stroke: 'none',
        }),
    ),
);

export const CircleMostlyFullIcon = GenIcon(
    element('svg', { viewBox: circleViewbox },
        element('circle', outerCircle),
        element('path', {
            d: 'M8 5a1 1 0 0 1 0 6 3 3 0 0 1-3-3h3Z',
            stroke: 'none',
        }),
    ),
);

export const CircleFullIcon = GenIcon(
    element('svg', { viewBox: circleViewbox },
        element('circle', outerCircle),
        element('circle', innerCircle),
    ),
);

export const CircleCrossIcon = GenIcon(
    element('svg', { viewBox: circleViewbox },
        element('circle', outerCircle),
        element('path', {
            d: 'm10 6-4 4m0-4 4 4',
            strokeWidth: '1.333',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
        }),
    ),
);

const bigViewbox = '0 0 24 24';
export const BigCircleEmptyIcon = GenIcon(
    element('svg', { viewBox: bigViewbox },
        element('circle', {
            cx: '12',
            cy: '12',
            r: '11',
            strokeWidth: '2',
            fill: 'none',
        }),
    ),
);

export const BigCircleCheckIcon = GenIcon(
    element('svg', { viewBox: bigViewbox },
        element('circle', {
            cx: '12',
            cy: '12',
            r: '12',
            fill: 'currentColor',
        }),
        element('path', {
            d: 'm17 8.5-6.417 6.417L7.667 12',
            stroke: '#F5F5F5',
            strokeWidth: '1.5',
        }),
    ),
);

export const InvoiceDownloadIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M13.466 1.833H5.675a1.833 1.833 0 0 0-1.833 1.833v14.667a1.833 1.833 0 0 0 1.833 1.833h11a1.833 1.833 0 0 0 1.833-1.833V6.875l-5.042-5.042Z',
    'M13.008 1.833v5.5h5.5M11.175 16.5V11M8.425 13.75l2.75 2.75 2.75-2.75',
);

export const FilterIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M19.932 2.605H2.564l6.947 8.215v5.679l3.474 1.737v-7.415l6.947-8.215Z',
);

export const InfoIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M11 20.167a9.167 9.167 0 1 0 0-18.333 9.167 9.167 0 0 0 0 18.333Zm0-5.5V11m0-3.667h.012',
);

export const OrderDescIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'm3.448 14.667 3.667 3.667 3.667-3.667M7.115 18.333V3.667M10.781 3.667h9.167M10.781 7.333h6.417M10.781 11h3.667',
);

export const OrderAscIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'm3.448 7.334 3.667-3.667 3.667 3.667M7.115 3.668v14.666M10.781 18.334h9.167M10.781 14.668h6.417M10.781 11.001h3.667',
);

export const SettingsIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M11 18.333a7.333 7.333 0 1 0 0-14.666 7.333 7.333 0 0 0 0 14.667Z',
    'M11 12.833a1.833 1.833 0 1 0 0-3.667 1.833 1.833 0 0 0 0 3.667Zm0-11v1.834m0 16.5v-1.834m4.583.605-.916-1.585m-4.584-7.938L6.416 3.062m12.522 12.521-1.586-.916m-14.29-8.25 1.585.916M12.833 11h7.333M1.833 11h1.834m15.271-4.583-1.586.916m-14.29 8.25 1.585-.916M15.583 3.062l-.916 1.586m-4.584 7.937-3.667 6.353',
);

export const PaymentsIcon = generateIcon(TODO_BASE_SIZE_FIXME,
    'M3.667 1.833v18.333l1.833-.917 1.833.917 1.833-.917 1.833.917 1.833-.917 1.833.917 1.833-.917 1.833.917V1.833l-1.833.917-1.831-.917-1.833.917L11 1.833l-1.833.917-1.834-.917L5.5 2.75l-1.833-.917Z',
    'M14.667 7.333h-5.5a1.833 1.833 0 0 0 0 3.667h3.667a1.833 1.833 0 0 1 0 3.667H7.333m3.667.917V6.417',
);

type SortOrderIconProps = IconBaseProps & {
    orderBy?: SortOrder;
};

export function SortOrderIcon({ orderBy, ...iconProps }: SortOrderIconProps) {
    // The default value (undefined) is ascending.
    return (orderBy ?? SortOrder.Ascending) === SortOrder.Ascending ? <OrderAscIcon {...iconProps} /> : <OrderDescIcon {...iconProps} />;
}

export { default as FlowlanceBanner } from './FlowlanceBanner';
export { default as FlowlanceLogo } from './FlowlanceLogo';
export { default as StripeLogo } from './StripeLogo';

export const ArrowIcon = GenIcon(element('svg',
    {
        viewBox: `0 0 17 10`,
        strokeWidth: '1',
        fill: '#545454',
        stroke: 'none',
    },
    element('path', {
        d: 'M 16.424 5.424 a 0.6 0.6 0 0 0 0 -0.848 L 12.606 0.757 a 0.6 0.6 0 1 0 -0.849 0.849 L 15.152 5 l -3.395 3.394 a 0.6 0.6 0 1 0 0.849 0.849 z M 1 5.6 h 15 V 4.4 H 1 z',
    }),
));

export const DoubleArrowIcon = GenIcon(element('svg',
    {
        viewBox: `0 0 17 10`,
        strokeWidth: '1',
        fill: '#545454',
        stroke: 'none',
    },
    element('path', {
        d: 'M.576 4.576a.6.6 0 0 0 0 .848l3.818 3.819a.6.6 0 1 0 .849-.849L1.849 5l3.394-3.394a.6.6 0 1 0-.849-.849zm15.848.848a.6.6 0 0 0 0-.848L12.606.757a.6.6 0 1 0-.849.849L15.152 5l-3.395 3.394a.6.6 0 1 0 .849.849zM1 5.6h15V4.4H1z',
    }),
));

export const CommandIcon = generateIcon(16,
    'M10 4v8a2 2 0 1 0 2-2H4a2 2 0 1 0 2 2V4a2 2 0 1 0-2 2h8a2 2 0 1 0-2-2',
);

export const RepeatIcon = generateIcon(16,
    'M11.333 1.332 14 3.999l-2.667 2.666',
    'M2 7.333v-.666A2.667 2.667 0 0 1 4.667 4H14M4.667 14.665 2 12l2.667-2.667',
    'M14 8.668v.667A2.667 2.667 0 0 1 11.333 12H2',
);

export const ChainIcon = generateIcon(16,
    'M6.667 8.668a3.333 3.333 0 0 0 5.026.36l2-2A3.333 3.333 0 0 0 8.98 2.315l-1.147 1.14',
    'M9.333 7.333a3.333 3.333 0 0 0-5.026-.36l-2 2a3.333 3.333 0 0 0 4.713 4.713l1.14-1.14',
);

export const SmileIcon = generateIcon(16,
    'M5.333 9.332s1 1.333 2.667 1.333 2.667-1.333 2.667-1.333M6 6h.007M10 6h.007',
    'M14.5 8a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0Z',
);

export const DiagonalArrowsIcon = generateIcon(22,
    'M13.75 2.75h5.5v5.5m-11 11h-5.5v-5.5m16.5-11-6.417 6.417M2.75 19.249l6.417-6.417',
);

export const NotesIcon = generateIcon(18,
    'M6 1.5v3m3-3v3m3-3v3',
    'M13.5 3h-9A1.5 1.5 0 0 0 3 4.5V15a1.5 1.5 0 0 0 1.5 1.5h9A1.5 1.5 0 0 0 15 15V4.5A1.5 1.5 0 0 0 13.5 3M6 7.5h5m-5 3h6m-6 3h3.75',
);

export const EmojiPlusIcon = generateIcon(18,
    'M16.5 8.25V9a7.5 7.5 0 1 1-6.75-7.5',
    'M6 10.5S7.125 12 9 12s3-1.5 3-1.5M6.75 6.75h.008m4.492 0h.008m.742-3h4.5M14.25 1.5V6',
);

export const FileIcon = generateIcon({ w: 16, h: 16, strokeWidth: 1 },
    'M10 1.333H4a1.333 1.333 0 0 0-1.333 1.334v10.666A1.333 1.333 0 0 0 4 14.667h8a1.333 1.333 0 0 0 1.333-1.334V4.667z',
    'M9.333 1.333V4a1.333 1.333 0 0 0 1.334 1.333h2.666M6.667 6H5.333m5.334 2.667H5.333m5.334 2.666H5.333',
);

export const CartIcon = generateIcon(18,
    'M6 16.5A.75.75 0 1 0 6 15a.75.75 0 0 0 0 1.5m8.25 0a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M1.538 1.537h1.5l1.995 9.315a1.5 1.5 0 0 0 1.5 1.185h7.335a1.5 1.5 0 0 0 1.462-1.177l1.238-5.573H3.84',
);

const onboardingArrowPaths = [
    'm53.443 9.966 2.648-.423c1.63-.22 2.983-.545 4.126-.743l1.56-.284 1.247-.272c.735-.15 1.301-.247 1.733-.304 1.726-.236 1.3.212 1.396.572s.657.515-1.018 1.215c-.418.168-.976.374-1.715.622l-1.25.416-1.576.4c-1.148.286-2.553.654-4.229.92l-2.72.468c-.988.143-2.034.242-3.168.382a84 84 0 0 1-16.003.149A81.6 81.6 0 0 1 17.46 9.735 69.2 69.2 0 0 1 4.733 4.428a37 37 0 0 1-3.537-2.123C.382 1.767-.04 1.435.003 1.368.226.955 7.644 5.315 18.06 7.972a88 88 0 0 0 16.723 2.706c5.196.324 10.409.21 15.583-.34l3.052-.363M64.8 5.84a94 94 0 0 1-3.685-2.358c-2.081-1.404-3.288-2.406-3.133-2.661.155-.256 1.633.27 3.917 1.345 1.139.535 2.47 1.203 3.942 1.972.74.397 1.467.775 2.316 1.257.55.295 1.009.735 1.327 1.271.209.391.297.835.254 1.275a2.9 2.9 0 0 1-.407 1.189q-.484.714-1.063 1.354c-.312.399-.665.765-.997 1.13a60 60 0 0 1-1.97 2.05 48 48 0 0 1-3.57 3.211c-2.13 1.713-3.596 2.548-3.762 2.335-.165-.212.978-1.457 2.82-3.405.923-.976 1.998-2.11 3.192-3.444a96 96 0 0 0 1.827-2.07l.94-1.102q.444-.496.82-1.047c.147-.28.118-.22.07-.359a1.8 1.8 0 0 0-.64-.594c-.758-.457-1.498-.9-2.19-1.324',
    'M.423 13.22a19 19 0 0 1 1.284-2.99 16.2 16.2 0 0 1 2.83-3.847 6.9 6.9 0 0 1 2.242-1.525 4.5 4.5 0 0 1 2.775-.147 6.35 6.35 0 0 1 2.63 1.449c.776.72 1.44 1.572 1.97 2.524q.802 1.486 1.451 3.058a8.4 8.4 0 0 0 2.05 3.026c.998.825 2.219 1.258 3.468 1.23a7 7 0 0 0 1.745-.21 10 10 0 0 0 1.596-.526c.959-.415 1.87-.949 2.717-1.593a21.46 21.46 0 0 0 6.599-8.49c.35-.811.643-1.653.874-2.515l.156 1.023c.135.797.269 1.537.396 2.146.257 1.215.493 1.96.642 1.94s.185-.797.123-2.043a49 49 0 0 0-.163-2.174c-.044-.398-.087-.836-.134-1.282q-.038-.331-.08-.673l-.044-.347-.021-.183a2.4 2.4 0 0 0-.066-.306 1.13 1.13 0 0 0-.34-.558.9.9 0 0 0-.581-.207 1.1 1.1 0 0 0-.599.207q-.12.083-.232.18l-.12.103c-.326.287-.649.553-.96.796-.621.502-1.202.944-1.68 1.326-.966.757-1.542 1.278-1.452 1.406s.799-.156 1.887-.757a21 21 0 0 0 1.854-1.163c.217-.147.435-.314.656-.481a33 33 0 0 1-1.23 3.038 22.4 22.4 0 0 1-4.291 6.171 23 23 0 0 1-1.992 1.76 11.7 11.7 0 0 1-2.423 1.454 9 9 0 0 1-1.382.474 6 6 0 0 1-1.451.19 4 4 0 0 1-2.674-.895 7.3 7.3 0 0 1-1.778-2.572A31 31 0 0 0 15.12 8.11a11.1 11.1 0 0 0-2.246-2.744 7.2 7.2 0 0 0-3.09-1.592 5.32 5.32 0 0 0-3.313.283A7.6 7.6 0 0 0 4.018 5.84a15.9 15.9 0 0 0-2.764 4.145 15 15 0 0 0-1.089 3.154c-.163.768-.188 1.194-.148 1.194s.166-.398.41-1.134',
    'M36.741 11.94a1.7 1.7 0 0 0-.428-.412 3.2 3.2 0 0 0-.788-.405c-3.351-1.269-6.15-2.042-6.246-1.728s2.346 1.502 5.52 2.723c-.42.131-.981.31-1.72.517-.74.206-1.698.45-2.888.653a27.4 27.4 0 0 1-7.116.334 24 24 0 0 1-3.237-.47 17 17 0 0 1-1.815-.505l.061-.065a7.9 7.9 0 0 0 2.758-3.646 6.61 6.61 0 0 0-1.7-7.192 6.4 6.4 0 0 0-2.324-1.44 4.87 4.87 0 0 0-2.703-.198 4.7 4.7 0 0 0-2.261 1.231 5.8 5.8 0 0 0-1.662 4.6 8.36 8.36 0 0 0 1.974 4.509 11.1 11.1 0 0 0 3.108 2.57q-.682.294-1.394.506c-1.227.36-2.49.586-3.765.673a13 13 0 0 1-3.492-.192 7 7 0 0 1-2.776-1.257A11.2 11.2 0 0 1 .837 9.33 16 16 0 0 0 0 7.843c.097.565.29 1.11.57 1.611a10.76 10.76 0 0 0 2.945 3.749A7.7 7.7 0 0 0 6.523 14.7a13.6 13.6 0 0 0 3.708.335 18.6 18.6 0 0 0 3.986-.59q1.169-.316 2.26-.845c1.019.446 2.08.789 3.169 1.023 1.137.247 2.29.412 3.45.494a26.7 26.7 0 0 0 7.489-.446c.997-.206 1.98-.48 2.94-.82a15 15 0 0 0 1.71-.707l.256-.131a1 1 0 0 0-.08.082q-.26.39-.491.75c-.314.474-.594.9-.824 1.255-.468.726-.708 1.184-.591 1.29.116.105.563-.176 1.202-.768q.561-.524 1.063-1.106c.203-.211.387-.464.586-.713.102-.138.19-.247.306-.411q.105-.15.171-.319a1.15 1.15 0 0 0 .089-.502 1.2 1.2 0 0 0-.207-.617zm-19.34-.196q-.286.225-.588.428c-.151-.069-.3-.129-.445-.204a9.5 9.5 0 0 1-3.11-2.401 6.85 6.85 0 0 1-1.645-3.64 4.53 4.53 0 0 1 1.233-3.571 3.45 3.45 0 0 1 1.646-.935 3.5 3.5 0 0 1 1.96.12 5.47 5.47 0 0 1 3.099 2.831c.57 1.302.629 2.77.166 4.112a6.97 6.97 0 0 1-2.306 3.269',
    'M33.796 7.124a59.6 59.6 0 0 0-11.185-.097c-3.752.333-6.659 1.02-6.634 1.276.025.255 2.954.074 6.59.11 3.629.033 7.254.253 10.86.66 2.275.247 3.542.397 4.29.392l-.704.559-1.76 1.32a186 186 0 0 0-3.004 2.253c-1.703 1.305-2.713 2.191-2.597 2.37s1.343-.379 3.214-1.464a77 77 0 0 0 3.228-1.988c.607-.374 1.218-.816 1.853-1.273.317-.235.644-.476.973-.744.2-.17 1.134-.89.9-1.623-.023-.091-.034-.184-.064-.277l-.09-.221a1 1 0 0 1-.14-.29c-.109-.177-.188-.36-.283-.525l-.589-.956c-.383-.622-.812-1.169-1.209-1.696a49 49 0 0 0-2.217-2.71C33.906.704 32.973-.126 32.789.014c-.183.14.412 1.224 1.477 2.89.542.827 1.188 1.8 1.9 2.893.338.55.728 1.107 1.098 1.697l.12.196a30 30 0 0 0-3.571-.563M13.329 8.714c-.035-.335-.983-.45-2.109-.212s-3.274 1.033-3.2 1.37c.076.336 2.282.052 3.382-.163 1.1-.216 1.964-.656 1.927-.995m-7.925 1.899c-.061-.26-1.053-.19-2.203.202S-.095 12.264.003 12.52c.099.256 2.35-.405 3.479-.777s1.99-.87 1.936-1.14',
    'M28.4 13.712a9.65 9.65 0 0 0 2.662-3.173 8.2 8.2 0 0 0 .934-4.066 7.9 7.9 0 0 0-1.14-3.769A5.57 5.57 0 0 0 28.19.424a7.15 7.15 0 0 0-2.896-.408 7.6 7.6 0 0 0-2.47.505 9.8 9.8 0 0 0-1.853 1.09q-.725.533-1.378 1.156a13 13 0 0 0-1.75 2.055 9 9 0 0 0-.753 1.312l.03-.737c.042-.758.094-1.44.135-2.014.086-1.15.13-1.876 0-1.91s-.403.64-.661 1.775a19 19 0 0 0-.345 2.045q-.067.582-.11 1.221c0 .215-.024.433-.03.651v.304q.005.083.02.163a1 1 0 0 0 .286.557.9.9 0 0 0 .55.225h.931c.385 0 .757-.024 1.109-.034a39 39 0 0 0 1.877-.115c1.067-.093 1.722-.225 1.722-.37s-.662-.273-1.722-.346a38 38 0 0 0-1.877-.107c-.345 0-.724-.021-1.11-.031h-.557c.093-.146.213-.346.389-.65q.409-.667.892-1.281a14.4 14.4 0 0 1 1.688-1.817q.61-.56 1.288-1.038a9 9 0 0 1 1.639-.935 6.7 6.7 0 0 1 2.108-.404c.834-.05 1.67.067 2.459.346a4.4 4.4 0 0 1 2.09 1.8c.577.939.907 2.01.958 3.114a6.9 6.9 0 0 1-.765 3.415 8.6 8.6 0 0 1-2.314 2.807 9.6 9.6 0 0 1-3.265 1.664c-1.195.34-2.43.52-3.672.533a17 17 0 0 1-1.86-.059c-.306-.031-.616-.045-.922-.083s-.61-.086-.913-.131a22.5 22.5 0 0 1-3.517-.859 24.7 24.7 0 0 1-6.023-2.972 26.3 26.3 0 0 1-4.254-3.634c-.561-.578-1.033-1.145-1.44-1.633L.91 4.265A6.4 6.4 0 0 0 0 3.178q.29.661.71 1.246l.916 1.412c.396.523.837 1.128 1.377 1.73a25.5 25.5 0 0 0 4.25 3.887 24.7 24.7 0 0 0 6.12 3.214 23 23 0 0 0 3.624.973c.317.052.63.104.947.152.317.049.637.07.957.107q.968.106 1.943.1a14.6 14.6 0 0 0 3.923-.508 10.5 10.5 0 0 0 3.633-1.793',
];

const onboardingArrowSizes = [
    { w: 70, h: 20 },
    { w: 36, h: 16 },
    { w: 37, h: 17 },
    { w: 40, h: 16 },
    { w: 32, h: 16 },
];

export function OnboardingArrowIcon({ variant, className }: Readonly<{ variant: number, className?: string }>) {
    const options: IconOptions = { ...onboardingArrowSizes[variant], stroke: 'none', fill: 'currentColor' };
    const Icon = generateIcon(options, onboardingArrowPaths[variant]);

    return (
        <Icon style={{ width: options.w, height: options.h }} className={className} />
    );
}

export const QuoteStart = generateIcon({ w: 17, h: 14, fill: 'currentColor', stroke: 'none' },
    'M4.144 13.168q-1.104 0-1.92-.576-.816-.624-1.248-1.68t-.432-2.4a9.3 9.3 0 0 1 .528-3.12A9 9 0 0 1 2.8 2.56Q3.952 1.264 5.776.4l2.016 1.872a7.9 7.9 0 0 0-2.304 1.2A7.3 7.3 0 0 0 3.904 5.2a7 7 0 0 0-.816 1.968l.192.096q.192-.144.48-.24t.864-.096 1.2.336 1.008 1.008q.432.624.432 1.68 0 .864-.432 1.584-.384.72-1.104 1.2a3 3 0 0 1-1.584.432m9.024 0q-1.104 0-1.92-.576-.816-.624-1.248-1.68t-.432-2.4a9.3 9.3 0 0 1 .528-3.12 9 9 0 0 1 1.728-2.832Q12.976 1.264 14.8.4l2.016 1.872a7.9 7.9 0 0 0-2.304 1.2A7.3 7.3 0 0 0 12.928 5.2a7 7 0 0 0-.816 1.968l.192.096q.192-.144.48-.24t.864-.096 1.2.336 1.008 1.008q.432.624.432 1.68 0 .864-.432 1.584-.384.72-1.104 1.2a3 3 0 0 1-1.584.432',
);

export const QuoteEnd = generateIcon({ w: 17, h: 14, fill: 'currentColor', stroke: 'none' },
    'M4.144.832q-1.104 0-1.92.576-.816.624-1.248 1.68t-.432 2.4a9.3 9.3 0 0 0 .528 3.12A9 9 0 0 0 2.8 11.44q1.152 1.296 2.976 2.16l2.016-1.872a7.9 7.9 0 0 1-2.304-1.2A7.3 7.3 0 0 1 3.904 8.8a7 7 0 0 1-.816-1.968l.192-.096q.192.144.48.24t.864.096 1.2-.336 1.008-1.008q.432-.624.432-1.68 0-.864-.432-1.584a3.3 3.3 0 0 0-1.104-1.2A3 3 0 0 0 4.144.832m9.024 0q-1.104 0-1.92.576-.816.624-1.248 1.68t-.432 2.4a9.3 9.3 0 0 0 .528 3.12 9 9 0 0 0 1.728 2.832q1.152 1.296 2.976 2.16l2.016-1.872a7.9 7.9 0 0 1-2.304-1.2A7.3 7.3 0 0 1 12.928 8.8a7 7 0 0 1-.816-1.968l.192-.096q.192.144.48.24t.864.096 1.2-.336 1.008-1.008q.432-.624.432-1.68 0-.864-.432-1.584a3.3 3.3 0 0 0-1.104-1.2 3 3 0 0 0-1.584-.432',
);

export const StonksIcon = generateIcon({ w: 20, h:10, fill: 'currentColor', stroke: 'none' },
    'm14 0 2.29 2.29 -4.88 4.88 -4 -4L0 10.59 1.41 12l6 -6 4 4 6.3 -6.29L20 6V0z',
);

export function AllIcons({ size }: Readonly<{ size: number }>) {
    return (
        <div className='d-flex flex-wrap gap-3' style={{ width: '260px' }}>
            <CalendarClockIcon size={size} />
            <CalendarCheckIcon size={size} />
            <CalendarPlusIcon size={size} />
            <ClockIcon size={size} />
            <CircleSlashIcon size={size} />
            <CircleUserIcon size={size} />
            <DownloadIcon size={size} />
            <ExpandIcon size={size} />
            <EyeIcon size={size} />
            <CrossedEyeIcon size={size} />
            <CheckIcon size={size} />
            <SmallCheckIcon size={size} />
            <ChevronLeftIcon size={size} />
            <ChevronRightIcon size={size} />
            <ChevronDownIcon size={size} />
            <ChevronUpIcon size={size} />
            <InvoiceIcon size={size} />
            <PlusIcon size={size} />
            <ReceiptIcon size={size} />
            <SendIcon size={size} />
            <TrashIcon size={size} />
            <UserPlusIcon size={size} />
            <UsersIcon size={size} />
            <MapPinIcon size={size} />
            <CalendarIcon size={size} />
            <WalletIcon size={size} />
            <CloseIcon size={size} />
            <DotsVerticalIcon size={size} />
            <DotsHorizontalIcon size={size} />
            <EditNotesIcon size={size} />
            <ShoppingBagIcon size={size} />
            <BankIcon size={size} />
            <PhoneCallIcon size={size} />
            <DiamondPercentIcon size={size} />
            <BuildingIcon size={size} />
            <CircleEmptyIcon size={size} />
            <CircleSemiFullIcon size={size} />
            <CircleMostlyFullIcon size={size} />
            <CircleFullIcon size={size} />
            <CircleCrossIcon size={size} />
            <BigCircleEmptyIcon size={size} />
            <BigCircleCheckIcon size={size} />
            <InvoiceDownloadIcon size={size} />
            <FilterIcon size={size} />
            <InfoIcon size={size} />
            <OrderDescIcon size={size} />
            <OrderAscIcon size={size} />
            <SettingsIcon size={size} />
            <PaymentsIcon size={size} />
            <ArrowIcon size={size} />
            <DoubleArrowIcon size={size} />
            <CommandIcon size={size} />
            <RepeatIcon size={size} />
            <ChainIcon size={size} />
            <SmileIcon size={size} />
            <DiagonalArrowsIcon size={size} />
            <NotesIcon size={size} />
            <EmojiPlusIcon size={size} />
            <FileIcon size={size} />
            <CartIcon size={size} />
            <OnboardingArrowIcon variant={0} />
            <OnboardingArrowIcon variant={1} />
            <OnboardingArrowIcon variant={2} />
            <OnboardingArrowIcon variant={3} />
            <OnboardingArrowIcon variant={4} />
            <QuoteStart size={17} />
            <QuoteEnd size={17} />
            <StonksIcon size={size} />
        </div>
    );
}
