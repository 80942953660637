import { memo } from 'react';
import { type EventFE } from ':frontend/types/Event';
import { useTranslation } from 'react-i18next';
import type { IconType } from 'react-icons';
import { CircleFullIcon, CircleSemiFullIcon, CircleEmptyIcon } from ':frontend/components/icons';
import { participantIsBillable } from ':frontend/types/EventParticipant';

type EventPaymentState = 'billable' | 'unpaid' | 'paid';

const stateDescriptions: Record<EventPaymentState, {
        color: string;
        label: string;
        icon: IconType;
    }> = {
        'billable': { color: 'secondary', label: 'billable', icon: CircleEmptyIcon },
        'unpaid': { color: 'warning', label: 'unpaid', icon: CircleSemiFullIcon },
        'paid': { color: 'success', label: 'paid', icon: CircleFullIcon },
    };

type EventPaymentStateBadgeProps = Readonly<{
    event: EventFE;
}>;

const EventPaymentStateBadge = memo(EventPaymentStateBadgeRaw);
export default EventPaymentStateBadge;

function EventPaymentStateBadgeRaw({ event }: EventPaymentStateBadgeProps) {
    const { t } = useTranslation('common', { keyPrefix: 'event.paymentState' });

    const { state, count } = computeState(event);
    if (state === null)
        return null;

    const description = stateDescriptions[state];

    return (
        <div className='d-flex align-items-center'>
            {description.icon({ size: 16, className: `text-${description.color} me-2` })}
            <span>{t(description.label, { count })}</span>
        </div>
    );
}

function computeState(event: EventFE): { state: EventPaymentState | null, count: number } {
    const payingCount = event.clients.length;
    if (payingCount === 0)
        // This should not happen since each event should have at least one billable participant.
        return { state: null, count: 0 };

    const paidCount = event.clients.filter(p => p.isPaid).length;
    if (paidCount === payingCount)
        return { state: 'paid', count: paidCount };

    const billableCount = event.clients.filter(participantIsBillable).length;
    if (billableCount > 0)
        return { state: 'billable', count: billableCount };

    return { state: 'unpaid', count: payingCount - paidCount };
}
