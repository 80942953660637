import { type CurrencyFE, type FormMoney, type Money } from './types';
import { priceFromServer } from ':frontend/utils/math';
import { compareStringsAscii } from ':frontend/utils/common';
import { type CurrencyId } from ':utils/id';

export function moneyFromServer(amount: number, currencyId: CurrencyId): Money {
    const currency = getCurrency(currencyId);
    if (!currency)
        throw new Error(`Currency code ${currencyId} not found.`);

    return {
        amount: priceFromServer(amount),
        currency,
    };
}

const ALL_CURRENCIES_MAP: Map<CurrencyId, CurrencyFE> = new Map;

export function getAllCurrencies(): CurrencyFE[] {
    return [ ...ALL_CURRENCIES_MAP.values() ];
}

export function addCurrency(currency: CurrencyFE) {
    if (ALL_CURRENCIES_MAP.has(currency.id))
        return;

    ALL_CURRENCIES_MAP.set(currency.id, currency);
}

export function getCurrency(id: CurrencyId): CurrencyFE {
    const currency = tryGetCurrency(id);
    if (!currency)
        throw new Error(`Currency with id ${id} not found.`);

    return currency;
}

export function tryGetCurrency(id: CurrencyId): CurrencyFE | undefined {
    return ALL_CURRENCIES_MAP.get(id);
}

export function compareCurrencies(a: CurrencyFE, b: CurrencyFE): number {
    return compareStringsAscii(a.id, b.id);
}

export function getDefaultCurrency(): CurrencyFE {
    return getAllCurrencies()[0];
}

export function timesBy(money: Money, multiplier: number): Money {
    return {
        amount: money.amount * multiplier,
        currency: money.currency,
    };
}

export function divideBy(money: Money, divisor: number): Money {
    return {
        amount: money.amount / divisor,
        currency: money.currency,
    };
}

export function moneyEquals(a: FormMoney, b: FormMoney) {
    return a.amount === b.amount && a.currency === b.currency;
}

export function isUnderMinimalAmount(money: Money): boolean {
    return money.amount < money.currency.minimalAmount;
}

export { priceFromServer, priceToServer } from ':frontend/utils/math';
