import { createEnv } from '@t3-oss/env-core';
import { z } from 'zod';

export const env = createEnv({
    clientPrefix: 'VITE_',
    client: {
        VITE_APP_URL: z.string().url(),
        VITE_SMALLCHAT_ID: z.string(),

        VITE_REWARDFUL_ID: z.string().optional(),
        VITE_GTM_ID: z.string().optional(),
    },
    server: {
        SENTRY_AUTH_TOKEN: z.string(),
    },
    emptyStringAsUndefined: true,
    runtimeEnv: import.meta.env,
});
