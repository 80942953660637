import { useUser } from ':frontend/context/UserProvider';
import { AppUserFE } from ':frontend/types/AppUser';
import { AppUserSettingsFE } from ':frontend/types/settings';
import PersonForm, { type PersonFormData } from ':frontend/components/settings/PersonForm';
import PreferencesForm from ':frontend/components/settings/PreferencesForm';
import { useTranslation } from 'react-i18next';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import { useAnalytics } from ':frontend/types/analytics';
import { trpc } from ':frontend/context/TrpcProvider';
import type { AppUserEdit } from ':utils/entity/user';
import type { AppUserSettingsEdit } from ':utils/entity/settings';
import { optionalStringToPut } from ':frontend/utils/common';

export default function GeneralSettingsEditor() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.general' });
    const { addAlert } = useNotifications();
    const { settings, setSettings, appUser, setAppUser } = useUser();
    const analytics = useAnalytics();

    const updateAppUserMutation = trpc.user.updateAppUser.useMutation();
    const updateAppUserSettingsMutation = trpc.user.updateAppUserSettings.useMutation();

    function onPersonSubmit(data: PersonFormData) {
        const edit: AppUserEdit = {
            ...data,
            phoneNumber: optionalStringToPut(data.phoneNumber),
        };
        updateAppUserMutation.mutate(edit, {
            onError: () => {
                setAppUser({ ...appUser });
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: response => {
                const newAppUser = AppUserFE.fromServer(response);
                setAppUser(newAppUser);
                addAlert(createTranslatedSuccessAlert('pages:settings.general.generic-settings-success'));
            },
        });
    }

    function onPreferencesSubmit(edit: AppUserSettingsEdit) {
        updateAppUserSettingsMutation.mutate(edit, {
            onError: () => {
                setSettings({ ...settings });
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: response => {
                analytics.settingsUpdated({ ...edit });
                const newSettings = AppUserSettingsFE.fromServer(response);
                setSettings(newSettings);
                addAlert(createTranslatedSuccessAlert('pages:settings.general.preferences-settings-success'));
            },
        });
    }

    return (
        <div>
            <h1 className='mt-0'>{t('page-title')}</h1>
            <PersonForm
                input={appUser}
                onSubmit={onPersonSubmit}
                isFetching={updateAppUserMutation.isPending}
            />
            <h2 className='mt-5'>{t('preferences-section-title')}</h2>
            <div className='mb-5'>
                <PreferencesForm
                    input={settings}
                    onSubmit={onPreferencesSubmit}
                    isFetching={updateAppUserSettingsMutation.isPending}
                />
            </div>
        </div>
    );
}
