import clsx from 'clsx';
import {  CloseIcon, DotsVerticalIcon, PlusIcon, TrashIcon } from '../icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiFillInfoCircle } from 'react-icons/ai';
import { MdModeEdit } from 'react-icons/md';

export type BaseButtonProps = Readonly<{
    aria: string;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    id?: string;
    disabled?: boolean;
}>;

export function DotsVerticalButton({ aria, className, ...rest }: BaseButtonProps) {
    return (
        <button
            type='button'
            aria-label={aria}
            className={clsx('sh-icon-button', className)}
            {...rest}
        >
            <DotsVerticalIcon size={18} />
        </button>
    );
}

export function CloseButton({ aria, className, ...rest }: BaseButtonProps) {
    return (
        <button
            type='button'
            aria-label={aria}
            className={clsx('sh-icon-button', className)}
            {...rest}
        >
            <CloseIcon size={18} />
        </button>
    );
}

export function DeleteButton({ aria, className, ...rest }: BaseButtonProps) {
    return (
        <button
            type='button'
            aria-label={aria}
            className={clsx('sh-icon-button', className)}
            {...rest}
        >
            <TrashIcon size={16} />
        </button>
    );
}

export function AddButton({ aria, className, ...rest }: BaseButtonProps) {
    return (
        <button
            type='button'
            aria-label={aria}
            className={clsx('sh-icon-button', className)}
            {...rest}
        >
            <PlusIcon size={16} />
        </button>
    );
}

export function EditButton({ aria, className, ...rest }: BaseButtonProps) {
    return (
        <button
            type='button'
            aria-label={aria}
            className={clsx('sh-icon-button', className)}
            {...rest}
        >
            <MdModeEdit size={16} />
        </button>
    );
}

type InfoTooltipProps = Readonly<{
    text: string;
    className?: string;
}>;

export function InfoTooltip({ text, className }: InfoTooltipProps) {
    return (
        <div className={clsx('d-inline-block position-relative', className)} style={{ width: '0px', height: '12px' }}>
            <div className='position-absolute' style={{ top: '-3px' }}>
                <OverlayTrigger
                    overlay={<Tooltip className='pre-line'>{text}</Tooltip>}
                >
                    <span className='d-inline-block' style={{ width: '18px', height: '18px' }}>
                        <AiFillInfoCircle size={18} className='text-primary clickable' />
                    </span>
                </OverlayTrigger>
            </div>
        </div>
    );
}
