import { z } from 'zod';
import { zId } from ':utils/id';

export type ProductOutput = z.infer<typeof zProductOutput>
export const zProductOutput = z.object({
    id: zId,
    title: z.string(),
    icon: z.string().optional(),
    description: z.string().optional(),
    sessionsCount: z.number(),
    sessionsDuration: z.number(),
    price: z.number(),
    currency: zId,
    vat: zId,
    location: zId.optional(),
});

export type ProductInit = z.infer<typeof zProductInit>;
export const zProductInit = z.object({
    title: z.string(),
    icon: z.string().optional(),
    description: z.string().optional(),
    sessionsCount: z.number(),
    sessionsDuration: z.number(),
    price: z.number(),
    currency: zId,
    vat: zId.optional(),
    locationId: zId.optional(),
});

export type ProductEdit = z.infer<typeof zProductEdit>;
export const zProductEdit = z.object({
    id: zId,
    title: z.string().optional(),
    icon: z.string().nullish(),
    description: z.string().nullish(),
    sessionsCount: z.number().optional(),
    sessionsDuration: z.number().optional(),
    price: z.number().optional(),
    currency: zId.optional(),
    vat: zId.optional(),
    locationId: zId.nullish(),
});
