import { type Dispatch, type SetStateAction, useCallback, useMemo, type ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Skeleton, Table } from ':frontend/components/common';
import { type ClientInfoFE } from ':frontend/types/Client';
import { Trans, useTranslation } from 'react-i18next';
import { routesFE } from ':utils/routes';
import { useClients, useSortOrder } from ':frontend/hooks';
import { SortOrderIcon, PlusIcon } from ':frontend/components/icons';
import { ClientIconBadge } from ':frontend/components/client/ClientIconLink';
import { type SortOrder } from ':utils/common';
import { getRandomSkeletonArray } from ':frontend/utils/math';
import { InfoCard } from ':frontend/components/settings/InfoCard';
import { ClientStateEdit } from ':frontend/components/client/ClientStateBadge';
import { ClientTags } from ':frontend/components/client/ClientTags';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import ClientStateFilter from ':frontend/components/common/filters/ClientStateFilter';
import ClientTagFilter from ':frontend/components/common/filters/ClientTagFilter';
import ClientNameFilter from ':frontend/components/common/filters/ClientNameFilter';
import FilterRow, { useFilters, useFiltersApply } from ':frontend/components/common/filters/FilterRow';

export default function Clients() {
    const { t } = useTranslation('pages', { keyPrefix: 'clients' });
    const isMasterOrFreelancer = !!toMaster(useUser());
    const [ nameOrder, setNameOrder ] = useSortOrder();
    const { clients: allClients, setClients } = useClients({ nameOrder });

    const filters = useMemo(() => [
        ...(isMasterOrFreelancer ? [ ClientStateFilter, ClientTagFilter ] : []),
        ClientNameFilter,
    ], [ isMasterOrFreelancer ]);

    const filtersControl = useFilters(filters);
    const applyFilters = useFiltersApply(filtersControl);

    const filteredClients = useMemo(() => allClients?.filter(applyFilters), [ allClients, applyFilters ]);

    return (
        <Container className='pb-4'>
            <h1 className='mt-3 mb-4'>{t('page-title')}</h1>
            <InfoCard infoKey='clients' className='mb-4' />
            <Row className='mb-3'>
                <Col>
                    <FilterRow control={filtersControl} />
                </Col>
                {allClients && allClients.length !== 0 && (<>
                    <Col xs='auto'>
                        <Link to={routesFE.clients.import}>
                            <Button className='compact'>{t('import-clients-button')}</Button>
                        </Link>
                    </Col>
                    <Col xs='auto'>
                        <Link to={routesFE.clients.new} className='mb-4'>
                            <Button variant='outline-primary' className='compact py-0'>
                                <PlusIcon size={22} className='me-2' />{t('new-client-button')}
                            </Button>
                        </Link>
                    </Col>
                </>)}
            </Row>
            <ClientsTable
                clients={filteredClients}
                setClients={setClients}
                noClientsMessage={allClients?.length === 0 ? noClientsYetMessage() : t('no-clients-text')}
                nameOrder={nameOrder}
                switchNameOrder={setNameOrder.toggle}
            />
        </Container>
    );
}

function noClientsYetMessage() {
    return (
        <Trans
            i18nKey='pages:clients.no-clients-yet-text'
            components={{
                // @ts-expect-error Translation api.
                import: <ImportClientsButton />,
                // @ts-expect-error Translation api.
                new: <NewClientButton />,
            }}
        />
    );
}

type ChildrenProps = Readonly<{
    children: ReactNode;
}>;

function ImportClientsButton({ children }: ChildrenProps) {
    return (
        <Link to={routesFE.clients.import}>
            <Button className='compact'>{children}</Button>
        </Link>
    );
}

function NewClientButton({ children }: ChildrenProps) {
    return (
        <Link to={routesFE.clients.new} className='mb-4'>
            <Button variant='outline-primary' className='compact py-0'>
                <PlusIcon size={22} className='me-2' />{children}
            </Button>
        </Link>
    );
}

type ClientsTableProps = Readonly<{
    clients?: ClientInfoFE[];
    setClients: Dispatch<SetStateAction<ClientInfoFE[] | undefined>>;
    noClientsMessage: ReactNode;
    nameOrder: SortOrder | undefined;
    switchNameOrder: () => void;
}>;

export function ClientsTable({ clients, setClients, noClientsMessage, nameOrder, switchNameOrder }: ClientsTableProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'clients' });
    const isMasterOrFreelancer = !!toMaster(useUser());

    return (
        <Table>
            <Table.Header>
                <Table.HeaderCol className='text-nowrap'>
                    <span className='me-1'>{t('client-label')}</span>
                    <SortOrderIcon orderBy={nameOrder} className='clickable' size={18} onClick={switchNameOrder}/>
                </Table.HeaderCol>
                {isMasterOrFreelancer && (<>
                    <Table.HeaderCol xs={1.5}>{t('state-label')}</Table.HeaderCol>
                    <Table.HeaderCol xs={3}>{t('tags-label')}</Table.HeaderCol>
                </>)}
                <Table.HeaderCol>{t('email-label')}</Table.HeaderCol>
                <Table.HeaderCol xs={1.5} >{t('phone-label')}</Table.HeaderCol>
            </Table.Header>
            <Table.Body>
                <ClientsList
                    clients={clients}
                    setClients={setClients}
                    noClientsMessage={noClientsMessage}
                />
            </Table.Body>
        </Table>
    );
}

type ClientsListProps = Readonly<{
    clients?: ClientInfoFE[];
    setClients: Dispatch<SetStateAction<ClientInfoFE[] | undefined>>;
    noClientsMessage: ReactNode;
}>;

function ClientsList({ clients, setClients, noClientsMessage }: ClientsListProps) {
    const setClient = useCallback((client: ClientInfoFE) => {
        setClients(oldClients => oldClients?.map(oldClient => oldClient.id === client.id ? client : oldClient));
    }, [ setClients ]);

    if (!clients)
        return (<>{getRandomSkeletonArray().map(id => <ClientSkeleton key={id} />)}</>);

    if (clients.length === 0) {
        return (
            <Table.Row>
                <Table.Col colSpan={4} className='text-center fs-4 py-5'>
                    {noClientsMessage}
                </Table.Col>
            </Table.Row>
        );
    }

    return (<>
        {clients.map(client => (
            <ClientRow
                key={client.id}
                client={client}
                setClient={setClient}
            />
        ))}
    </>);
}

type ClientRowProps = Readonly<{
    client: ClientInfoFE;
    setClient: (client: ClientInfoFE) => void;
}>;

function ClientRow({ client, setClient }: ClientRowProps) {
    const isMasterOrFreelancer = !!toMaster(useUser());
    const link = routesFE.clients.detail.resolve({ id: client.id, key: 'general' });

    return (
        <Table.Row className='clickable hoverable'>
            <Table.Col truncate link={link}>
                <ClientIconBadge client={client} />
            </Table.Col>
            {isMasterOrFreelancer && (<>
                <Table.Col className='non-clickable hoverable-exception py-2'>
                    <ClientStateEdit client={client} setClient={setClient} />
                </Table.Col>
                <Table.Col className='non-clickable hoverable-exception py-2'>
                    <ClientTags client={client} setClient={setClient} />
                </Table.Col>
            </>)}
            <Table.Col truncate className='fw-medium' link={link}>{client.email}</Table.Col>
            <Table.Col link={link}>{client.phoneNumber}</Table.Col>
        </Table.Row>
    );
}

function ClientSkeleton() {
    const isMasterOrFreelancer = !!toMaster(useUser());

    return (
        <Table.Row>
            <Table.Col><Skeleton height={18} /></Table.Col>
            <Table.Col><Skeleton height={18} /></Table.Col>
            {isMasterOrFreelancer && (<>
                <Table.Col><Skeleton height={18} /></Table.Col>
                <Table.Col><Skeleton height={18} /></Table.Col>
            </>)}
            <Table.Col><Skeleton height={18} /></Table.Col>
        </Table.Row>
    );
}
