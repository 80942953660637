import { getCurrency, type CurrencyFE } from ':frontend/modules/money';
import { type Person } from './Person';
import { type AppUserFE } from './AppUser';
import type { TeamMemberOutput, TeamMemberRole, TeamOutput } from ':utils/entity/team';
import { type Id } from ':utils/id';
import type { DateTime } from 'luxon';

export class TeamFE {
    private constructor(
        readonly id: Id,
        readonly title: string,
        readonly legalName: string,
        readonly email: string,
        readonly paymentEmail: string | undefined,
        readonly dateTrialStarted: DateTime | undefined,
        readonly stripeCurrency: CurrencyFE,
        readonly isStripeConnected: boolean,
    ) {}

    static fromServer(input: TeamOutput): TeamFE {
        return new TeamFE(
            input.id,
            input.title,
            input.legalName,
            input.email,
            input.paymentEmail,
            input.dateTrialStarted,
            getCurrency(input.stripeCurrencyId),
            input.isStripeConnected,
        );
    }
}

export class TeamMemberFE implements Person {
    private constructor(
        readonly id: Id,
        readonly appUserId: Id,
        readonly firstName: string,
        readonly lastName: string | undefined,
        readonly email: string,
        readonly phoneNumber: string | undefined,
        readonly role: TeamMemberRole,
        /** Hex code (6 characters). */
        readonly color: string,
    ) {}

    static fromServer(input: TeamMemberOutput): TeamMemberFE {
        return new TeamMemberFE(
            input.id,
            input.appUser.id,
            input.appUser.firstName,
            input.appUser.lastName,
            input.appUser.email,
            input.appUser.phoneNumber,
            input.role,
            input.color,
        );
    }
}

export class TeamMembers {
    readonly all: TeamMemberFE[];
    readonly other: TeamMemberFE[];

    constructor(
        appUser: AppUserFE,
        teamMembers: TeamMemberFE[],
    ) {
        this.all = teamMembers;
        this.other = teamMembers.filter(member => member.appUserId !== appUser.id);
        this.map = new Map(teamMembers.map(member => [ member.appUserId, member ]));
        this.appUserId = appUser.id;
    }

    private readonly map: Map<string, TeamMemberFE>;
    private readonly appUserId: Id;

    getByAppUserId(id: Id): TeamMemberFE | undefined {
        return this.map.get(id);
    }

    getOtherByAppUserId(id: Id): TeamMemberFE | undefined {
        const found = this.getByAppUserId(id);
        return found?.appUserId === this.appUserId ? undefined : found;
    }
}

export type ForRole<
    TRequiredRole extends TeamMemberRole,
    TRole extends TeamMemberRole,
    TTrue,
    TFalse = undefined,
> = TRole extends TRequiredRole ? TTrue : TFalse;

export type ForScheduler<TRole extends TeamMemberRole, TData> = ForRole<typeof TeamMemberRole.scheduler, TRole, TData>;
export type ForMaster<TRole extends TeamMemberRole, TData> = ForRole<typeof TeamMemberRole.master, TRole, TData>;
export type ForFreelancer<TRole extends TeamMemberRole, TData> = ForRole<typeof TeamMemberRole.freelancer, TRole, TData>;

export type ExceptScheduler<TRole extends TeamMemberRole, TData> = ForRole<typeof TeamMemberRole.scheduler, TRole, undefined, TData>;
