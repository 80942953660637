import EventCell from './EventCell';
import { isSelected } from './utils/selection';

/* eslint-disable react/prop-types */
export default {
    // propTypes: {
    //   slotMetrics: PropTypes.object.isRequired,

    //   selected: PropTypes.object,
    //   isAllDay: PropTypes.bool,
    //   onSelect: PropTypes.func,
    //   onDoubleClick: PropTypes.func,
    //   onKeyPress: PropTypes.func,
    // },

    defaultProps: {
        segments: [],
        selected: {},
    },

    renderEvent(props, event) {
        let {
            selected,
            isAllDay: _,
            onSelect,
            onDoubleClick,
            onKeyPress,
            slotMetrics,
        } = props;

        let continuesPrior = slotMetrics.continuesPrior(event);
        let continuesAfter = slotMetrics.continuesAfter(event);

        return (
            <EventCell
                event={event}
                onSelect={onSelect}
                onDoubleClick={onDoubleClick}
                onKeyPress={onKeyPress}
                continuesPrior={continuesPrior}
                continuesAfter={continuesAfter}
                selected={isSelected(event, selected)}
            />
        );
    },

    renderSpan(slots, len, key, content = ' ') {
        let per = (Math.abs(len) / slots) * 100 + '%';

        return (
            <div
                key={key}
                className='rbc-row-segment'
                // IE10/11 need max-width. flex-basis doesn't respect box-sizing
                style={{ WebkitFlexBasis: per, flexBasis: per, maxWidth: per }}
            >
                {content}
            </div>
        );
    },
};
