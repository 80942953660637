import { useCallback, useMemo, useState } from 'react';
import { DefaultFilterItemBadge, type Filter, type FilterDefinition, type FilterFunction, type FilterItemBadgeProps, type FilterMenuProps } from './FilterRow';
import { Dropdown } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { type ClientInfoFE } from ':frontend/types/Client';
import { compareArrays } from ':frontend/utils/common';
import ClientSelect from ':frontend/components/client/ClientSelect';

export const filterName = 'client';

type FilterState = {
    all: ClientInfoFE[];
    selected: ClientInfoFE[];
};

function FilterToggleMenu({ state, setState }: FilterMenuProps<FilterState>) {
    return (
        <Dropdown.Item className='p-3 bg-white sh-design text-dark'>
            <InnerFilter state={state} setState={setState} />
        </Dropdown.Item>
    );
}

function FilterRowMenu({ state, setState }: FilterMenuProps<FilterState>) {
    return (
        <div className='sh-design compact text-dark' style={{ width: 300 }}>
            <InnerFilter state={state} setState={setState} />
        </div>
    );
}

function InnerFilter({ state, setState }: FilterMenuProps<FilterState>) {
    const availableClients = useMemo(() => state.all.filter(client => !state.selected.find(c => c.id === client.id)), [ state ]);
    const [ value, setValue ] = useState<ClientInfoFE>();

    const handleOnChange = useCallback((client?: ClientInfoFE) => {
        if (!client)
            return;

        setState({ ...state, selected: [ ...state.selected, client ] });
        setValue(undefined);
    }, [ state, setState ]);

    return (
        <ClientSelect
            value={value}
            clients={availableClients}
            onChange={handleOnChange}
        />
    );
}

function FilterItemBadge({ item, onClose }: FilterItemBadgeProps<ClientInfoFE>) {
    const { t } = useTranslation('common', { keyPrefix: `filters.${filterName}` });

    return (
        <DefaultFilterItemBadge item={item} onClose={onClose}>
            <Trans t={t} i18nKey='badge-label' components={{ sm: <span className='fw-medium' /> }} values={{ name: item.name }} />
        </DefaultFilterItemBadge>
    );
}

function remove(state: FilterState, item: ClientInfoFE): FilterState {
    return {
        ...state,
        selected: state.selected.filter(client => client.id !== item.id),
    };
}

function toItems(state: FilterState): ClientInfoFE[] {
    return state.selected;
}

function createFilterFunction(state: FilterState): FilterFunction<ClientInfoFE> {
    if (state.selected.length === 0)
        return () => true;

    const set = new Set<string>(state.selected.map(client => client.id));

    return (data: ClientInfoFE) => set.has(data.id);
}

function toServer(state: FilterState, previous: string[] | undefined): string[] {
    const current = state.selected.map(client => client.id).sort();
    return previous && compareArrays(current, previous)
        ? previous
        : current;
}

function createFilter(allClients: ClientInfoFE[]): FilterDefinition<ClientInfoFE, FilterState, ClientInfoFE> {
    return {
        name: filterName,
        defaultValues: {
            all: allClients,
            selected: [],
        },
        FilterToggleMenu,
        FilterRowMenu,
        FilterItemBadge,
        remove,
        toItems,
        createFilterFunction,
        toServer: toServer as (state: FilterState, previous: unknown | undefined) => string[],
    };
}

export default createFilter as (allClients: ClientInfoFE[]) => Filter;
