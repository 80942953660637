import { useCallback, useEffect } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import GeneralSettingsEditor from './GeneralSettingsEditor';
import LocationSettingsEditor from './LocationSettingsEditor';
import InvoicingProfilesEditor from './InvoicingProfilesEditor';
import UserPricingsEditor from './UserPricingsEditor';
import ChangePassword from './ChangePassword';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routesFE } from ':utils/routes';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import InvoicingProfileEditor from './InvoicingProfileEditor';
import { OnboardingFE } from ':frontend/types/Onboarding';
import { OnboardingFlag } from ':utils/entity/user';
import { trpc } from ':frontend/context/TrpcProvider';

export default function Settings() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings' });
    const { key } = useParams();
    const navigate = useNavigate();

    const userContext = useUser();
    const { appUser, onboarding, setOnboarding } = userContext;
    const masterContext = toMaster(userContext);
    const isMasterOrFreelancer = !!masterContext;

    const handleSelect = useCallback((newKey: string | null) => newKey && navigate(routesFE.settings.resolve({ key: newKey })), [ navigate ]);

    const updateOnboardingMutation = trpc.user.updateOnboarding.useMutation();

    const syncOnboarding = useCallback(() => {
        updateOnboardingMutation.mutate({ [OnboardingFlag.SettingsOpened]: true }, {
            onSuccess: response => {
                setOnboarding(OnboardingFE.fromServer(response));
            },
        });
    }, []);

    useEffect(() => {
        if (onboarding.flags.settingsOpened)
            return;

        syncOnboarding();
    }, []);

    return (
        <div className='d-flex flex-column h-100'>
            <Tab.Container
                activeKey={key}
                transition={false}
                onSelect={handleSelect}
            >
                <div className='border-1 border-bottom' style={{ backgroundColor: '#F5F5F5' }}>
                    <div className='container-small d-flex align-items-center justify-content-between gap-4'>
                        <Nav className='sh-tabs nav-tabs' as='ul'>
                            <Nav.Item as='li'><Nav.Link as='button' eventKey='general'>{t('general-tab')}</Nav.Link></Nav.Item>
                            {masterContext && (masterContext.profiles.length === 1 ? (<>
                                <Nav.Item as='li'><Nav.Link as='button' eventKey='invoicing'>{t('invoicing-general-tab')}</Nav.Link></Nav.Item>
                                <Nav.Item as='li'><Nav.Link as='button' eventKey='invoicing-custom'>{t('invoicing-custom-tab')}</Nav.Link></Nav.Item>
                            </>) : (
                                <Nav.Item as='li'><Nav.Link as='button' eventKey='invoicing'>{t('invoicing-profiles-tab')}</Nav.Link></Nav.Item>
                            ))}
                            {isMasterOrFreelancer && (
                                <Nav.Item as='li'><Nav.Link as='button' eventKey='pricing'>{t('pricings-tab')}</Nav.Link></Nav.Item>
                            )}
                            <Nav.Item as='li'><Nav.Link as='button' eventKey='locations'>{t('locations-tab')}</Nav.Link></Nav.Item>
                            {!appUser.google.isAuth && (
                                <Nav.Item as='li'><Nav.Link as='button' eventKey='change-password'>{t('change-password-tab')}</Nav.Link></Nav.Item>
                            )}
                        </Nav>
                    </div>
                </div>
                <div className='sh-main-scroller py-4'>
                    <Tab.Content className='container-small'>
                        <Tab.Pane eventKey='general' unmountOnExit>
                            <GeneralSettingsEditor />
                        </Tab.Pane>
                        {masterContext && (masterContext.profiles.length === 1 ? (<>
                            <Tab.Pane eventKey='invoicing' unmountOnExit>
                                <InvoicingProfileEditor type='general' />
                            </Tab.Pane>
                            <Tab.Pane eventKey='invoicing-custom' unmountOnExit>
                                <InvoicingProfileEditor type='custom' />
                            </Tab.Pane>
                        </>) : (
                            <Tab.Pane eventKey='invoicing' unmountOnExit>
                                <InvoicingProfilesEditor />
                            </Tab.Pane>
                        ))}
                        {isMasterOrFreelancer && (
                            <Tab.Pane eventKey='pricing' unmountOnExit>
                                <UserPricingsEditor />
                            </Tab.Pane>
                        )}
                        <Tab.Pane eventKey='locations' unmountOnExit>
                            <LocationSettingsEditor />
                        </Tab.Pane>
                        {!appUser.google.isAuth && (
                            <Tab.Pane eventKey='change-password' unmountOnExit>
                                <ChangePassword />
                            </Tab.Pane>
                        )}
                    </Tab.Content>
                </div>
            </Tab.Container>
        </div>
    );
}
