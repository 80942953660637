import { useToggle } from ':frontend/hooks';
import { useCallback, useState, type ChangeEvent } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { HexColorPicker } from 'react-colorful';
import { useTranslation } from 'react-i18next';

type ColorPickerProps = Readonly<{
    /** Hex code (a most 6 characters). Might be invalid (i.e., shorter), because the user is still typing. */
    color: string;
    onChange: (color: string) => void;
}>;

export function ColorPicker({ color, onChange }: ColorPickerProps) {
    const pickerColor = toValidHex(color);

    const innerOnChange = useCallback((input: string) => {
        onChange(input.replace('#', ''));
    }, [ onChange ]);

    const onTextChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const hex = toHex(e.target.value);
        onChange(hex);
    }, [ onChange ]);

    return (
        <div className='p-3 w-fit bg-white rounded-3' >
            <div className='' style={{ width: '200px' }}>
                <HexColorPicker
                    color={`#${pickerColor}`}
                    onChange={innerOnChange}
                />
                <Form.Control
                    value={color}
                    onChange={onTextChange}
                    className='mt-3'
                />
            </div>
        </div>
    );
}

function toHex(input: string): string {
    return input.replace(/[^0-9a-fA-F]/g, '').slice(0, 6);
}

/**
 * Returns the true color that will be used if the user decides to save the input.
 * We expect the input to be a valid hex, but we need to make sure it's 6 characters long.
 */
function toValidHex(rawHex: string): string {
    const hex = rawHex.toLowerCase();
    if (hex.length > 3)
        return hex.padEnd(6, '0');

    // If the hex is 3 characters long, it's the same as 6 characters where all characters are doubled.
    const pad3 = hex.padEnd(3, '0');
    return pad3[0] + pad3[0] + pad3[1] + pad3[1] + pad3[2] + pad3[2];
}

type ColorPickerModalProps = Readonly<{
    /** Hex code (6 characters). */
    color: string;
    onSave: (color: string) => void;
}>;

export function ColorPickerDropdown({ color, onSave }: ColorPickerModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'colorPicker' });
    const [ show, setShow ] = useToggle(false);
    const [ innerColor, setInnerColor ] = useState(color);
    const validColor = toValidHex(innerColor);

    function open() {
        setInnerColor(color);
        setShow.true();
    }

    function save() {
        onSave(validColor);
        setShow.false();
    }

    return (
        <Dropdown autoClose='outside' show={show} onToggle={setShow.false}>
            <button className='sh-icon-button' onClick={open}>
                <div style={{ width: '16px', height: '16px', backgroundColor: `#${color}` }} className='rounded-1 m-auto' />
            </button>
            <Dropdown.Menu className='p-0'>
                {show && (<>
                    <ColorPicker
                        color={innerColor}
                        onChange={setInnerColor}
                    />
                    <div className='pb-3 px-3 d-flex justify-content-between'>
                        <Button variant='outline-secondary' className='compact' onClick={setShow.false}>
                            {t('cancel-button')}
                        </Button>
                        <Button className='compact' disabled={validColor === color} onClick={save}>
                            {t('save-button')}
                        </Button>
                    </div>
                </>)}
            </Dropdown.Menu>
        </Dropdown>
    );
}
