import { z } from 'zod';
import { zId } from ':utils/id';
import { zLocaleCode, zTimezoneCode } from ':utils/i18n';
import { zCanonizedEmail } from ':utils/forms';

const zGoogleIntegrationOutput = z.object({
    calendar: z.object({
        calendarId: z.string(),
        datePastEvents: z.string(),
    }).optional(),
    contacts: z.boolean().optional(),
});

export type AppUserOutput = z.infer<typeof zAppUserOutput>;
export const zAppUserOutput = z.object({
    id: zId,
    firstName: z.string(),
    lastName: z.string().optional(),
    email: z.string(),
    phoneNumber: z.string().optional(),
    google: zGoogleIntegrationOutput.optional(),
});

type AppUserName = { firstName: string, lastName?: string };

export function getAppUserName({ firstName, lastName }: AppUserName): string {
    return lastName ? `${firstName} ${lastName}` : firstName;
}

export function getAppUserShortName({ firstName, lastName }: AppUserName): string {
    if (!lastName)
        return firstName;

    const dot = lastName.length > 1 ? '.' : '';
    return `${firstName} ${lastName[0]}${dot}`;
}

export type AppUserLocalization = z.infer<typeof zAppUserLocalization>;
export const zAppUserLocalization = z.object({
    timezone: zTimezoneCode,
    locale: zLocaleCode,
});

export type AppUserInit = z.infer<typeof zAppUserInit>;
export const zAppUserInit = zAppUserLocalization.extend({
    email: zCanonizedEmail,
    password: z.string().min(8).max(255),
    firstName: z.string(),
    lastName: z.string().optional(),
});

export enum SubscriptionPhase {
    trialClaimed = 'trialClaimed',
    finished = 'finished',
}

/** Like PUT. */
export type AppUserEdit = z.infer<typeof zAppUserEdit>;
export const zAppUserEdit = z.object({
    firstName: z.string(),
    lastName: z.string(),
    phoneNumber: z.string().optional(),
});

export enum OnboardingFlag {
    ClientsCreated = 'clientsCreated',
    StripeOrBankAccount = 'stripeOrBankAccount',
    SettingsOpened = 'settingsOpened',
    ProductCreatedOrUpdated = 'productCreatedOrUpdated',
}

export type OnboardingStateEdit = z.infer<typeof zOnboardingStateEdit>;
export const zOnboardingStateEdit = z.object({
    [OnboardingFlag.SettingsOpened]: z.boolean(),
});
