import { useCallback, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Row, Col, Button, Modal, Form, InputGroup } from 'react-bootstrap';
import { MoreButtonDropdown, SpinnerButton } from ':frontend/components/common';
import { DEFAULT_PREFIX, OrderFE, canTransition, type OrderTransitionFE } from ':frontend/types/orders/Order';
import OrderStateBadge from ':frontend/components/orders/OrderStateBadge';
import BasicItemsTable, { BasicItemsForm } from ':frontend/components/orders/BasicItemsTable';
import EventItemsTable, { EventItemsForm } from ':frontend/components/orders/EventItemsTable';
import { Trans, useTranslation } from 'react-i18next';
import useNotifications from ':frontend/context/NotificationProvider';
import { createErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import { INDEX_DISPLAY_DIGITS, InvoiceNumberDisplay, getInvoiceNumber } from ':frontend/components/orders/invoiceNumber';
import { MdModeEdit } from 'react-icons/md';
import { CheckIcon, CircleSlashIcon, CloseIcon, DownloadIcon, EyeIcon, InvoiceIcon, ReceiptIcon, SendIcon, TrashIcon } from ':frontend/components/icons';
import ClientIconLink from ':frontend/components/client/ClientIconLink';
import DateTimeDisplay from ':frontend/components/common/DateTimeDisplay';
import EditableTextInput from ':frontend/components/forms/EditableTextInput';
import { createActionState, useCached, useToggle } from ':frontend/hooks';
import TextareaAutosize from 'react-textarea-autosize';
import { type LogFE } from ':frontend/types/Log';
import { useOrder, type OrderFormState, type UseOrderDispatch, type UseOrderState, computeFullPrefix, type OrderFormWithInvoice, hasInvoice } from ':frontend/components/orders/useOrder';
import { type TFunction } from 'i18next';
import { CountrySelect, DatePicker } from ':frontend/components/forms';
import { transformToPositiveIntegerOrZero } from ':frontend/utils/math';
import { type DateTime } from 'luxon';
import clsx from 'clsx';
import { HEADER_FOOTER_MAX_LENGTH, MaxLengthText } from ':frontend/components/settings/PersonalizationForm';
import { TranslatedErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { SpoilerButtonRow } from ':frontend/components/common/Collapse';
import { routesBE, routesFE } from ':utils/routes';
import type { PreselectCustom } from './NewCustomOrder';
import { CondensedInvoiceToggle } from ':frontend/components/forms/CondensedInvoiceToggle';
import { type EmailPreviewState } from ':frontend/components/orders/checkout/useCheckout';
import { EmailPreviewForm } from ':frontend/components/orders/checkout/EmailPreview';
import { trpc } from ':frontend/context/TrpcProvider';
import { isStripeOrder, OrderTransition } from ':utils/entity/order';

// TODO unify the initial fetching with the rest of the hook? Or not?
export default function OrderDetail() {
    const { id } = useParams();

    const orderOutput = trpc.order.getOrder.useQuery({ id: id! });

    const order = useMemo(() => {
        if (!orderOutput.data)
            return;

        return OrderFE.fromServer(orderOutput.data);
    }, [ orderOutput.data ]);

    if (!order)
        return null;

    return (
        <OrderDetailFetched order={order} />
    );
}

type OrderDetailFetchedProps = Readonly<{
    order: OrderFE;
}>;

function OrderDetailFetched({ order }: OrderDetailFetchedProps) {
    const { state, dispatch } = useOrder(order);

    return (
        <OrderDetailInner state={state} dispatch={dispatch} />
    );
}

type StateDispatchProps = Readonly<{
    state: UseOrderState;
    dispatch: UseOrderDispatch;
}>;

function OrderDetailInner({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'orderDetail' });
    const { addAlert } = useNotifications();

    const updateOrderMutation = trpc.order.updateOrder.useMutation();
    // TODO move to reducer
    const syncTitle = useCallback(async (newTitle: string) => {
        if (newTitle === '')
            return false;

        try {
            const response = await updateOrderMutation.mutateAsync({ id: state.order.id, title: newTitle });
            dispatch({ type: 'reset', order: OrderFE.fromServer(response) });
            addAlert(createTranslatedSuccessAlert('pages:orderDetail.editTitleSuccessAlert'));

            return true;
        }
        catch {
            addAlert(createErrorAlert(updateOrderMutation.error));

            return false;
        }
    }, [ state.order.id, addAlert, dispatch, updateOrderMutation ]);

    return (
        <div className='d-flex flex-column h-100'>
            <div className='container-large py-3 d-flex align-items-center gap-col-2'>
                <div className='flex-grow-1 overflow-hidden'>
                    <EditableTextInput
                        className='d-flex align-items-center gap-3 sh-reset-form-control fs-4 mb-2'
                        viewClassName='text-truncate'
                        value={state.order.title}
                        syncFunction={syncTitle}
                        disabled={!!state.form}
                    />
                    <div className='d-flex align-items-center gap-3'>
                        {state.order.invoice && <InvoiceNumberDisplay invoice={state.order.invoice} />}
                        <OrderPaymentMethodDisplay order={state.order} />
                    </div>
                </div>
                {!state.form && (<>
                    <TransitionOrderButtons state={state} dispatch={dispatch} />
                    {state.order.invoice && (<>
                        <SendNotificationButton state={state} dispatch={dispatch} />
                        <a href={routesBE.public.invoice.resolve({ id: state.order.id })} target='_blank' rel='noreferrer' className='text-decoration-none'>
                            <Button variant='ghost-secondary' className='square'>
                                <EyeIcon size={22} />
                                {t('view-invoice-button')}
                            </Button>
                        </a>
                    </>)}
                    <DuplicateOrderButton order={state.order} />
                </>)}
                {editOrderButtons(state, dispatch, t)}
                {!state.form && (
                    <MoreButtonDropdown>
                        <DeleteOrderButton state={state} dispatch={dispatch} />
                        {state.order.invoice && (
                            <a href={routesBE.public.invoice.resolve({ id: state.order.id })} download={getInvoiceNumber(state.order.invoice) + '.pdf'} className='text-decoration-none'>
                                <Button variant='ghost-secondary' className='square'>
                                    <DownloadIcon size={22} />
                                    {t('download-invoice-button')}
                                </Button>
                            </a>
                        )}
                    </MoreButtonDropdown>
                )}
            </div>
            <div className='border-1 border-bottom border-top' style={{ backgroundColor: '#F5F5F5' }}>
                <div className='container-large d-flex align-items-center gap-4 py-3'>
                    <OrderStateBadge order={state.order} />
                    <ClientIconLink client={state.order.client} />
                </div>
            </div>
            <div className='sh-main-scroller'>
                <div className='container-large py-4 d-flex flex-column gap-4'>
                    {state.form ? (<>
                        {state.order.invoice && (
                            <OrderForm state={state} dispatch={dispatch} />
                        )}
                        {state.order.getEventItems().length > 0 && (
                            <EventItemsForm state={state} form={state.form} dispatch={dispatch} />
                        )}
                        {state.order.getBasicItems().length > 0 && (
                            <BasicItemsForm state={state} form={state.form} dispatch={dispatch} />
                        )}
                    </>) : (<>
                        {state.order.invoice && (
                            <OrderDisplay state={state} dispatch={dispatch} />
                        )}
                        {state.order.getEventItems().length > 0 && (
                            <EventItemsTable items={state.order.getEventItems()} />
                        )}
                        {state.order.getBasicItems().length > 0 && (
                            <BasicItemsTable items={state.order.getBasicItems()} />
                        )}
                        {state.order.invoice && !!state.order.logs.length && (
                            <OrderLogsList order={state.order} />
                        )}
                    </>)}
                </div>
            </div>
        </div>
    );
}

type OrderLogsListProps = Readonly<{
    order: OrderFE;
}>;

function OrderLogsList({ order }: OrderLogsListProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'orderDetail' });

    return (
        <div className='d-flex flex-column gap-2'>
            <h2 className='m-0 mb-2'>{t('logs-title')}</h2>
            {order.logs.map(log => (
                <OrderLogsRow key={log.id} log={log} />
            ))}
        </div>
    );
}

type OrderLogsRowProps = Readonly<{
    log: LogFE;
}>;

function OrderLogsRow({ log }: OrderLogsRowProps) {
    const { t } = useTranslation('components', { keyPrefix: 'historyLogs' });

    return (
        <div className='d-flex gap-3'>
            <span className='text-secondary'><DateTimeDisplay dateTime={log.date} /></span>
            <div>
                <Trans
                    t={t}
                    i18nKey={log.translationId}
                    values={log.data}
                    components={{
                        b: <span className='fw-semibold' />,
                    }}
                />
            </div>
        </div>
    );
}

function TransitionOrderButtons({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'orderDetail' });
    const transitionOrder = useCallback((transition: OrderTransitionFE) => dispatch({ type: 'sync', operation: 'transition', transition, fid: transition }), [ dispatch ]);
    if (isStripeOrder(state.order))
        return null;

    return (<>
        {canTransition(state.order, OrderTransition.Fulfill) &&
            <SpinnerButton
                variant='ghost-secondary'
                className='square'
                onClick={() => transitionOrder(OrderTransition.Fulfill)}
                fetching={state.sync?.fetching}
                fid={OrderTransition.Fulfill}
            >
                <ReceiptIcon size={22} />
                {t('fulfill-order-button')}
            </SpinnerButton>
        }
        {canTransition(state.order, OrderTransition.Unfulfill) &&
            <SpinnerButton
                variant='ghost-secondary'
                className='square'
                onClick={() => transitionOrder(OrderTransition.Unfulfill)}
                fetching={state.sync?.fetching}
                fid={OrderTransition.Unfulfill}
            >
                <CircleSlashIcon size={22} />
                {t('unfulfill-order-button')}
            </SpinnerButton>
        }
    </>);
}

function SendNotificationButton({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'orderDetail.sendNotificationButton' });
    const { sendNotification, order, sync } = state;
    const cached = useCached(sendNotification);
    const isFetching = !!sync?.fetching;
    const className = cached?.phase === 'emailPreview' ? 'sh-send-notification-email-preview-modal' : 'sh-send-notification-modal';

    return (<>
        <Modal show={!!sendNotification} onHide={() => dispatch({ type: 'sendNotification', operation: 'close' })} className={className}>
            {cached && (<>
                {cached.phase === 'emailPreview' ? (
                    <EmailPreview emailPreview={cached.emailPreview} dispatch={dispatch} />
                ) : (<>
                    <div className='header'>{t('modal-title')}</div>
                    <div className='body'>
                        <div>
                            {t(`modal-text${order.isNotificationSent ? '-again' : ''}`)}
                            <Button
                                variant='outline-secondary'
                                className='compact mt-2'
                                onClick={() => dispatch({ type: 'sendNotification', operation: 'emailPreview' })}
                            >
                                <SendIcon size={18} className='me-2' />
                                {t('preview-email-button')}
                            </Button>
                        </div>
                        <SpinnerButton
                            onClick={() => dispatch({ type: 'sync', operation: 'sendNotification', fid: FID_SEND_NOTIFICATION })}
                            fetching={state.sync?.fetching}
                            fid={FID_SEND_NOTIFICATION}
                        >
                            {t(`send${order.isNotificationSent ? '-again' : ''}-button`)}
                        </SpinnerButton>
                        <Button
                            variant='outline-secondary'
                            onClick={() => dispatch({ type: 'sendNotification', operation: 'close' })}
                            disabled={isFetching}
                        >
                            {t('close-button')}
                        </Button>
                    </div>
                </>)}
            </>)}
        </Modal>
        <Button
            variant='ghost-secondary'
            className='square'
            onClick={() => dispatch({ type: 'sendNotification', operation: 'open' })}
        >
            {order.isNotificationSent ? (<>
                <CheckIcon size={22} />
                {t('open-modal-again-button')}
            </>) : (<>
                <SendIcon size={22} />
                {t('open-modal-button')}
            </>)}
        </Button>
    </>);
}

const FID_SEND_NOTIFICATION = 'send-notification';

type EmailPreviewProps = Readonly<{
    emailPreview: EmailPreviewState;
    dispatch: UseOrderDispatch;
}>;

function EmailPreview({ emailPreview, dispatch }: EmailPreviewProps) {
    const { t } = useTranslation('components', { keyPrefix: 'checkout.emailPreview' });
    const isChanged = emailPreview.isChanged;

    return (
        <div className='sh-checkout-email-preview sh-design compact-imputs' style={{ padding: '20px' }}>
            <h2 className='m-0'>{t('title')}</h2>
            <EmailPreviewForm state={emailPreview} dispatch={dispatch} />
            <div className='d-flex justify-content-end gap-2 mt-3'>
                {isChanged && (
                    <Button variant='outline-secondary' onClick={() => dispatch({ type: 'emailPreview', operation: 'reset' })}>
                        {t('reset-button')}
                    </Button>
                )}
                <Button onClick={() => dispatch({ type: 'sendNotification', operation: 'overview' })}>
                    {t('back-button')}
                </Button>
            </div>
        </div>
    );
}

function editOrderButtons(state: UseOrderState, dispatch: UseOrderDispatch, t: TFunction) {
    if (!state.form) {
        return (
            <Button
                variant='ghost-secondary'
                className='square'
                onClick={() => dispatch({ type: 'form', operation: 'edit' })}
            >
                <MdModeEdit size={22} />
                {t('edit-form-button')}
            </Button>
        );
    }

    return (<>
        <SpinnerButton
            variant='primary'
            className='square'
            onClick={() => dispatch({ type: 'sync', operation: 'save', fid: FID_SAVE })}
            fetching={state.sync?.fetching}
            fid={FID_SAVE}
            disabled={!!state.formErrors}
        >
            <TrashIcon size={22} />
            {t('save-form-button')}
        </SpinnerButton>
        <Button
            variant='ghost-danger'
            className='square'
            onClick={() => dispatch({ type: 'form', operation: 'discard' })}
        >
            <CloseIcon size={22} />
            {t('discard-form-button')}
        </Button>
    </>);
}

const FID_SAVE = 'save';

function DeleteOrderButton({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'orderDetail.deleteOrderButton' });
    const [ showModal, setShowModal ] = useToggle(false);
    const isFetching = !!state.sync?.fetching;

    return (<>
        <Modal show={showModal} onHide={() => !isFetching && setShowModal.false()}>
            <Modal.Header closeButton>
                <Modal.Title>{t('modal-title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='text-center'>
                {t('modal-text')}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='outline-danger'
                    className='compact'
                    onClick={setShowModal.false}
                    disabled={isFetching}
                >
                    {t('close-modal-button')}
                </Button>
                <SpinnerButton
                    className='compact ms-3'
                    onClick={() => dispatch({ type: 'sync', operation: 'delete', fid: FID_DELETE })}
                    fetching={state.sync?.fetching}
                    fid={FID_DELETE}
                >
                    {t('confirm-modal-button')}
                </SpinnerButton>
            </Modal.Footer>
        </Modal>
        <Button
            variant='ghost-danger'
            className='square'
            onClick={setShowModal.true}
        >
            <TrashIcon size={22} />
            {t('open-modal-button')}
        </Button>
    </>);
}

const FID_DELETE = 'delete';

function DuplicateOrderButton({ order }: Readonly<{ order: OrderFE }>) {
    const { t } = useTranslation('pages', { keyPrefix: 'orderDetail' });

    const isEvents = useMemo(() => order.getEventItems().length > 0, [ order ]);
    if (isEvents)
        return null;

    return (
        <Link
            to={routesFE.orders.newCustom}
            state={createActionState<PreselectCustom>('preselectCustom', { orderId: order.id })}
            className='text-decoration-none'
        >
            <Button
                variant='ghost-secondary'
                className='square'
            >
                <InvoiceIcon size={22} />
                {t('duplicate-order-button')}
            </Button>
        </Link>
    );
}

function OrderDisplay({ state }: StateDispatchProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'orderDetail' });
    const { order } = state;

    const showDetails = !!(
        order.supplier.cin
        ?? order.supplier.tin
        ?? order.subscriber.cin
        ?? order.subscriber.tin
        ?? order.fields.customKey1
        ?? order.fields.customKey2
    );

    const showTaxDate = useMemo(() => state.order.items.some(i => !i.vat.isZero), [ state.order.items ]);

    return (
        <div className='d-flex flex-column'>
            <Row className='gx-90 fw-medium'>
                <Col>
                    <h3 className='text-muted'>{t('supplier-label')}</h3>
                    {addressDisplay(state, 'supplier')}
                </Col>
                <Col className='border-start border-end'>
                    <h3 className='text-muted'>{t('middle-column-label')}</h3>
                    <div className='d-flex flex-column gap-2'>
                        <div className='d-flex justify-content-between'>
                            <span className='text-muted'>{t('issue-date-label')}</span>
                            <DateTimeDisplay dateTime={order.issueDate} date />
                        </div>
                        <div className='d-flex justify-content-between'>
                            <span className='text-muted'>{t('due-date-label')}</span>
                            <DateTimeDisplay dateTime={order.dueDate} date />
                        </div>
                        {showTaxDate && (
                            <div className='d-flex justify-content-between'>
                                <span className='text-muted'>{t('tax-date-label')}</span>
                                <DateTimeDisplay dateTime={order.taxDate} date />
                            </div>
                        )}
                        {!isStripeOrder(order) && order.invoice && (
                            <div className='d-flex justify-content-between'>
                                <span className='text-muted'>{t('variable-symbol-label')}</span>
                                <span>{order.invoice.variableSymbol}</span>
                            </div>
                        )}
                    </div>
                </Col>
                <Col>
                    <h3 className='text-muted'>{t('subscriber-label')}</h3>
                    {addressDisplay(state, 'subscriber')}
                </Col>
            </Row>
            {showDetails && (
                <Row className='gx-90 fw-medium'>
                    <Col className='pt-3'>{detailsDisplay(state, 'supplier', t)}</Col>
                    <Col className='border-start border-end' />
                    <Col className='pt-3'>{detailsDisplay(state, 'subscriber', t)}</Col>
                </Row>
            )}
        </div>
    );
}

function addressDisplay(state: UseOrderState, type: 'supplier' | 'subscriber') {
    const identity = state.order[type];
    const address = identity.address;

    return (
        <div className='d-flex flex-column gap-1'>
            <div>{identity.name}</div>
            <div>{identity.email}</div>
            {address?.line1 && (
                <div>{address?.line1}</div>
            )}
            {(address?.postalCode || address?.city) && (
                <div>{address?.postalCode}{address?.postalCode && ', '}{address?.city}</div>
            )}
            {address?.country && (
                <div>{address?.country}</div>
            )}
        </div>
    );
}

function detailsDisplay(state: UseOrderState, type: 'supplier' | 'subscriber', t: TFunction) {
    const identity = state.order[type];
    const fields = state.order.fields;

    return (
        <div className='d-flex flex-column gap-1'>
            {identity.cin && (
                <div className='d-flex justify-content-between'>
                    <span className='text-muted'>{t('cin-label')}</span>
                    <span>{identity.cin}</span>
                </div>
            )}
            {identity.tin && (
                <div className='d-flex justify-content-between'>
                    <span className='text-muted'>{t('tin-label')}</span>
                    <span>{identity.tin}</span>
                </div>
            )}
            {type === 'supplier' && fields.customKey1 && (
                <div className='d-flex justify-content-between'>
                    <span className='text-muted'>{fields.customKey1}</span>
                    <span>{fields.customValue1}</span>
                </div>
            )}
            {type === 'supplier' && fields.customKey2 && (
                <div className='d-flex justify-content-between'>
                    <span className='text-muted'>{fields.customKey2}</span>
                    <span>{fields.customValue2}</span>
                </div>
            )}
        </div>
    );
}

function OrderForm({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'orderDetail' });
    const [ isShowMore, setIsShowMore ] = useState(true);
    const { order, form } = state;

    const dispatchDate = useMemo(() => ({
        issue: (value?: DateTime) => value && dispatch({ type: 'input', field: 'issueDate', value }),
        due: (value?: DateTime) => value && dispatch({ type: 'input', field: 'dueDate', value }),
        tax: (value?: DateTime) => value && dispatch({ type: 'input', field: 'taxDate', value }),
    }), [ dispatch ]);

    // TODO use form items instead
    const showTaxDate = useMemo(() => state.order.items.some(i => !i.vat.isZero), [ state.order.items ]);

    if (!form)
        return null;

    return (
        <div className='d-flex flex-column gap-3'>
            <Row className='gx-90 fw-medium'>
                <Col>
                    <h3 className='text-muted'>{t('supplier-label')}</h3>
                    {identityForm(state, dispatch, 'supplier', t)}
                </Col>
                <Col>
                    <h3 className='text-muted'>{t('middle-column-label')}</h3>
                    <div className='d-flex flex-column gap-2'>
                        {hasInvoice(form) && (
                            <Row className='align-items-center gx-2'>
                                <Col className='text-muted'>{t('number-label')}</Col>
                                {invoiceNumberInput(form, state, dispatch)}
                            </Row>
                        )}
                        <Row className='align-items-center input-text-end'>
                            <Col className='text-muted'>{t('issue-date-label')}</Col>
                            <Col><DatePicker selected={form.issueDate} onChange={dispatchDate.issue} type='date' /></Col>
                        </Row>
                        <Row className='align-items-center input-text-end'>
                            <Col className='text-muted'>{t('due-date-label')}</Col>
                            <Col><DatePicker selected={form.dueDate} onChange={dispatchDate.due} type='date' /></Col>
                        </Row>
                        {showTaxDate && (
                            <Row className='align-items-center input-text-end'>
                                <Col className='text-muted'>{t('tax-date-label')}</Col>
                                <Col><DatePicker selected={form.taxDate} onChange={dispatchDate.tax} type='date' /></Col>
                            </Row>
                        )}
                        {!isStripeOrder(order) && hasInvoice(form) && (<>
                            <Row className='align-items-center input-text-end'>
                                <Col className='text-muted'>{t('variable-symbol-label')}</Col>
                                <Col>
                                    <Form.Control
                                        className='monospace-numbers'
                                        value={form.invoice.variableSymbol}
                                        onChange={event => dispatch({ type: 'input', field: 'invoice.variableSymbol', value: event.target.value })}
                                    />
                                </Col>
                            </Row>
                            {form.invoice.variableSymbol !== form.invoice.generatedVariableSymbol && (
                                <Row>
                                    <Col />
                                    <Col>
                                        <Button
                                            variant='outline-secondary' className='compact w-100'
                                            onClick={() => dispatch({ type: 'form', operation: 'generateVariableSymbol' })}
                                        >
                                            {t('generate-variable-symbol-button')}
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        </>)}
                    </div>
                </Col>
                <Col>
                    <h3 className='text-muted'>{t('subscriber-label')}</h3>
                    {identityForm(state, dispatch, 'subscriber', t)}
                </Col>
            </Row>
            <SpoilerButtonRow title={t('toggle-details-button')} isShow={isShowMore} setIsShow={setIsShowMore} />
            {isShowMore && detailsForm(form, dispatch, t)}
        </div>
    );
}

function invoiceNumberInput(form: OrderFormWithInvoice, state: UseOrderState, dispatch: UseOrderDispatch) {
    const isChangeable = state.masterContext.subscription.isLogoChangeable;

    function updateIndex(value: string) {
        const parsed = transformToPositiveIntegerOrZero(value);
        if (parsed.toString().length > INDEX_DISPLAY_DIGITS)
            return;

        dispatch({ type: 'input', field: 'invoice.index', value: parsed });
    }

    const fullPrefix = computeFullPrefix(form, state.masterContext);
    const error = state.error?.numberAlreadyUsed;
    const displayError = error?.prefix === fullPrefix && error?.index === form.invoice.index;

    return (<>
        <Col xs={!isChangeable ? 6 : 5} className='d-flex align-items-center gap-2 monospace-numbers'>
            <InputGroup>
                {!isChangeable && (
                    <InputGroup.Text className='monospace-numbers'>
                        {DEFAULT_PREFIX}
                    </InputGroup.Text>
                )}
                <Form.Control
                    className='monospace-numbers'
                    value={form.invoice.formPrefix}
                    onChange={event => dispatch({ type: 'input', field: 'invoice.formPrefix', value: event.target.value })}
                />
            </InputGroup>
        </Col>
        -
        <Col xs={3} className='input-text-end'>
            <Form.Control
                className='monospace-numbers'
                value={form.invoice.index}
                onChange={event => updateIndex(event.target.value)}
            />
        </Col>
        {displayError && (
            <TranslatedErrorMessage translationId={'common:form.invoice-number-already-used'} />
        )}
    </>);
}

function identityForm(state: UseOrderState, dispatch: UseOrderDispatch, type: 'supplier' | 'subscriber', t: TFunction) {
    const form = state.form;
    if (!form)
        return null;

    const identity = form[type];

    return (
        <div className='sh-invoicing-identity-form d-flex flex-column gap-2'>
            <Form.Group>
                <Form.Label>{t('name-company-label')}</Form.Label>
                <Form.Control
                    value={identity.name}
                    onChange={e => dispatch({ type: 'input', field: `${type}.name`, value: e.target.value })}
                    placeholder={t('name-company-label')}
                />
                <TranslatedErrorMessage translationId={state.formErrors?.[`${type}.name`]} />
            </Form.Group>
            <Form.Group>
                <Form.Label>{t('email-label')}</Form.Label>
                <Form.Control
                    value={identity.email}
                    onChange={e => dispatch({ type: 'input', field: `${type}.email`, value: e.target.value })}
                    placeholder={t('email-label')}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>{t('city-label')}</Form.Label>
                <Form.Control
                    value={identity.address.city}
                    onChange={e => dispatch({ type: 'input', field: `${type}.address.city`, value: e.target.value })}
                    placeholder={t('city-label')}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>{t('line1-label')}</Form.Label>
                <Form.Control
                    value={identity.address.line1}
                    onChange={e => dispatch({ type: 'input', field: `${type}.address.line1`, value: e.target.value })}
                    placeholder={t('line1-label')}
                />
            </Form.Group>
            <Row className='gx-2'>
                <Form.Group as={Col} xs={4}>
                    <Form.Label>{t('postal-code-label')}</Form.Label>
                    <Form.Control
                        value={identity.address.postalCode}
                        onChange={e => dispatch({ type: 'input', field: `${type}.address.postalCode`, value: e.target.value })}
                        placeholder={t('postal-code-label')}
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{t('country-label')}</Form.Label>
                    <CountrySelect
                        value={identity.address.country}
                        onChange={value => dispatch({ type: 'input', field: `${type}.address.country`, value })}
                        placeholder={t('country-label')}
                        isClearable
                    />
                </Form.Group>
            </Row>
        </div>
    );
}

function detailsForm(form: OrderFormState, dispatch: UseOrderDispatch, t: TFunction) {
    return (
        <div>
            <Row className='gx-90 fw-medium sh-invoicing-identity-form'>
                <Col>{identityDetailsForm(form, dispatch, 'supplier', t)}</Col>
                <Col>
                    <Row className='align-items-center input-text-end'>
                        <Col className='text-muted'>{t('condensed-invoice-label')}</Col>
                        <Col>
                            <CondensedInvoiceToggle
                                value={form.isCondensedInvoice}
                                onChange={value => dispatch({ type: 'input', field: 'isCondensedInvoice', value })}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col>{identityDetailsForm(form, dispatch, 'subscriber', t)}</Col>
            </Row>
            <Form.Group className='sh-design mt-4'>
                <Form.Label>{t('header-label')}</Form.Label>
                <Form.Control
                    placeholder={t('header-label')}
                    as={TextareaAutosize}
                    minRows={2}
                    value={form.customFields.header}
                    onChange={e => dispatch({ type: 'input', field: 'customFields.header', value: e.target.value })}
                    aria-describedby='header-textarea'
                />
                <MaxLengthText length={form.customFields.header?.length ?? 0} maxLength={HEADER_FOOTER_MAX_LENGTH} id='header-textarea' />
            </Form.Group>
            <Form.Group className='sh-design mt-3'>
                <Form.Label>{t('footer-label')}</Form.Label>
                <Form.Control
                    placeholder={t('footer-label')}
                    as={TextareaAutosize}
                    minRows={2}
                    value={form.customFields.footer}
                    onChange={e => dispatch({ type: 'input', field: 'customFields.footer', value: e.target.value })}
                    aria-describedby='footer-textarea'
                />
                <MaxLengthText length={form.customFields.footer?.length ?? 0} maxLength={HEADER_FOOTER_MAX_LENGTH} id='footer-textarea' />
            </Form.Group>
        </div>
    );
}

function identityDetailsForm(form: OrderFormState, dispatch: UseOrderDispatch, type: 'supplier' | 'subscriber', t: TFunction) {
    const identity = form[type];

    return (
        <div className='sh-invoicing-identity-form d-flex flex-column gap-2'>
            <Row className='gx-2'>
                <Form.Group as={Col}>
                    <Form.Label>{t('cin-label')}</Form.Label>
                    <Form.Control
                        value={identity.cin}
                        onChange={e => dispatch({ type: 'input', field: `${type}.cin`, value: e.target.value })}
                        placeholder={t('cin-label')}
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{t('tin-label')}</Form.Label>
                    <Form.Control
                        value={identity.tin}
                        onChange={e => dispatch({ type: 'input', field: `${type}.tin`, value: e.target.value })}
                        placeholder={t('tin-label')}
                    />
                </Form.Group>
            </Row>
            {type === 'supplier' && (<>
                <Row className='gx-2'>
                    <Form.Group as={Col}>
                        <Form.Label>{t('custom-key-1-label')}</Form.Label>
                        <Form.Control
                            value={form.customFields.customKey1}
                            onChange={e => dispatch({ type: 'input', field: `customFields.customKey1`, value: e.target.value })}
                            placeholder={t('custom-key-1-label')}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{t('custom-value-1-label')}</Form.Label>
                        <Form.Control
                            value={form.customFields.customValue1}
                            onChange={e => dispatch({ type: 'input', field: `customFields.customValue1`, value: e.target.value })}
                            placeholder={t('custom-value-1-label')}
                        />
                    </Form.Group>
                </Row>
                <Row className='gx-2'>
                    <Form.Group as={Col}>
                        <Form.Label>{t('custom-key-2-label')}</Form.Label>
                        <Form.Control
                            value={form.customFields.customKey2}
                            onChange={e => dispatch({ type: 'input', field: `customFields.customKey2`, value: e.target.value })}
                            placeholder={t('custom-key-2-label')}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{t('custom-value-2-label')}</Form.Label>
                        <Form.Control
                            value={form.customFields.customValue2}
                            onChange={e => dispatch({ type: 'input', field: `customFields.customValue2`, value: e.target.value })}
                            placeholder={t('custom-value-2-label')}
                        />
                    </Form.Group>
                </Row>
            </>)}
        </div>
    );
}

type OrderPaymentMethodDisplayProps = Readonly<{
    order: OrderFE;
    className?: string;
}>;

function OrderPaymentMethodDisplay({ order, className }: OrderPaymentMethodDisplayProps) {
    const { t } = useTranslation('components', { keyPrefix: 'paymentMethodSelect' });

    return (
        <div className={clsx('sh-order-payment-method', className)}>
            {t(`${order.paymentMethod}-title`)}
        </div>
    );
}
