import { useEditing, type SyncFunction, EditingPhase } from ':frontend/hooks';
import { Form, Spinner } from 'react-bootstrap';
import { MdModeEdit } from 'react-icons/md';
import clsx from 'clsx';

type EditableTextInputProps = Readonly<{
    value: string;
    syncFunction: SyncFunction<string>;
    className?: string;
    viewClassName?: string;
    iconSize?: number;
    disabled?: boolean;
}>;

export default function EditableTextInput({ value, syncFunction, className, viewClassName, iconSize, disabled }: EditableTextInputProps) {
    const { state: { value: innerValue, phase }, setValue, doUpdate, setEditing } = useEditing(value, syncFunction);

    return (
        <div className={className}>
            {phase === EditingPhase.View && (<>
                <span className={viewClassName}>{innerValue}</span>
                {!disabled && (
                    <MdModeEdit size={iconSize} className='sh-editing-icon clickable' role='button' onClick={setEditing} />
                )}
            </>)}
            {phase === EditingPhase.Editing && (
                <Form.Control
                    autoFocus
                    value={innerValue}
                    onKeyDown={event => event.key === 'Enter' && doUpdate()}
                    onChange={e => setValue(e.target.value)}
                    onBlur={() => doUpdate()}
                />
            )}
            {phase === EditingPhase.Updating && (<>
                <span className={clsx('text-muted', viewClassName)}>{innerValue}</span>
                <Spinner size='sm' variant='secondary' animation='border' className='sh-updating-spinner' />
            </>)}
        </div>
    );
}
