import { type Id, type CurrencyId } from ':utils/id';
import { floatToPercent, priceFromServer } from ':frontend/utils/math';
import type { CurrencyOutput, TaxRateOutput } from ':utils/entity/money';
import type { CountryCode, LocaleCode } from ':utils/i18n';

/**
 * Should be singleton (for each currency id).
 */
export class CurrencyFE  {
    private constructor(
        readonly id: CurrencyId,
        readonly label: string,
        readonly minimalAmount: number,
    ) {}

    displayFull(amount: number, locale: LocaleCode, compact?: boolean): string {
        return Intl.NumberFormat(locale, {
            style: 'currency',
            currency: this.id,
            currencyDisplay: 'narrowSymbol',
            minimumFractionDigits: compact ? 0 : undefined,
        }).format(amount);
    }

    displaySymbol(locale: LocaleCode): string {
        return Intl.NumberFormat(locale, {
            style: 'currency',
            currency: this.id,
            currencyDisplay: 'narrowSymbol',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(0).replace(/\d/g, '').trim();
    }

    isSymbolBefore(locale: LocaleCode): boolean {
        const cached = this.isSymbolBeforeCache.get(locale);
        if (cached !== undefined)
            return cached;

        const computed = this.computeIsSymbolBefore(locale);
        this.isSymbolBeforeCache.set(locale, computed);

        return computed;
    }

    private isSymbolBeforeCache: Map<LocaleCode, boolean> = new Map();

    private computeIsSymbolBefore(locale: LocaleCode): boolean {
        const parts = Intl.NumberFormat(locale, {
            style: 'currency',
            currency: this.id,
            currencyDisplay: 'code',
        }).formatToParts(69);

        return parts[0].type === 'currency';
    }

    static displayAmount(amount: number, locale: LocaleCode, compact?: boolean): string {
        return Intl.NumberFormat(locale, {
            minimumFractionDigits: compact ? 0 : undefined,
        }).format(amount);
    }

    static fromServer(input: CurrencyOutput): CurrencyFE {
        return new CurrencyFE(
            input.id,
            input.id, // TODO - i18n ?
            priceFromServer(input.stripeMinimalCharge),
        );
    }
}

export type Money = {
    /** in whole currency (not cents) */
    amount: number;
    currency: CurrencyFE;
};

export type FormMoney = {
    amount: number | '';
    currency: CurrencyFE;
};

/**
 * Should be singleton (for each tax rate).
 */
export class TaxRateFE {
    private constructor(
        readonly id: Id,
        readonly label: string,
        readonly value: number,
        readonly isInclusive: boolean,
        readonly isEnabled: boolean,
        readonly country?: CountryCode, // If undefined, the tax rate is supposed to be for all countries
    ) {}

    static fromServer(input: TaxRateOutput): TaxRateFE {
        return new TaxRateFE (
            input.id,
            `${floatToPercent(input.value)} %`,
            input.value,
            input.inclusive,
            input.enabled,
            input.country,
        );
    }

    toKey(): string {
        return this.id;
    }

    // The tax rates are singletons so this is valid
    equals(other: TaxRateFE): boolean {
        return this === other;
    }

    get isZero(): boolean {
        return this.value === 0;
    }
}
