import type { Money, TaxRateFE } from ':frontend/modules/money';
import { type Id } from ':utils/id';
import type { OrderItemFE } from './OrderItem';
import { type DateTime } from 'luxon';
import { secondsToMinutes } from ':utils/common';
import { type EventFE } from '../Event';
import { type ExceptScheduler } from '../Team';
import { ClientInfoFE } from '../Client';
import type { TeamMemberRole } from ':utils/entity/team';
import { EventState } from ':utils/entity/event';
import type { ProductOrderItemOutput } from ':utils/entity/orderItem';

export class ProductOrderItemFE<TRole extends TeamMemberRole = typeof TeamMemberRole.master | typeof TeamMemberRole.freelancer> implements OrderItemFE<TRole> {
    private constructor(
        readonly id: Id,
        readonly title: string,
        readonly quantity: number,
        readonly unitPrice: ExceptScheduler<TRole, Money>,
        readonly vat: ExceptScheduler<TRole, TaxRateFE>,
        readonly index: number,
        readonly createdAt: DateTime,

        readonly icon: string | undefined,
        readonly sessionsCount: number,
        readonly sessionsDuration: number,
        readonly scheduledCount: number,
        readonly completedCount: number,
        readonly guest: ClientInfoFE,
    ) {}

    static fromServer<TRole extends TeamMemberRole>(base: OrderItemFE<TRole>, input: ProductOrderItemOutput): ProductOrderItemFE<TRole> {
        return new ProductOrderItemFE(
            base.id,
            base.title,
            base.quantity,
            base.unitPrice,
            base.vat,
            base.index,
            base.createdAt,
            input.icon,
            input.sessionsCount,
            secondsToMinutes(input.sessionsDuration),
            input.scheduledCount,
            input.completedCount,
            ClientInfoFE.fromServer(input.guest),
        );
    }

    get price(): ExceptScheduler<TRole, Money> {
        return this.unitPrice;
    }

    get isCompleted(): boolean {
        return this.completedCount === this.sessionsCount;
    }

    get isScheduled(): boolean {
        return this.scheduledCount === this.sessionsCount;
    }

    updateFromEvents(events: EventFE[]): ProductOrderItemFE<TRole> {
        return new ProductOrderItemFE(
            this.id,
            this.title,
            this.quantity,
            this.unitPrice,
            this.vat,
            this.index,
            this.createdAt,
            this.icon,
            this.sessionsCount,
            this.sessionsDuration,
            this.scheduledCount + events.length,
            this.completedCount + events.filter(e => e.state === EventState.finished).length,
            this.guest,
        );
    }
}

export type GenericProductItem = ProductOrderItemFE<TeamMemberRole>;
export type SchedulerProductItem = ProductOrderItemFE<typeof TeamMemberRole.scheduler>;
