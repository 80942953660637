import { type EventFE, type EventInfoFE } from ':frontend/types/Event';
import { useTranslation } from 'react-i18next';
import { CircleFullIcon, CircleSemiFullIcon, CircleEmptyIcon, CircleSlashIcon } from ':frontend/components/icons';
import clsx from 'clsx';
import type { IconType } from 'react-icons';
import { SpinnerButton } from '../common';
import { EventState, EventTransition, isEventTransitionPossible } from ':utils/entity/event';

const stateDescriptions = {
    [EventState.ready]: { color: 'warning', label: 'ready', icon: CircleSemiFullIcon },
    [EventState.finished]: { color: 'success', label: 'finished', icon: CircleFullIcon },
    [EventState.canceled]: { color: 'danger', label: 'canceled', icon: CircleEmptyIcon },
} as const;

const iconSizes = {
    'small': 16,
    'large': 20,
} as const;

type EventStateBadgeProps = {
    size?: keyof typeof iconSizes;
    className?: string;
} & ({
    event: EventInfoFE;
} | {
    state: EventState;
});

export default function EventStateBadge(props: EventStateBadgeProps) {
    const { t } = useTranslation('common', { keyPrefix: 'event.state' });
    const { size = 'small' } = props;
    const iconSize = iconSizes[size];
    const state = 'event' in props ? props.event.state : props.state;
    const description = stateDescriptions[state];

    return (
        <div className={clsx('d-flex align-items-center fw-medium gap-col-2', props.className)}>
            <span className={`d-flex text-${description.color}`}>
                {description.icon({ size: iconSize })}
            </span>
            {t(description.label)}
        </div>
    );
}

type EventDraftBadgeProps = Readonly<{
    size?: keyof typeof iconSizes;
    className?: string;
}>;

export function EventDraftBadge({ size, className }: EventDraftBadgeProps) {
    const { t } = useTranslation('common', { keyPrefix: 'event.state' });
    const iconSize = iconSizes[size ?? 'small'];

    return (
        <div className={clsx('d-flex align-items-center fw-medium gap-col-2', className)}>
            <span className='d-flex text-border-light'>
                <CircleEmptyIcon size={iconSize} />
            </span>
            {t('draft')}
        </div>
    );
}

const transitions: {
    type: EventTransition;
    button: { compact: string, square: string };
    icon: IconType;
}[] = [
    { type: EventTransition.Cancel, button: { compact: 'outline-danger', square: 'ghost-danger' }, icon: CircleSlashIcon },
    { type: EventTransition.Revoke, button: { compact: 'outline-warning', square: 'ghost-warning' }, icon: CircleSlashIcon },
];

type StateTransitionButtonsProps = Readonly<{
    event: EventFE;
    onSubmit: (transition: EventTransition, fid: string) => void;
    fetching: string | undefined;
    isOverlay?: boolean;
    className?: string;
    buttonType: 'compact' | 'square';
    buttonClassName?: string;
}>;

export function StateTransitionButtons({ event, onSubmit, fetching, isOverlay, className, buttonType, buttonClassName }: StateTransitionButtonsProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventSidebar' });

    return (
        <div className={className}>
            {transitions.filter(transition => isEventTransitionPossible(event, transition.type)).map(transition => (
                <SpinnerButton
                    key={transition.type}
                    variant={transition.button[buttonType]}
                    className={clsx(buttonType, buttonClassName)}
                    fetching={fetching}
                    fid={transition.type}
                    isOverlay={isOverlay}
                    onClick={() => onSubmit(transition.type, transition.type)}
                >
                    {transition.icon(buttonType === 'compact' ? { size: 18, className: 'me-2' } : { size: 22 })}{t(`${transition.type}Button`)}
                </SpinnerButton>
            ))}
        </div>
    );
}
