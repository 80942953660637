import { useCallback, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import { useUser } from ':frontend/context/UserProvider';
import { DateTime } from 'luxon';
import { SubscriptionFE } from ':frontend/types/Subscription';
import { getEnumValues } from ':utils/common';
import ErrorMessage from ':frontend/components/forms/ErrorMessage';
import { MoneyDisplay, SpinnerButton } from ':frontend/components/common';
import { IoSettingsSharp } from 'react-icons/io5';
import { type Money, type CurrencyFE } from ':frontend/modules/money';
import { useDaysLeft } from ':frontend/hooks';
import type { TFunction } from 'i18next';
import type { CurrencyId } from ':utils/id';
import { TeamMemberRole } from ':utils/entity/team';
import { SubscriptionTierCode, SubscriptionTierPaymentPeriod } from ':utils/entity/subscription';
import { trpc } from ':frontend/context/TrpcProvider';

const TEAM_OPTION_BUTTON_LINK = 'https://app.lemcal.com/@stepancalta';

export default function Subscriptions() {
    const { t } = useTranslation('pages', { keyPrefix: 'subscription' });
    const { subscription, role } = useUser();

    return (
        <Container className='sh-subscription-page pb-5'>
            <h2>{t('current-subscription-section-title')}</h2>
            <Card className='mb-2'>
                <Card.Body className='d-flex align-items-baseline'>
                    {subscription
                        ? (
                            <SubscriptionInner subscription={subscription} />
                        ) : (
                            <>{t('no-subscription-label')}</>
                        )
                    }
                </Card.Body>
            </Card>
            {!subscription.isActive && (
                <ErrorMessage message={t('no-plan-selected-text')} />
            )}
            {role === TeamMemberRole.freelancer && (
                <div className='mt-5'>
                    <SubscriptionSelect />
                </div>
            )}
            {role === TeamMemberRole.master && (
                <div className='mt-5 text-center w-100 fs-4 pre-line'>
                    <Trans
                        t={t}
                        i18nKey='master-text'
                        components={{
                            email: <a href='mailto:info@flowlance.com' target='_blank' rel='noreferrer' className='text-decoration-none' />,
                        }}
                    />
                </div>
            )}
        </Container>
    );
}

type SubscriptionInnerProps = Readonly<{
    subscription: SubscriptionFE;
}>;

function SubscriptionInner({ subscription }: SubscriptionInnerProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'subscription' });

    const { label, className } = getPlanLabelAndClassName(subscription);

    return (<>
        <span className='fs-2 me-3'>{t(`${subscription.code}.label`)}</span>
        <span className={clsx('fs-2 fw-semibold', className)}>
            {t(label)}
        </span>
        <span className='flex-grow-1' />
        {subscription.isTrial ? (
            <DaysLeft expiresAt={subscription.trialExpiresAt} />
        ) : (
            <ManagePlan />
        )}
    </>);
}

function getPlanLabelAndClassName(subscription: SubscriptionFE) {
    if (subscription.isTrial) {
        return subscription.isActive
            ? { label: 'trial-active-label', className: 'text-warning' }
            : { label: 'trial-ended-label', className: 'text-danger' };
    }

    return subscription.isActive
        ? { label: 'plan-active-label', className: 'text-success' }
        : { label: 'plan-ended-label', className: 'text-danger' };
}

type DaysLeftProps = Readonly<{
    expiresAt: DateTime;
}>;

function DaysLeft({ expiresAt }: DaysLeftProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'subscription' });
    const count = useDaysLeft(expiresAt);

    return (
        <span>
            {t('trial-days-left-label', { count })}
        </span>
    );
}

function ManagePlan() {
    const { t } = useTranslation('pages', { keyPrefix: 'subscription' });

    const { goToSubscriptionSession, isFetching } = useSubscriptionUpsert();

    return (
        <SpinnerButton
            isFetching={isFetching}
            onClick={goToSubscriptionSession}
            className='sh-button-inverse'
        >
            {t('manage-plan-button')} <IoSettingsSharp size={20} className='ms-2' />
        </SpinnerButton>
    );
}

/** This extends SubscriptionTierCode by the team option, which can be bought, but then it's mapped to the Paid2 tier. */
enum SubscriptionType {
    Free = 'free',
    Paid2 = 'paid2',
    Team = 'team',
}

function subscriptionCodeFromType(type: SubscriptionType): SubscriptionTierCode {
    if (type === SubscriptionType.Free)
        return SubscriptionTierCode.free;
    if (type === SubscriptionType.Paid2)
        return SubscriptionTierCode.paid2;

    throw new Error(`Unknown subscription type: ${type}`);
}

function subscriptionCodeEqualsType(code: SubscriptionTierCode, type: SubscriptionType): boolean {
    return '' + code === type;
}

const SUBSCRIPTION_TYPES = getEnumValues(SubscriptionType);

function SubscriptionSelect() {
    const { t } = useTranslation('pages', { keyPrefix: 'subscription' });
    const [ paymentPeriod, setPaymentPeriod ] = useState<SubscriptionTierPaymentPeriod>(SubscriptionTierPaymentPeriod.yearly);

    return (<>
        <Row className='mb-5'>
            <Col>
                <Button
                    className={clsx('sh-subscription-button float-end', { 'sh-button-inverse': paymentPeriod === SubscriptionTierPaymentPeriod.yearly })}
                    onClick={() => setPaymentPeriod(SubscriptionTierPaymentPeriod.monthly)}
                    disabled={paymentPeriod === SubscriptionTierPaymentPeriod.monthly}
                >
                    {t('month-button')}
                </Button>
            </Col>
            <Col>
                <Button
                    className={clsx('sh-subscription-button', { 'sh-button-inverse': paymentPeriod === SubscriptionTierPaymentPeriod.monthly })}
                    onClick={() => setPaymentPeriod(SubscriptionTierPaymentPeriod.yearly)}
                    disabled={paymentPeriod === SubscriptionTierPaymentPeriod.yearly}
                >
                    {t('year-button')}
                </Button>
            </Col>
        </Row>
        <div className='sh-subscription-container'>
            {SUBSCRIPTION_TYPES.map(type => (
                <SubscriptionOption
                    key={type}
                    type={type}
                    paymentPeriod={paymentPeriod}
                />
            ))}
        </div>
    </>);
}

export function getClientReferenceId(): string {
    const referenceId = 'Rewardful' in window
        && window.Rewardful
        && typeof window.Rewardful === 'function'
        && 'referral' in window.Rewardful
        && window.Rewardful.referral
        || ('checkout_'+ DateTime.now().toMillis());

    return '' + referenceId;
}

type SubscriptionOptionProps = Readonly<{
    type: SubscriptionType;
    paymentPeriod: SubscriptionTierPaymentPeriod;
}>;

function SubscriptionOption({ type, paymentPeriod }: SubscriptionOptionProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'subscription' });
    const { t: tType } = useTranslation('pages', { keyPrefix: `subscription.${type}` });

    const { subscription, team } = useUser();
    const { buySubscription, isFetching } = useSubscriptionUpsert();

    const isSelected = type === SubscriptionType.Paid2;
    const isActive = subscription.isActive
        && !subscription.isTrial
        && subscriptionCodeEqualsType(subscription.code, type)
        && (paymentPeriod === subscription.paymentPeriod || type === SubscriptionType.Free);

    // The trial is available only for the paid plan and only if the team didn't use it before.
    const isTrialAvailable = type === SubscriptionType.Paid2 && !team.dateTrialStarted;

    return (
        <div className={clsx('sh-subscription-option', { 'sh-selected': isSelected })}>
            <div className='d-flex align-items-baseline justify-content-between'>
                <h2 className='mb-4'>{tType('label')}</h2>
                {isSelected && (
                    <span className='sh-most-popular rounded-5 p-2'>{t('most-popular-label')}</span>
                )}
            </div>
            {priceRow(t, paymentPeriod, type, team.stripeCurrency)}
            <div className='mt-4'>
                {type === SubscriptionType.Team ? (
                    <a href={TEAM_OPTION_BUTTON_LINK} target='_blank' rel='noreferrer'>
                        <Button className='rounded-5 w-100 py-2'>
                            {tType('button')}
                        </Button>
                    </a>
                ) : (
                    <SpinnerButton
                        isFetching={isFetching}
                        onClick={() => {
                            const code = subscriptionCodeFromType(type);
                            if (code)
                                buySubscription(code, paymentPeriod);
                        }}
                        className='rounded-5 w-100 py-2'
                    >
                        {isActive
                            ? t('manage-plan-button')
                            : tType(isTrialAvailable ? 'button-try-trial' : 'button')
                        }
                    </SpinnerButton>
                )}
            </div>
            <div className='mt-4 sh-subscription-option-description fw-medium'>
                <Trans
                    t={tType}
                    i18nKey='description'
                    components={{ pr: <span className='text-primary-300 fw-semibold' /> }}
                />
            </div>
        </div>
    );
}

export function useSubscriptionUpsert() {
    const { subscription, setSubscription } = useUser();

    const createSubscriptionSessionMutation = trpc.subscription.createSubscriptionSession.useMutation();
    const upsertSubscriptionMutation = trpc.subscription.upsertSubscription.useMutation();

    const goToSubscriptionSession = useCallback(() => {
        createSubscriptionSessionMutation.mutate(undefined, {
            onError: () => {
                // TODO Do something.
            },
            onSuccess: response => {
                window.location.href = response.continueUrl;
            },
        });
    }, [ createSubscriptionSessionMutation ]);

    const buySubscription = useCallback((code: SubscriptionTierCode, rawPaymentPeriod: SubscriptionTierPaymentPeriod) => {
        // The free subscription exists only in the monthy variant.
        const paymentPeriod = code === SubscriptionTierCode.free ? SubscriptionTierPaymentPeriod.monthly : rawPaymentPeriod;

        // If the desired subscription is same as the current, we redirect appUser to the customer portal without code and paymentPeriod.
        if (
            subscription.isActive
            && subscription.code === code
            && subscription.paymentPeriod === paymentPeriod
        ) {
            goToSubscriptionSession();
            return;
        }

        // If none of the above is true, we redirect him to a page where he can buy a new subscription.
        upsertSubscriptionMutation.mutate({ code, paymentPeriod, clientReferenceId: getClientReferenceId() }, {
            onError: () => {
                // TODO Do something.
            },
            onSuccess: response => {
                if ('subscription' in response) {
                    const newSubscription = SubscriptionFE.fromServer(response.subscription);
                    setSubscription(newSubscription);
                }
                else {
                    window.location.href = response.continueUrl;
                }
            },
        });
    }, [ subscription, setSubscription, goToSubscriptionSession, upsertSubscriptionMutation ]);

    return {
        goToSubscriptionSession,
        buySubscription,
        isFetching: createSubscriptionSessionMutation.isPending || upsertSubscriptionMutation.isPending,
    };
}

function priceRow(t: TFunction, period: SubscriptionTierPaymentPeriod, type: SubscriptionType, currency: CurrencyFE) {
    const monthPrice = SUBSCRIPTION_PRICES[currency.id][SubscriptionTierPaymentPeriod.monthly][type];
    const month = toFixedMoney(monthPrice, currency);
    const monthSeat = toSeatMoney(monthPrice, currency);

    if (period === SubscriptionTierPaymentPeriod.monthly) {
        return (
            <div>
                <MoneyDisplay money={month} className='fs-1 fw-semibold' />
                <span className='ms-2 fs-2 text-muted'>{t('month-unit')}</span>
                {monthSeat && (<>
                    <span className='ms-3'>+ </span>
                    <MoneyDisplay money={monthSeat} />
                    <span className='ms-2 text-muted'>{t('seat-unit')}</span>
                </>)}
            </div>
        );
    }

    const yearPrice = SUBSCRIPTION_PRICES[currency.id][SubscriptionTierPaymentPeriod.yearly][type];
    const year = toFixedMoney(yearPrice, currency);
    const yearSeat = toSeatMoney(yearPrice, currency);

    return (<>
        <div>
            <MoneyDisplay money={year} className='fs-1 fw-semibold' />
            <span className='ms-2 fs-2 text-muted'>{t('month-unit')}</span>
            {yearSeat && (<>
                <span className='ms-3'>+ </span>
                <MoneyDisplay money={yearSeat} />
                <span className='ms-2 text-muted'>{t('seat-unit')}</span>
            </>)}
        </div>
        {type !== SubscriptionType.Free && (
            <div className='h-0 position-relative'>
                <MoneyDisplay money={month} className='text-decoration-line-through text-muted' />
                <span className='ms-2 text-muted'>{t('month-unit')}</span>
                {monthSeat && yearSeat && (<>
                    <span className='ms-3'>+ </span>
                    <MoneyDisplay money={monthSeat} className='text-decoration-line-through text-muted' />
                    <span className='ms-2 text-muted'>{t('seat-unit')}</span>
                </>)}
            </div>
        )}
    </>);
}

type PriceNumber = number | { fixed: number, seat: number };
function toFixedMoney(price: PriceNumber, currency: CurrencyFE): Money {
    const amount = typeof price === 'number' ? price : price.fixed;
    return { amount, currency };
}

function toSeatMoney(price: PriceNumber, currency: CurrencyFE): Money | undefined {
    if (typeof price === 'number')
        return;

    return { amount: price.seat, currency };
}

const SUBSCRIPTION_PRICES: {
    [key: CurrencyId]: {
        [key in SubscriptionTierPaymentPeriod]: {
            [key in SubscriptionType]: PriceNumber;
        };
    };
} = {
    'CZK': {
        [SubscriptionTierPaymentPeriod.monthly]: {
            [SubscriptionType.Free]: 0,
            [SubscriptionType.Paid2]: 1200,
            [SubscriptionType.Team]: { fixed: 2500, seat: 150 },
        },
        [SubscriptionTierPaymentPeriod.yearly]: {
            [SubscriptionType.Free]: 0,
            [SubscriptionType.Paid2]: 960,
            [SubscriptionType.Team]: { fixed: 2000, seat: 99 },
        },
    },
    'EUR': {
        [SubscriptionTierPaymentPeriod.monthly]: {
            [SubscriptionType.Free]: 0,
            [SubscriptionType.Paid2]: 49,
            [SubscriptionType.Team]: { fixed: 99, seat: 6 },
        },
        [SubscriptionTierPaymentPeriod.yearly]: {
            [SubscriptionType.Free]: 0,
            [SubscriptionType.Paid2]: 39,
            [SubscriptionType.Team]: { fixed: 79, seat: 4 },
        },
    },
    'USD': {
        [SubscriptionTierPaymentPeriod.monthly]: {
            [SubscriptionType.Free]: 0,
            [SubscriptionType.Paid2]: 49,
            [SubscriptionType.Team]: { fixed: 99, seat: 6 },
        },
        [SubscriptionTierPaymentPeriod.yearly]: {
            [SubscriptionType.Free]: 0,
            [SubscriptionType.Paid2]: 39,
            [SubscriptionType.Team]: { fixed: 79, seat: 4 },
        },
    },
};
