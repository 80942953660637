import clsx from 'clsx';
import { Navigate } from './utils/common';
import { localizer, type NavigateAction } from '.';
import { type DateTime } from 'luxon';
import { capitalize } from ':frontend/utils/common';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon, ChevronRightIcon } from ':frontend/components/icons';
import { Views, type View } from './Views';
import { MonthLabel } from ':frontend/components/forms';

type ToolbarProps = {
    date: DateTime;
    onNavigate: (navigate: NavigateAction) => void;
    view: View;
    onView: (view: View) => void;
};

export default function Toolbar({ date, onNavigate, view, onView }: ToolbarProps) {
    const { t } = useTranslation('components', { keyPrefix: 'calendar' });

    return (
        <div className='rbc-toolbar'>
            <span className='sh-close-buttons'>
                <button
                    className='sh-close-button icon'
                    type='button'
                    onClick={() => onNavigate(Navigate.PREVIOUS)}
                    aria-label={t(`previous-${view}-button`)}
                >
                    <ChevronLeftIcon size={18} />
                </button>
                <button
                    className='sh-close-button'
                    type='button'
                    onClick={() => onNavigate(Navigate.TODAY)}
                >
                    {t('today')}
                </button>
                <button
                    className='sh-close-button icon'
                    type='button'
                    onClick={() => onNavigate(Navigate.NEXT)}
                    aria-label={t(`next-${view}-button`)}
                >
                    <ChevronRightIcon size={18} />
                </button>
            </span>

            <span className='rbc-toolbar-label'><ToolbarLabel date={date} view={view} /></span>

            <span className='sh-segmented-buttons'>
                {navigableViews.map(name => (
                    <button
                        type='button'
                        key={name}
                        className={clsx('sh-segmented-button', { 'selected': view === name })}
                        onClick={() => onView(name)}
                    >
                        {t(name)}
                    </button>
                ))}
            </span>
        </div>
    );
}

const navigableViews: View[] = [ Views.WEEK, Views.MONTH, Views.AGENDA ];

type ToolbarLabelProps = {
    date: DateTime;
    view: View;
};

function ToolbarLabel({ date, view }: ToolbarLabelProps) {
    if (view === Views.WEEK) {
        const start = localizer.startOf(date, 'week');
        const end = localizer.endOf(date, 'week');
        // TODO make more effective
        const allDays = localizer.range(start, end);

        return <WeekRangeFormat start={start} end={allDays[allDays.length - 1]} />;
    }

    if (view === Views.MONTH || view === Views.AGENDA)
        return <MonthLabel date={date} />;

    return null;
}

type WeekRangeFormatProps = {
    start: DateTime;
    end: DateTime;
};

function WeekRangeFormat({ start, end }: WeekRangeFormatProps) {
    // If the years aren't equal, the months are not equal as well. Example: December 2023 - January 2024
    if (!localizer.eq(start, end, 'year')) {
        return (<>
            <span className='fw-semibold'>{capitalize(localizer.format(start, 'LLLL'))}</span>
            {' '}
            <span>{localizer.format(start, 'yyyy')}</span>
            {' '}
            <span className='fw-semibold'>—</span>
            {' '}
            <span className='fw-semibold'>{capitalize(localizer.format(end, 'LLLL'))}</span>
            {' '}
            <span>{localizer.format(end, 'yyyy')}</span>
        </>);
    }

    return (<>
        {/* If the months aren't equal but the year is. Example: January - February 2024 */}
        {!localizer.eq(start, end, 'month') && (
            <span className='fw-semibold'>{capitalize(localizer.format(start, 'LLLL — '))}</span>
        )}
        {/* If the months are equal. Example: January 2024 */}
        <span className='fw-semibold'>{capitalize(localizer.format(end, 'LLLL'))}</span>
        {' '}
        <span>{localizer.format(end, 'yyyy')}</span>
    </>);
}
