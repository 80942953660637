import { z, type ZodSchema } from 'zod';
import { zId } from ':utils/id';
import { zCustomItemInit, zDiscountItemInit, zEventItemInit, zOrderItemEdit, zOrderItemOutput } from './orderItem';
import { zClientInfoOutput } from './client';
import { zClientOrContactInit } from './eventParticipant';
import { zCurrencyId } from './money';
import { zInvoicingIdentityOutput, zInvoicingIdentityUpdate } from './invoicing';
import { zDateTime, zDateRange } from ':utils/common';
import { zOrderBy, zPage } from ':utils/query';

// Enums

export enum OrderState {
    fulfilled = 'fulfilled',
    new = 'new',
    overdue = 'overdue',
    canceled = 'canceled',
}

export enum OrderTransition {
    Cancel = 'cancel',
    Fulfill = 'fulfill',
    Unfulfill = 'unfulfill',
    Overdue = 'overdue'
}

export enum PaymentMethod {
    bankTransfer = 'bankTransfer',
    stripe = 'stripe',
    noInvoice = 'noInvoice',
}

// Logs

export enum OrderLogType {
    Created = 'order.created',
    NotificationSent = 'order.notificationSent',
    Updated = 'order.updated',
    Transition = 'order.transition',
}


export function zLogOutputFunction<TData extends ZodSchema>(data: TData) {
    return z.object({
        id: zId,
        createdAt: zDateTime,
        data,
    });
}

export const zOrderLogData = z.discriminatedUnion('type', [
    z.object({
        type: z.literal(OrderLogType.Created),
    }),
    z.object({
        type: z.literal(OrderLogType.NotificationSent),
        email: z.string(),
    }),
    z.object({
        type: z.literal(OrderLogType.Updated),
    }),
    z.object({
        type: z.literal(OrderLogType.Transition),
        before: z.nativeEnum(OrderState),
        after: z.nativeEnum(OrderState),
        transition: z.nativeEnum(OrderTransition),
        isAutomatic: z.boolean(),
    }),
]);

export const zOrderLogOutput = zLogOutputFunction(zOrderLogData);

// Outputs

export type OrderInfoOutput = z.infer<typeof zOrderInfoOutput>;
export const zOrderInfoOutput = z.object({
    id: zId,
    client: zClientInfoOutput,
    prefix: z.string().optional(),
    index: z.number().optional(),
    title: z.string(),
    total: z.number(),
    currencyId: zCurrencyId,
    state: z.nativeEnum(OrderState),
    createdAt: zDateTime,
    issueDate: zDateTime,
    paymentMethod: z.nativeEnum(PaymentMethod),
    /** AppUser */
    schedulerId: zId.optional(),
});

export type SchedulerOrderInfoOutput = z.infer<typeof zSchedulerOrderInfoOutput>;
export const zSchedulerOrderInfoOutput = z.object({
    id: zId,
    client: zClientInfoOutput,
    title: z.string(),
    state: z.nativeEnum(OrderState),
    createdAt: z.string(),  // TODO zDateTime ?
});

export type OrderCustomFields = z.infer<typeof zOrderCustomFields>;
export const zOrderCustomFields = z.object({
    header: z.string().optional(),
    footer: z.string().optional(),
    customKey1: z.string().optional(),
    customValue1: z.string().optional(),
    customKey2: z.string().optional(),
    customValue2: z.string().optional(),
});

export type OrderOutput = z.infer<typeof zOrderOutput>;
export const zOrderOutput = zOrderInfoOutput.merge(zOrderInfoOutput).merge(zOrderCustomFields).merge(z.object({
    supplier: zInvoicingIdentityOutput,
    subscriber: zInvoicingIdentityOutput,
    variableSymbol: z.string().optional(),
    items: z.array(zOrderItemOutput),
    logs: z.array(zOrderLogOutput),
    isNotificationSent: z.boolean(),
    dueDate: zDateTime,
    taxDate: zDateTime,
    condensedInvoice: z.boolean(),
}));

export type NotificationInit = z.infer<typeof zNotificationInit>;
export const zNotificationInit = z.object({
    /** If undefined, the client's email will be used instead. */
    email: z.string().optional(),
    cc: z.array(z.string()).optional(),
    subject: z.string(),
    body: z.string(),
});

export const emailVariables = [ '#invoiceLink#', '#paymentLink#' ] as const;
export type EmailVariable = typeof emailVariables[number];

const zOrderInitBase = z.object({
    paymentMethod: z.nativeEnum(PaymentMethod),
    dueDays: z.number().optional(),
    notification: zNotificationInit.optional(),
});

export type EventOrderInit = z.infer<typeof zEventOrderInit>;
export const zEventOrderInit = zOrderInitBase.and(z.object({
    /** There should be one item for each client. */
    eventItems: z.array(zEventItemInit),
}));

export type ProductOrderInit = z.infer<typeof zProductOrderInit>;
export const zProductOrderInit = zOrderInitBase.and(z.object({
    // TODO remove title and calculate it on BE
    title: z.string(),
    client: zClientOrContactInit,
    guest: zClientOrContactInit,
    scheduler: zId.optional(),

    /** Product */
    productItems: z.array(zId),
    discountItems: z.array(zDiscountItemInit),
}));

export type CustomOrderInit = z.infer<typeof zCustomOrderInit>;
export const zCustomOrderInit = zOrderInitBase.and(z.object({
    // TODO remove title and calculate it on BE
    title: z.string(),
    client: zClientOrContactInit,

    currencyId: zCurrencyId,
    items: z.array(zCustomItemInit),
}));

export type OrderInit = z.infer<typeof zOrderInit>;
export const zOrderInit = z.union([
    z.object({
        event: zEventOrderInit,
    }),
    z.object({
        product: zProductOrderInit,
    }),
    z.object({
        custom: zCustomOrderInit,
    }),
]);

/** Like PATCH. */
export type OrderEdit = z.infer<typeof zOrderEdit>;
export const zOrderEdit = z.object({
    prefix: z.string(),
    index: z.number(),
    title: z.string(),
    variableSymbol: z.string(),
    issueDate: zDateTime,
    dueDate: zDateTime,
    taxDate: zDateTime,
    condensedInvoice: z.boolean(),
    /** Like PUT - if anything changes, the object is replaced. */
    subscriber: zInvoicingIdentityUpdate,
    /** Like PUT - if anything changes, the object is replaced. */
    supplier: zInvoicingIdentityUpdate,
    /** Like PUT - if anything changes, the object is replaced. */
    fields: zOrderCustomFields,
    /** Like PUT - if anything changes on a specific item, the item is replaced. */
    orderItems: z.array(zOrderItemEdit),
}).partial().and(z.object({
    id: zId,
}));

export const zOrderStatsInput = zDateRange.array();

const zOrderStatsBasePeriodOutput = z.object({
    productsSold: z.number(),
    activeClients: z.number(),
});
const zOrderStatsStates = z.object({
    [OrderState.fulfilled]: z.number(),
    [OrderState.new]: z.number(),
    [OrderState.overdue]: z.number(),
});

const zOrderStatsFullPeriodOutput = zOrderStatsStates.merge(zOrderStatsBasePeriodOutput);

export type OrderStatsCurrencyOutput = z.infer<typeof zOrderStatsCurrencyOutput>;
const zOrderStatsCurrencyOutput = z.object({
    currency: zId,
    periods: z.array(zOrderStatsFullPeriodOutput).min(4),
    orders: z.array(zOrderInfoOutput),
});

export type OrderStatsOutput = z.infer<typeof zOrderStatsOutput>;
export const zOrderStatsOutput = z.object({
    currencies: z.array(zOrderStatsCurrencyOutput),
    periods: zDateRange.array(),
});

export function isStripeOrder({ paymentMethod }: { paymentMethod: PaymentMethod }): boolean {
    return paymentMethod === PaymentMethod.stripe;
}

export const zOrderIndex = z.number().int().min(1).max(9999);

export const zGetOrdersQuery = z.object({
    state: z.array(z.nativeEnum(OrderState)),
    client: z.array(zId),
    invoicingProfile: zId,
    createdAfter: zDateTime,
    createdBefore: zDateTime,

    page: zPage,
    orderBy: zOrderBy([ 'createdAt', 'index' ]),
}).partial();

export enum ExportOrdersFormat {
    PdfZip = 'pdf-zip',
    Pdf = 'pdf',
    Csv = 'csv',
    Isdoc = 'isdoc',
}

export type ExportOrdersQuery = z.infer<typeof zExportOrdersQuery>;
export const zExportOrdersQuery = z.object({
    format: z.nativeEnum(ExportOrdersFormat),
    state: z.array(z.nativeEnum(OrderState)).optional(),
    client: z.array(zId).optional(),
    createdAfter: zDateTime.optional(),
    createdStrictlyBefore: zDateTime.optional(),
});
