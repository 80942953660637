import { type CSSProperties } from 'react';
import clsx from 'clsx';
import { localizer } from '.';
import EventWrapper from './addons/dragAndDrop/EventWrapper';
import { getEventStyle } from './eventStyle';
import type { CalendarEvent } from ':frontend/types/calendar/Calendar';
import { EventTitle } from './TimeGridEvent';
import { useUser } from ':frontend/context/UserProvider';
import { TeamMemberRole } from ':utils/entity/team';

type EventCellProps = {
    event: CalendarEvent & { __isPreview?: boolean };
    selected: boolean;
    continuesPrior: boolean;
    continuesAfter: boolean;
    onSelect: (event: CalendarEvent, e: React.MouseEvent<HTMLDivElement>) => void;
    onDoubleClick: (event: CalendarEvent, e: React.MouseEvent<HTMLDivElement>) => void;
    onKeyPress: (event: CalendarEvent, e: React.KeyboardEvent<HTMLDivElement>) => void;
    style?: CSSProperties;
    className?: string;
    type?: 'popup';
    onDragStart: () => void;
    onDragEnd: () => void;
};

export default function EventCell(props: EventCellProps) {
    const { style, className, event, selected, onSelect, onDoubleClick, onKeyPress, continuesPrior, continuesAfter, ...rest } = props;

    const tooltip = event.tooltip ?? event.title;
    const showAsAllDay = !!event.allDay || localizer.diff(event.start, localizer.ceil(event.end, 'day'), 'day') > 1;

    const isMaster = useUser().role === TeamMemberRole.master;
    const userProps = getEventStyle(event, isMaster, selected);

    return (
        <EventWrapper {...props} type='date'>
            <div
                {...rest}
                tabIndex={0}
                style={{ ...userProps.style, ...style }}
                className={clsx('rbc-event', className, userProps.className, {
                    'rbc-selected': selected,
                    'rbc-event-allday': showAsAllDay,
                    'rbc-event-continues-prior': continuesPrior,
                    'rbc-event-continues-after': continuesAfter,
                })}
                onClick={e => onSelect?.(event, e)}
                onDoubleClick={e => onDoubleClick?.(event, e)}
                onKeyPress={e => onKeyPress?.(event, e)}
            >
                <div className='rbc-event-content' title={tooltip || undefined}>
                    <EventTitle event={event} />
                </div>
            </div>
        </EventWrapper>
    );
}
