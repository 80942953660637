import { useCallback, useMemo, useState, type Dispatch, type SetStateAction } from 'react';
import { useNavigate, useParams, type NavigateFunction } from 'react-router-dom';
import { ClientFE, type ClientInfoFE } from ':frontend/types/Client';
import { Button, Nav, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { routesFE } from ':utils/routes';
import ClientNotes from './ClientNotes';
import { ClientIcon } from ':frontend/components/client/ClientIconLink';
import DeleteClientModal from ':frontend/components/client/DeleteClientModal';
import { CheckIcon, PhoneCallIcon, SendIcon, TrashIcon } from ':frontend/components/icons';
import { useBlockerModal, useDaysLeft, useToggle } from ':frontend/hooks';
import BlockNavigationModal from ':frontend/components/BlockNavigationModal';
import { type EventFE } from ':frontend/types/Event';
import ClientPreferencesForm from ':frontend/components/client/ClientPreferencesForm';
import Portal, { portals } from ':frontend/components/common/Portal';
import useNotifications from ':frontend/context/NotificationProvider';
import { createErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import { SpinnerButton } from ':frontend/components/common';
import ClientGeneralForm from ':frontend/components/client/ClientGeneralForm';
import ClientInvoicingForm from ':frontend/components/client/ClientInvoicingForm';
import OrdersTable from ':frontend/components/orders/OrdersTable';
import EventsTable from ':frontend/components/event/EventsTable';
import { EventDetailLink } from '../event/EventDetail';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import ClientPackages from ':frontend/components/client/ClientPackages';
import { ClientStateEdit } from ':frontend/components/client/ClientStateBadge';
import { ClientTags } from ':frontend/components/client/ClientTags';
import { trpc } from ':frontend/context/TrpcProvider';
import type { ClientUpdate } from ':utils/entity/client';
import type { OmitId } from ':utils/id';

export const CLIENT_DETAIL_SCROLLER_ID = 'client-detail-scroller';

// We assume that the client isn't deleted. If he is, he shouldn't be returned from the server.

export default function ClientDetail() {
    const { t } = useTranslation('pages', { keyPrefix: 'client' });
    const userContext = useUser();
    const isMasterOrFreelancer = !!toMaster(userContext);
    const { id, key } = useParams<{id: string, key: string}>();
    const navigate = useNavigate();
    const handleSelect = useCallback((newKey: string | null) => id && newKey && navigate(routesFE.clients.detail.resolve({ id, key: newKey })), [ id, navigate ]);

    // const [ clientTemp, setClient ] = useState<ClientFE>();
    // const setClientInfo = useCallback((info: ClientInfoFE) => setClient(client => client?.updateFromInfo(info)), [ setClient ]);

    // TODO
    const [ nextEvent, setNextEvent ] = useState<EventFE>();

    const clientQuery = trpc.$client.getClient.useQuery({ id: id! });
    const trpcUtils = trpc.useUtils();
    const invalidateClient = useCallback(() => trpcUtils.invalidate(), [ trpcUtils ]);

    const client = useMemo(() => clientQuery.data && ClientFE.fromServer(clientQuery.data), [ clientQuery.data ]);

    const [ isDirty, setIsDirty ] = useState(false);
    const { navigateUnblocked, control } = useBlockerModal(isDirty);

    if (!client)
        return null;

    return (
        <div className='d-flex flex-column h-100'>
            <BlockNavigationModal control={control} />
            <div className='container-large py-3 d-flex align-items-center gap-col-2'>
                <div className='flex-grow-1 d-flex align-items-center gap-3'>
                    <ClientIcon client={client} size='lg' />
                    <h2 className='text-truncate m-0'>{client.name}</h2>
                    {isMasterOrFreelancer && (<>
                        <ClientStateEdit client={client} setClient={invalidateClient} />
                        <div style={{ maxWidth: '50%' }}>
                            <ClientTags client={client} setClient={invalidateClient} />
                        </div>
                    </>)}
                </div>

                <div id={portals.client.save} />
                <a href={`mailto:${client.email}`} target='_blank' rel='noreferrer' className='text-decoration-none'>
                    <Button variant='ghost-secondary' className='square'>
                        <SendIcon size={22} />
                        {t('send-email-button')}
                    </Button>
                </a>
                {client.phoneNumber && (
                    <a href={`tel:${client.phoneNumber}`} target='_blank' rel='noreferrer' className='text-decoration-none'>
                        <Button variant='ghost-secondary' className='square'>
                            <PhoneCallIcon size={22} />
                            {t('phone-call-button')}
                        </Button>
                    </a>
                )}
                <DeleteButton client={client} navigateUnblocked={navigateUnblocked} />
            </div>
            <Tab.Container
                activeKey={key}
                transition={false}
                onSelect={handleSelect}
            >
                <div className='border-1 border-bottom border-top' style={{ backgroundColor: '#F5F5F5' }}>
                    <div className='container-large d-flex align-items-center justify-content-between gap-4'>
                        <Nav className='sh-tabs nav-tabs' as='ul'>
                            <Nav.Item as='li'><Nav.Link as='button' eventKey='general'>{t('general-tab-title')}</Nav.Link></Nav.Item>
                            {isMasterOrFreelancer && (
                                <Nav.Item as='li'><Nav.Link as='button' eventKey='invoicing'>{t('invoicing-tab-title')}</Nav.Link></Nav.Item>
                            )}
                            <Nav.Item as='li'><Nav.Link as='button' eventKey='preferences'>{t('preferences-tab-title')}</Nav.Link></Nav.Item>
                            <Nav.Item as='li'><Nav.Link as='button' eventKey='packages'>{t('packages-tab-title')}</Nav.Link></Nav.Item>
                            <Nav.Item as='li'><Nav.Link as='button' eventKey='orders'>{t('orders-tab-title')}</Nav.Link></Nav.Item>
                            <Nav.Item as='li'><Nav.Link as='button' eventKey='events'>{t('events-tab-title')}</Nav.Link></Nav.Item>
                            <Nav.Item as='li'><Nav.Link as='button' eventKey='notes'>{t('notes-tab-title')}</Nav.Link></Nav.Item>
                        </Nav>
                        {nextEvent && <NextEventBadge event={nextEvent} />}
                    </div>
                </div>
                <div
                    className='sh-main-scroller'
                    id={CLIENT_DETAIL_SCROLLER_ID}
                    style={key === 'packages' ? { backgroundColor: '#F5F8FA' } : undefined}
                >
                    <Tab.Content className='container-large py-4'>
                        <Tab.Pane eventKey='general' unmountOnExit>
                            <ClientFormPart client={client} onUpdate={invalidateClient} isDirty={isDirty} setIsDirty={setIsDirty} type={ClientUpdateType.General} />
                        </Tab.Pane>
                        {isMasterOrFreelancer && (
                            <Tab.Pane eventKey='invoicing' unmountOnExit>
                                <ClientFormPart client={client} onUpdate={invalidateClient} isDirty={isDirty} setIsDirty={setIsDirty} type={ClientUpdateType.Invoicing} />
                            </Tab.Pane>
                        )}
                        <Tab.Pane eventKey='preferences' unmountOnExit>
                            <ClientFormPart client={client} onUpdate={invalidateClient} isDirty={isDirty} setIsDirty={setIsDirty} type={ClientUpdateType.Preferences} />
                        </Tab.Pane>
                        <Tab.Pane eventKey='packages' unmountOnExit>
                            <ClientPackages client={client} />
                        </Tab.Pane>
                        <Tab.Pane eventKey='orders' unmountOnExit>
                            <ClientOrders client={client} />
                        </Tab.Pane>
                        <Tab.Pane eventKey='events' unmountOnExit>
                            <ClientEvents client={client} />
                        </Tab.Pane>
                        <Tab.Pane eventKey='notes' unmountOnExit>
                            <ClientNotes client={client} />
                        </Tab.Pane>
                    </Tab.Content>
                </div>
            </Tab.Container>
        </div>
    );
}

type DeleteButtonProps = Readonly<{
    client: ClientFE;
    navigateUnblocked: NavigateFunction;
}>;

function DeleteButton({ client, navigateUnblocked }: DeleteButtonProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'client' });
    const [ showModal, setShowModal ] = useToggle(false);

    return (<>
        <DeleteClientModal
            client={showModal ? client : undefined}
            onClose={setShowModal.false}
            onDelete={() => navigateUnblocked(routesFE.clients.list)}
        />
        <Button
            variant='ghost-danger'
            className='square'
            onClick={setShowModal.true}
        >
            <TrashIcon size={22} />
            {t('delete-button')}
        </Button>
    </>);
}

type NextEventBadgeProps = Readonly<{
    event: EventFE;
}>;

function NextEventBadge({ event }: NextEventBadgeProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'client.next-event-badge' });
    const daysLeft = useDaysLeft(event.start);
    const daysText = daysLeft === 0
        ? t('days-count-zero')
        : t('days-count', { count: daysLeft });

    return (
        <EventDetailLink event={event} className='text-reset text-decoration-none overflow-hidden'>
            <div className='sh-next-event-badge'>
                <span className='icon flex-shrink-0' />
                <span className='text-truncate'>{t('label')}{' '}{event.displayTitle}</span>
                <span className='divider' />
                <span className='text-nowrap'>{daysText}</span>
            </div>
        </EventDetailLink>
    );
}

const formParts = {
    general: ClientGeneralForm,
    invoicing: ClientInvoicingForm,
    preferences: ClientPreferencesForm,
};

export enum ClientUpdateType {
    General = 'general',
    Invoicing = 'invoicing',
    Preferences = 'preferences',
}

type ClientFormPartProps = {
    client: ClientFE;
    onUpdate: Dispatch<SetStateAction<ClientFE | undefined>>;
    type: ClientUpdateType;
    isDirty: boolean;
    setIsDirty: (isDirty: boolean) => void;
};

function ClientFormPart({ client, onUpdate, type, isDirty, setIsDirty }: ClientFormPartProps) {
    const { addAlert } = useNotifications();

    const clientUpdateMutation = trpc.$client.updateClient.useMutation();

    function onSubmit(update: OmitId<ClientUpdate>) {
        clientUpdateMutation.mutate({ id: client.id, ...update }, {
            onError: () => {
                addAlert(createErrorAlert(clientUpdateMutation.error));
            },
            onSuccess: response => {
                const updatedClient = ClientFE.fromServer(response);
                onUpdate(updatedClient);
                addAlert(createTranslatedSuccessAlert('pages:client.save-success-alert'));
            },
        });
    }

    const saveButton = useCallback(({ onClick }: { onClick: () => void }) => (
        <SaveButton onClick={onClick} isFetching={clientUpdateMutation.isPending} />
    ), [ clientUpdateMutation.isPending ]);

    const InnerForm = formParts[type];

    return (
        <InnerForm
            onSubmit={onSubmit}
            defaultValue={client}
            onChange={setIsDirty}
            saveButton={isDirty ? saveButton : undefined}
        />
    );
}

type SaveButtonProps = Readonly<{
    onClick: () => void;
    isFetching: boolean;
}>;

function SaveButton({ onClick, isFetching }: SaveButtonProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'client' });

    return (
        <Portal to={portals.client.save}>
            <SpinnerButton
                type='submit'
                isFetching={isFetching}
                variant='primary'
                className='square'
                onClick={onClick}
            >
                <CheckIcon size={22} />
                {t('save-button')}
            </SpinnerButton>
        </Portal>
    );
}


type ClientTabProps = Readonly<{
    client: ClientInfoFE;
}>;

function ClientOrders({ client }: ClientTabProps) {
    const { t } = useTranslation('pages');

    return (
        <div>
            <h1 className='mt-0'>{t('client.orders.page-title')}</h1>
            <OrdersTable filterClient={client} />
        </div>
    );
}

function ClientEvents({ client }: ClientTabProps) {
    const { t } = useTranslation('pages');

    return (
        <div>
            <h1 className='mt-0'>{t('client.events.page-title')}</h1>
            <EventsTable client={client} />
        </div>
    );
}
