import qs from 'qs';
import { type Result } from ':frontend/types/result';
import axios, { type AxiosRequestConfig, type AxiosInstance, type AxiosResponse } from 'axios';
import { AccessDeniedError } from ':utils/error/generic/accessDenied.error';
import { Route } from ':utils/routes';
import { emptyFunction } from '../common';

/**
 * This represents a custom frontend error that was thrown by the api interceptors themselves.
 * For example, if the api token is missing for google, this error will be thrown.
 */
export const FRONTEND_ERROR_NAME = 'FrontendError';

type Action = string | Route<never, string>;
type QueryParams = Record<string, unknown> | void;
type RequestBody = Record<string, unknown> | Record<string, unknown>[] | void;

export class RawApi {
    constructor(
        private readonly axiosInstance: AxiosInstance,
        private readonly actionToPath: ActionToPath,
        private readonly authorizer?: ApiAuthorizer,
    ) {}

    private authorize(config: AxiosRequestConfig): AxiosRequestConfig {
        if (this.authorizer) {
            const header = this.authorizer.getAuthorizationHeader();
            if (!header)
                // TODO transform this into unauthorized error
                // throw new Error('Authorization header is missing');
                return config;

            if (!config.headers)
                config.headers = {};

            config.headers['Authorization'] = header;
        }

        return config;
    }

    GET<TOutput, TQueryParams extends QueryParams = QueryParams>(
        action: Action,
        params?: TQueryParams,
        config: AxiosRequestConfig = {},
    ): Promise<Result<TOutput>> {
        const path = this.actionToPath(action);
        const info = 'GET:    ' + path;

        config = this.authorize(config);

        return this.requestWrapperFunction<TOutput>(this.axiosInstance.get<TOutput>(path, {
            ...config,
            params,
            paramsSerializer: function (params) {
                return qs.stringify(params, { arrayFormat: 'brackets' });
            },
        }), info, params);
    }

    POST<TOutput, TRequestBody extends RequestBody = RequestBody>(
        action: Action,
        body?: TRequestBody,
        config: AxiosRequestConfig = {},
    ): Promise<Result<TOutput>> {
        const path = this.actionToPath(action);
        const info = 'POST:   ' + path;

        config = this.authorize(config);

        return this.requestWrapperFunction<TOutput>(this.axiosInstance.post<TOutput>(path, body, config), info, config.params, body);
    }

    PUT<TOutput, TRequestBody extends RequestBody = RequestBody>(
        action: Action,
        body?: TRequestBody,
        config: AxiosRequestConfig = {},
    ): Promise<Result<TOutput>> {
        const path = this.actionToPath(action);
        const info = 'PUT:    ' + path;

        config = this.authorize(config);

        return this.requestWrapperFunction<TOutput>(this.axiosInstance.put<TOutput>(path, body, config), info, config.params, body);
    }

    PATCH<TOutput, TRequestBody extends RequestBody = RequestBody>(
        action: Action,
        body?: TRequestBody,
        config: AxiosRequestConfig = {},
    ): Promise<Result<TOutput>> {
        const path = this.actionToPath(action);
        const info = 'PATCH:  ' + path;

        config = {
            ...config,
            headers: {
                ...config.headers,
                'Content-Type': 'application/merge-patch+json',
            },
        };
        config = this.authorize(config);

        return this.requestWrapperFunction<TOutput>(this.axiosInstance.patch<TOutput>(path, body, config), info, config.params, body);
    }

    DELETE<TOutput>(
        action: Action,
        body?: unknown,
        config: AxiosRequestConfig = {},
    ): Promise<Result<TOutput>> {
        const path = this.actionToPath(action);
        const info = 'DELETE: ' + path;

        config = this.authorize(config);

        return this.requestWrapperFunction<TOutput>(this.axiosInstance.delete<TOutput>(path, { ...config, data: body }), info, config.params);
    }

    prepareAbort(): [ AbortSignal, () => void ] {
        const controller = new AbortController();

        return [
            controller.signal,
            () => {
                controller.abort();
            },
        ];
    }

    private requestWrapperFunction<TOutput>(promise: Promise<AxiosResponse<TOutput>>, info: string, params: unknown, body?: unknown): Promise<Result<TOutput>> {
        const coalescedParams = params !== undefined ? params : {};

        return promise
            .then(response => {
                if (body !== undefined)
                    console.log(info, coalescedParams, body, response.data);
                else
                    console.log(info, coalescedParams, response.data);

                return ({
                    status: true,
                    data: response.data,
                } as Result<TOutput>);
            }).catch(error => {
                if (error.name === 'CanceledError') {
                    return ({
                        status: false,
                        error: CANCELED_ERROR,
                    } as Result<TOutput>);
                }

                if (error.name === FRONTEND_ERROR_NAME) {
                    console.warn(FRONTEND_ERROR_NAME, error.data);
                    return ({
                        status: false,
                        error: error.data,
                    } as Result<TOutput>);
                }

                console.error(info, coalescedParams, error.response?.data, error);

                // Expired access token or invalid credentials
                if (error.response?.status === AccessDeniedError.httpStatus) {
                    console.warn('[Api] Unauthorized: ' + error.response?.data?.message);
                    this.authorizer?.JWTExpired();
                    return ({
                        status: false,
                        error: { type: 'login.unauthorized' },
                    } as Result<TOutput>);
                }

                return ({
                    status: false,
                    error: error.response?.data?.error || error.response?.data?.message || error.response?.data || error.message || error,
                    response: error.response,
                } as Result<TOutput>);
            });
    }
}

export const CANCELED_ERROR = 'canceled';

type TokenProvider = () => string | undefined;
type ExpiredJWTCallback = () => void;

export class ApiAuthorizer {
    // Default value, need to be set by the auth provider.
    private tokenProvider: TokenProvider = () => undefined;

    public setTokenProvider(provider: TokenProvider) {
        this.tokenProvider = provider;
    }

    public getAuthorizationHeader(): string | undefined {
        const token = this.tokenProvider();
        return token ? `Bearer ${token}` : undefined;
    }

    // Default value, need to be set by the auth manager.
    private expiredJWTCallback: ExpiredJWTCallback = emptyFunction;

    public setExpiredJWTCallback(callback: ExpiredJWTCallback) {
        this.expiredJWTCallback = callback;
    }

    public JWTExpired() {
        this.expiredJWTCallback();
    }
}

const axiosInstance = axios.create({
    baseURL: '',
    headers: {
        Accept: 'application/json',
    },
    // do not remove this, its added to add params later in the config
    params: {},
});

type ApiOptions = {
    actionToPath?: ActionToPath;
    authorizer?: ApiAuthorizer;
};

export function createApiObject(options?: ApiOptions): RawApi {
    return new RawApi(
        axiosInstance,
        options?.actionToPath ?? defaultActionToPath,
        options?.authorizer,
    );
}

type ActionToPath = (action: Action) => string;

function defaultActionToPath(action: Action): string {
    if (action instanceof Route)
        return action.resolve({});

    return action;
}
