import { secondsToMinutes } from ':utils/common';
import { type Id } from ':utils/id';
import { type ProductOutput } from ':utils/entity/product';
import { moneyFromServer, type Money, type TaxRateFE, getTaxRate } from ':frontend/modules/money';

export class ProductFE {
    private constructor(
        readonly id: Id,
        readonly title: string,
        readonly icon: string | undefined,
        readonly description: string | undefined,
        readonly sessionsCount: number,
        /** In minutes. */
        readonly sessionsDuration: number,
        /** Total price for all sessions. */
        readonly price: Money,
        readonly vat: TaxRateFE,
        readonly locationId: Id | undefined,
    ) {}

    static fromServer(input: ProductOutput): ProductFE {
        return new ProductFE(
            input.id,
            input.title,
            input.icon,
            input.description,
            input.sessionsCount,
            secondsToMinutes(input.sessionsDuration),
            moneyFromServer(input.price, input.currency),
            getTaxRate(input.vat),
            input.location,
        );
    }
}
